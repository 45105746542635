/* eslint-disable max-statements */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/fields';
import { makeStyles } from '@material-ui/core/styles';
import TooltipWrapper from 'components/TooltipWrapper';
import useActiveConditions from 'lib/hooks/useActiveConditions';
import { ViewMode } from 'lib/enums';
import { Text } from '@nike/eds';
import { TooltipContainer } from './styles';

const useStyles = makeStyles(() => ({
  greyOut: {
    '& > div > div': {
      backgroundColor: '#dedede !important',
    },
  },
  fullWidth: {
    width: '100%',
    '& .eds-tooltip__anchor': {
      width: '100%',
    },
    '& .eds-tooltip': {
      color: '#FFFFFF',
    },
  },
  tooltipBody: {
    backgroundColor: 'rgb(17, 17, 17)',
    padding: '20px 16px 20px 24px',
  },
}));

const SelectWithCriteria = ({ options, values, viewMode }) => {
  const styles = useStyles();
  const { status } = values;
  const createPage = viewMode === ViewMode.CREATE && true;
  const messageCriteria = createPage ? false : useActiveConditions(values);
  const showTooltipAndDisable = createPage || messageCriteria.length > 0;

  const TooltipBody = () => {
    return (
      <div className={ styles.tooltipBody }>
        <Text font="subtitle-2" className="eds-spacing--mb-4">
          { createPage
            ? 'Complete create process to activate'
            : 'Cannot set status to active' }
        </Text>
        { !createPage && messageCriteria && (
          <div>
            { messageCriteria?.map(({ criteria }, i) => (
              <div key={ i }> ⚠️ { criteria }</div>
            )) }
          </div>
        ) }
      </div>
    );
  };

  return (
    <div className={ styles.fullWidth }>
      <TooltipContainer>
        <TooltipWrapper
          active={ showTooltipAndDisable }
          message={ <TooltipBody /> }
          children={
            <SelectField
              disabled={ status === 'inactive' ? !!showTooltipAndDisable : false }
              name="status"
              label="Initiative Status"
              options={ options }
              className={
                status === 'inactive' && showTooltipAndDisable
                  ? styles.greyOut
                  : ''
              }
            />
          }
        />
      </TooltipContainer>
    </div>
  );
};

SelectWithCriteria.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  values: PropTypes.shape({
    handle: PropTypes.string,
    status: PropTypes.string,
    isConsentRequired: PropTypes.bool,
    platforms: PropTypes.arrayOf(PropTypes.string),
  }),
  viewMode: PropTypes.string,
};

export default SelectWithCriteria;
