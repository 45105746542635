import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditFeatureFlag from './Edit';
import FeatureFlagsListView from './List';

export const FeatureFlag = () => {
  return (
    <Switch>
      <Route
        path="/dev/featureFlag"
        exact={ true }
        component={ FeatureFlagsListView }
      />
      <Route
        path="/dev/featureFlag/edit/:handle"
        exact={ true }
        component={ EditFeatureFlag }
      />
      <Route component={ FeatureFlagsListView } />
    </Switch>
  );
};
export default FeatureFlag;
