/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { map } from 'lodash';
import styles from './styles.module.scss';
import {
  formatTable,
  formatText,
  format,
  cleanText,
  formatHighlightText,
} from './utils';
import remarkRehype from 'remark-rehype';
import remarkParse from 'remark-parse';
import rehypeReact from 'rehype-react';

const RichTextPreview = ({ content = [] }) => {
  return (
    <div className={ styles.preview }>
      { map(content, ({ field, value }) =>
        (value ? (
          <section key={ field }>
            <ReactMarkdown
              children={ cleanText(value) }
              remarkPlugins={ [remarkRehype, [remarkGfm, {}], remarkParse] }
              rehypePlugins={ [
                [
                  rehypeReact,
                  {
                    createElement: React.createElement,
                    fragment: React.Fragment,
                  },
                ],
              ] }
              linkTarget="_blank"
              components={{
                h1: formatText,
                h2: formatText,
                h3: formatText,
                h4: formatText,
                h5: formatText,
                h6: formatText,
                ul: format,
                table: formatTable,
                blockquote: formatText,
                mark: formatText,
                p: formatHighlightText,
              }}
            />
          </section>
        ) : null)
      ) }
    </div>
  );
};

RichTextPreview.defaultProps = {
  content: [{}],
};

RichTextPreview.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.string,
      order: PropTypes.number,
      helperText: PropTypes.string,
    })
  ).isRequired,
};

export default RichTextPreview;
