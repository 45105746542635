import { adminApiSlice } from '../adminApiSlice';

const featureFlagApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['featureFlag', 'featureFlag-list'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchFeatureFlags: builder.query({
          query: () => '/featureFlags',
          providesTags: () => [{ type: 'featureFlag-list', id: 'LIST' }],
        }),
        fetchFeatureFlagsByHandle: builder.query({
          query: (handle) => `/featureFlags/${ handle }`,
          providesTags: (_result, _error, handle) => [
            { type: 'featureFlag', id: handle },
          ],
        }),
        patchFeatureFlag: builder.mutation({
          query: ({ handle, activeItems }) => ({
            url: `/featureFlags/${ handle }`,
            method: 'PATCH',
            body: { activeItems },
          }),
          invalidatesTags: (_result, _error, { handle }) => [
            { type: 'featureFlag-list', id: 'LIST' },
            { type: 'featureFlag', id: handle },
          ],
        }),
      };
    },
  });

export const {
  useFetchFeatureFlagsQuery,
  useFetchFeatureFlagsByHandleQuery,
  usePatchFeatureFlagMutation,
} = featureFlagApiSlice;
