export const trimAndReplace = (str) => {
  const removeFirstHyphen = (str) =>
    (str && str.charAt(0) === '-' ? str.substring(1) : str);

  return removeFirstHyphen(
    str
      ?.trim()
      .replace(/[{}]/g, '') //Remove '{' and '}' for surveyHandle
      .replace(/\s+/g, '-')
      .replace(/-{2,}/g, '-')
      .toLowerCase()
  );
};

//eslint-disable-next-line no-useless-escape
export const nonSpecialCharRegex = /^[0-9A-Za-z\s\-]+$/;

// This regex allows custom variables enclosed in double curly braces (e.g., {{variableName}}).
// It ensures that the string does not contain any special characters outside of these custom variables
// and that the custom variables are also properly formatted.
// Additionally, it doesn't allow strings that are only whitespace or improperly formatted custom variables.
export const specialCharRegexWithCustomVariable =
  // eslint-disable-next-line
  /^(?!.*\{\{[^}]*\{\{)(?!.*\{\{[^a-zA-Z0-9]+\}\})(?!.*\{\{[^}]*[^a-zA-Z0-9]\}\})^(?!\s*\{\{[a-zA-Z0-9]+\}\}\s*$)[^\{\}]*((\{\{[a-zA-Z0-9]+\}\})[^\{\}]*)*$/;

export const nonSpace = /^(\S+$)/g;

export const helperText =
  'Warning: This field can only be edited upon initiative creation';

export const moduleHandleHelperText =
  'Enter the code module identifier that will be used to load the Journey';

export const surveyFieldsHelperText =
  'Warning: This field can only be edited upon survey creation';

export const textFormatter = (label) =>
  label?.toLowerCase().replace(/\s+/g, '-');

export const digitalServicePrefix = 'digital-service';

//TODO comment out experiencePrefix
export const experiencePrefix = 'exp';
export const audiencePrefix = 'aud';
export const surveyPrefix = 'svy';
export const screenerPrefix = 'scr';
export const defaultRequiredPpis = [
  'ppi-first-name',
  'ppi-last-name',
  'ppi-email',
  'ppi-date-of-birth',
];

export const initiativePrefix = (type) => {
  switch (type) {
    case 'core':
      return 'cor';
    case 'digital-service':
      return 'exp';
    default:
      return 'exp';
  }
};
export const duplicateItemMessage = 'Answer option cannot be same';
export const customVariableSupportText =
  'Custom variable needs to follow the format: {{variableName}}';

export const isTextOrHiddenType = (value) => ['text', 'hidden'].includes(value);

export const isValidCustomVariable = (value, regex) =>
  typeof value === 'string' && value?.trim().match(regex);

export const isEmptyValue = (value) =>
  (typeof value === 'string' ? !value.trim() : !value);

//Error messages for the fields.
export const requiredError = 'Required';
export const duplicateAnswerOption = 'Duplicate Answer Option';
export const invalidCharacterError = 'Invalid character(s)';
export const atLeastTwoOptionsError = 'At least 2 answer options are required';
export const spacesNotAllowedError = 'Spaces not allowed';
export const uuidFormatError = 'Must be in UUID format';
export const textBeforeOrAfterCustomVariableError =
  'The field must contain text before or after the custom variable';
export const unknownCustomVariableError =
  'The custom variable provided is not a known inquiry variable. Please review carefully prior to publishing.';
export const emptyCustomVariableError =
  'The Custom variable cannot be empty. Please provide a valid variable.';
