import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { applyTo, pipe, path } from 'ramda';
import config from 'conf';

const BASE_URL = config.USER_API_HOST;

export const innovationApiSlice = createApi({
  reducerPath: 'innovationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders(headers) {
      const token = applyTo(
        window.localStorage.getItem('okta-token-storage', '{}'),
        pipe(JSON.parse, path(['accessToken', 'accessToken']))
      );
      headers.set('x-l2w-token-type', 'okta');
      headers.set('content-type', 'application/json');
      headers.set('authorization', `Bearer ${ token }`);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
