/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { formTypes } from 'lib/propTypes';
import { Button } from '@nike/eds';
import { length, propOr } from 'ramda';
import InquiryQuestion from './InquiryQuestion.js';
import { AccordionStyled } from '@nike/nike-design-system-components';
import { getFormFields, ppiAnswerOptions } from 'components/forms/Assets/lib';
import { INQUIRIES } from './utils';
import { Plus } from '@nike/nike-design-system-icons';
import {
  QuestionSetTypes,
  findMatchingQuestionSetsWithIndex,
  getFieldByFieldName,
  getFieldValueByFieldName,
  isCategoryField,
  setConditionalToggledLabel,
  setDynamicQuestionLabels,
  setUsedInConditionalLabels,
} from './RepeatableFieldsetUtils';
import { SCREENER_FORM, SURVEY_FORM } from 'lib/layoutUtils';

const RepeatableFieldSet = ({
  fields,
  setFieldValue,
  setFieldTouched,
  setFormState,
  formState,
  errors,
  disabled,
  layoutType,
  isApproved,
  requiredFields,
  setHasUnsavedChanges,
}) => {
  const [repeatableFieldSet, setRepeatableFieldSet] = useState([]);
  const [existingPPIOptions, setExistingPPIOptions] = useState([]);
  const fieldKey = 'questionSet';

  useEffect(() => {
    if (
      fields.length > 0 &&
      (!getFieldValueByFieldName(
        repeatableFieldSet[0]?.value,
        QuestionSetTypes.QUESTION_LABEL
      ) ||
        existingPPIOptions?.length === 0)
    ) {
      const initialFieldSet = fields;
      setRepeatableFieldSet(initialFieldSet);
      const existingPPI = getFieldByFieldName(
        initialFieldSet[0]?.value,
        QuestionSetTypes.EXISTING_PPI
      )?.options;
      setExistingPPIOptions(existingPPI);
    }
  }, [fields]);

  useEffect(() => {
    if (INQUIRIES.includes(layoutType) && Array.isArray(fields)) {
      const updatedQuestionSetFields = setConditionalToggledLabel(fields);
      setRepeatableFieldSet(updatedQuestionSetFields);
      setFormState(setConditionalToggledLabel(updatedQuestionSetFields));
    }
  }, [layoutType, fields]);

  const handleRepeatableSetChange = useCallback(
    (response, option) => {
      //Select sends key=option.name
      const key = option?.name || response?.target?.name || response;
      const ppiHandle = response?.target?.ppiHandle;
      const isOptionalQuestion = key.includes(
        QuestionSetTypes.OPTIONAL_QUESTION
      );
      //construct categoryName
      const categoryNameKey = key ? `categoryName-${ key.split('-')[1] }` : '';

      //Text sends response.target.value
      //Select sends response
      const value = response?.label
        ? response
        : key?.includes(QuestionSetTypes.CONDITIONAL_OPTIONS)
          ? response?.map((opt) => ({
            label: opt?.name || opt?.label,
            value: opt?.value,
          }))
          : key?.includes(QuestionSetTypes.CONDITIONAL_TOGGLE) ||
          isOptionalQuestion
            ? response?.target?.checked
            : (response?.target?.type === 'checkbox'
              ? response?.target?.checked
              : response?.target?.value) || option;

      let updatedFieldSet;

      if (isOptionalQuestion) {
        setRepeatableFieldSet((prevState) => {
          updatedFieldSet = [...prevState];
          const questionSetIndex = updatedFieldSet.findIndex(
            (questionSet) => questionSet.fieldSetId === +key.split('-')[1]
          );
          updatedFieldSet[questionSetIndex].optional = value;
          return updatedFieldSet;
        });
      } else {
        setRepeatableFieldSet((prevState) => {
          updatedFieldSet = [...prevState];

          const questionSetIndex = updatedFieldSet.findIndex((obj) =>
            obj.value.some((val) => val.field === key)
          );
          const questionOrderIndex = updatedFieldSet[
            questionSetIndex
          ].value.findIndex((val) => val.field === key);

          if (key.includes(QuestionSetTypes.QUESTION_CATEGORY)) {
            const questionSetIndexCat = updatedFieldSet.findIndex((obj) =>
              obj.value.some((val) => val.field === categoryNameKey)
            );
            const questionOrderIndexCat = updatedFieldSet[
              questionSetIndexCat
            ].value.findIndex((val) => val.field === categoryNameKey);

            updatedFieldSet[questionSetIndexCat].value[
              questionOrderIndexCat
            ].value =
              key.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
              value?.length === 1
                ? value[0]
                : value?.value;
          }

          updatedFieldSet[questionSetIndex].value[questionOrderIndex].value =
            key.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
            value?.length === 1
              ? value[0]
              : value;

          if (isCategoryField(key?.split('-')[0])) {
            const categoryValue = getFieldValueByFieldName(
              updatedFieldSet[questionSetIndex]?.value,
              QuestionSetTypes.QUESTION_CATEGORY
            )?.value;

            const matchingQuestionSetsWithIndex =
              findMatchingQuestionSetsWithIndex(
                updatedFieldSet,
                categoryValue,
                questionSetIndex
              );

            if (matchingQuestionSetsWithIndex) {
              matchingQuestionSetsWithIndex.forEach(
                ({ index: questionSetIdx }) => {
                  updatedFieldSet[questionSetIdx].value[
                    questionOrderIndex
                  ].value = value;
                }
              );
            }
          }

          if (key.includes(QuestionSetTypes.EXISTING_PPI)) {
            const { value: ppiValue } = value || {};
            const answerOptions = getFieldByFieldName(
              updatedFieldSet[questionSetIndex].value,
              QuestionSetTypes.ANSWER_OPTIONS
            );

            if (layoutType === SCREENER_FORM) {
              const { value: ppiValue } = value || {};
              answerOptions.value = ppiAnswerOptions(ppiValue);
            } else if (!ppiAnswerOptions(ppiValue)) {
              answerOptions.value = [];
            }

            const conditionalLabel = getFieldValueByFieldName(
              updatedFieldSet[questionSetIndex].value,
              QuestionSetTypes.QUESTION_LABEL
            );

            updatedFieldSet.forEach((questionSet) => {
              if (questionSet?.conditionalLabel === conditionalLabel) {
                // still holds a reference to updatedFieldSet object, not a copy of it.
                const questionSetField = getFieldByFieldName(
                  questionSet.value,
                  QuestionSetTypes.CONDITIONAL_OPTIONS
                );
                questionSetField.value = undefined;
                questionSetField.options = [];
              }
            });
          }

          if (key.includes(QuestionSetTypes.CONDITIONAL_TOGGLE)) {
            // still holds a reference to updatedFieldSet object, not a copy of it.
            const questionCategoryField = getFieldByFieldName(
              updatedFieldSet[questionSetIndex].value,
              QuestionSetTypes.QUESTION_CATEGORY
            );

            if (value) {
              const lastQuestionCategory = [];
              lastQuestionCategory.push(
                getFieldValueByFieldName(
                  updatedFieldSet?.[questionSetIndex - 1]?.value,
                  QuestionSetTypes.QUESTION_CATEGORY
                )?.value
              );

              questionCategoryField.options = lastQuestionCategory.map(
                (category) => {
                  return { label: category, value: category };
                }
              );
            } else {
              const options = updatedFieldSet.map(
                (questionSet) =>
                  getFieldValueByFieldName(
                    questionSet.value,
                    QuestionSetTypes.QUESTION_CATEGORY
                  )?.value
              );
              questionCategoryField.options = [...new Set(options)]
                .filter(Boolean)
                .map((category) => {
                  return { label: category, value: category };
                });
            }
          }

          if (ppiHandle) {
            updatedFieldSet[questionSetIndex].value[
              questionOrderIndex
            ].ppiHandle = ppiHandle;
          }
          return updatedFieldSet;
        });
      }

      if (updatedFieldSet) setFieldValue(fieldKey, updatedFieldSet);
      setFieldTouched(key, true);
      setHasUnsavedChanges(true);
      // setFieldTouched({ [fieldKey]: true });
    },
    [formState]
  );

  const handleRemoveFieldSet = useCallback(
    (setId) => () => {
      let updatedFieldSet;
      setRepeatableFieldSet((prevState) => {
        updatedFieldSet = prevState.filter(
          (field) => field.fieldSetId !== setId
        );
        Object.keys(updatedFieldSet).forEach(
          (item, index) => (updatedFieldSet[item].sortOrder = index + 1)
        );
        return updatedFieldSet;
      });
      //Call to re-index sections for error handling
      setFieldValue(fieldKey, updatedFieldSet);
      if (layoutType === SURVEY_FORM) setFieldTouched(fieldKey, true);
    },
    [formState]
  );

  function getCategoriesFromForm(form) {
    const formValues = form?.questionSet
      ? form?.questionSet?.updatedFieldSet
      : form;

    const categoryContent =
      Array.isArray(formValues) &&
      formValues
        .filter((itm) =>
          getFieldValueByFieldName(
            itm.value,
            QuestionSetTypes.QUESTION_CATEGORY
          )
        )
        .map(
          (itm) =>
            getFieldValueByFieldName(
              itm.value,
              QuestionSetTypes.QUESTION_CATEGORY
            )?.value
        );

    return categoryContent
      ? {
        categories: [...new Set(categoryContent)].map((val) => {
          return {
            name: val,
            handle: val,
            questions: categoryContent.filter((question) => question === val),
          };
        }),
      }
      : [];
  }

  const handleAddFieldSet = useCallback(
    (type) => {
      const content = getCategoriesFromForm(formState);
      const addedFieldSet =
        (existingPPIOptions?.length > 0 &&
          getFormFields(content, layoutType, existingPPIOptions).elements[0]
            .value) ||
        [];

      setRepeatableFieldSet((prevState) => [...prevState, ...addedFieldSet]);
    },
    [formState, existingPPIOptions]
  );

  useEffect(() => {
    setFormState(
      setDynamicQuestionLabels(setUsedInConditionalLabels(repeatableFieldSet))
    );
  }, [repeatableFieldSet]);

  const showRemoveLink = !disabled && length(repeatableFieldSet) > 1;

  const showAddLink =
    !disabled &&
    length(repeatableFieldSet) >= 0 &&
    INQUIRIES.includes(layoutType);

  return (
    <>
      { repeatableFieldSet.length && (
        <>
          <AccordionStyled
            id="eligibilityQuestionForm"
            key="eligibilityQuestionForm"
            open={ true }
            size={ 'large' }
          >
            { repeatableFieldSet && repeatableFieldSet.length
              ? repeatableFieldSet.map((fieldSet, idx) => {
                const isCurrentIndexConditional = getFieldValueByFieldName(
                  fieldSet?.value,
                  QuestionSetTypes.CONDITIONAL_TOGGLE
                );
                const hideQuestion = fieldSet?.hideQuestion;
                return (
                  !hideQuestion && (
                    <div
                      key={ fieldSet.fieldSetId }
                      data-testid={ `inquiryQuestion-${ idx }` }
                    >
                      <div
                        className={
                          isCurrentIndexConditional
                            ? styles.conditionalLayout
                            : styles.layout
                        }
                      >
                        { showRemoveLink && (
                          <a
                            className={ `${ styles.removeLink } ${
                              isCurrentIndexConditional
                                ? styles.conditional
                                : ''
                            }` }
                            onClick={ handleRemoveFieldSet(
                              fieldSet.fieldSetId
                            ) }
                          >
                            Remove
                          </a>
                        ) }
                        <br />

                        <InquiryQuestion
                          questionIndex={ idx }
                          formState={ formState }
                          fields={ fieldSet }
                          disabled={ disabled }
                          setFieldValue={ setFieldValue }
                          setFormState={ setFormState }
                          errors={ propOr([], 'questionSet', errors)[idx] }
                          handleChange={ handleRepeatableSetChange }
                          layoutType={ layoutType }
                          isApproved={ isApproved }
                          requiredFields={ requiredFields }
                        />
                      </div>
                    </div>
                  )
                );
              })
              : null }
          </AccordionStyled>

          { showAddLink && (
            <div style={{ margin: '24px 0px 16px 0px' }}>
              <Button
                afterSlot={ <Plus /> }
                size="medium"
                variant="secondary"
                onClick={ handleAddFieldSet }
                data-testid="add-item-btn"
              >
                Add Item
              </Button>
            </div>
          ) }
        </>
      ) }
    </>
  );
};

RepeatableFieldSet.propTypes = {
  ...formTypes,
  disabled: PropTypes.bool,
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  setHasUnsavedChanges: PropTypes.func,
};

export default RepeatableFieldSet;
