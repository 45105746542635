import { curry, map, prop, test, propOr, pipe } from 'ramda';
import { useRef } from 'react';

// LocalStorage -> String -> LocalStorage
export const NamespacedLocalStorage = curry((ls, ns) => ({
  getItem: (key) => ls.getItem(`${ ns }:${ key }`),
  setItem: (key, val) => ls.setItem(`${ ns }:${ key }`, val),
  removeItem: (key) => ls.removeItem(`${ ns }:${ key }`),
  clear: () => {
    Object.keys(ls)
      .filter((key) => key.startsWith(`${ ns }:`))
      .forEach((key) => ls.removeItem(key));
  },
  get length() {
    return Object.keys(ls).filter((key) => key.startsWith(`${ ns }:`)).length;
  },
}));

// RelayConnection -> [Node]
export const connectionToNodes = pipe(propOr([], 'edges'), map(prop('node')));

export const isMedia = test(
  /^https?:\/\/[-a-zA-Z0-9].+.{2,3}(\/?[/-a-zA-Z0-9].*)?/
);

export const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () =>
    elRef?.current?.scrollIntoView({ behavior: 'smooth' });
  return [executeScroll, elRef];
};

export const transformValues = (values, initiativeType) => ({
  ...values,
  initiativeType,
});

export const participationStatusArray = ['OPEN', 'FULL', 'COMPLETED'];
