import PropTypes from 'prop-types';
import React from 'react';
import { Table, TableHeading, TableCell } from '@nike/eds';

const RichTextTable = (props) => {
  const [head, body] = props.children;
  return (
    <Table { ...props }>
      <thead>
        { head.props.children.map((row) => {
          return (
            <tr key={ row.key }>
              { row.props.children.map((column) => (
                <TableHeading key={ column.key }>
                  { column.props.children }
                </TableHeading>
              )) }
            </tr>
          );
        }) }
      </thead>
      <tbody>
        { body.props.children.map((row) => {
          return (
            <tr key={ row.key }>
              { row.props.children.map((cell) => (
                <TableCell
                  key={ cell.key }
                  { ...cell.props.children?.[0].properties }
                >
                  { cell.props.children }
                </TableCell>
              )) }
            </tr>
          );
        }) }
      </tbody>
    </Table>
  );
};

RichTextTable.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};
export default RichTextTable;
