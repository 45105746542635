// modules
import React, { useMemo } from 'react';
import { concat } from 'ramda';
import { useFetchDataRequestsQuery } from 'features/adminApi/endpoints/dataRequests';
import { statusMap } from '../../utils';
import { EntityType } from 'lib/enums';
// local
import DataTable from 'components/DataTable';

// eslint-disable-next-line react/prop-types
const List = ({ location: { state } }) => {
  const { data: inprogress = [], isFetching: isFetchingInProgress } =
    useFetchDataRequestsQuery('inprogress');
  const { data: complete = [], isFetching: isFetchingIComplete } =
    useFetchDataRequestsQuery('complete');

  const items = useMemo(
    () => concat(inprogress, complete),
    [inprogress, complete]
  );

  const initialFilter = useMemo(
    () =>
      // eslint-disable-next-line react/prop-types
      state?.initialFilter || {
        field: 'status',
        operator: 'equals',
        value: 'inprogress',
      },
    []
  );

  const columnVisibilityModel = {
    id: false,
  };

  return (
    <>
      <DataTable
        loading={ isFetchingInProgress || isFetchingIComplete }
        heading="Data Requests"
        createHref="/dataRequests/create"
        createLabel="Create"
        columns={ [
          {
            field: 'requestNature',
            headerName: 'Type',
            minWidth: 100,
            flex: 1,
            hideable: false,
            align: 'center',
          },
          {
            field: 'athleteId',
            headerName: 'AthleteID',
            minWidth: 300,
            flex: 1,
            filterable: true,
          },
          {
            field: 'upmId',
            headerName: 'UPMID',
            minWidth: 300,
            flex: 1,
            filterable: true,
          },
          {
            field: 'requestedDate',
            headerName: 'Date Requested',
            minWidth: 200,
            flex: 1,
            type: 'dateTime',
            filterable: true,
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 200,
            flex: 1,
            filterable: true,
            valueFormatter: ({ value }) => statusMap[value] || value,
          },
          {
            field: 'id',
            headerName: 'Id',
            minWidth: 200,
            flex: 1,
            filterable: true,
          },
        ] }
        actions={ [
          {
            key: 'id',
            url: '/dataRequests/',
            label: 'view',
            action: 'read',
            entity: EntityType.DATA_REQUEST,
          },
        ] }
        rows={ items }
        initialFilter={ initialFilter }
        columnVisibilityModel={ columnVisibilityModel }
      />
    </>
  );
};

export default List;
