import styled from '@emotion/styled';

export const TooltipContainer = styled.div`
  .eds-tooltip {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px 0px;
    width: 33vw;
    max-width: 424px;
    min-width: 300px;
    margin-top: -24px;
  }
  .eds-tooltip__anchor {
    width: 100%;
  }
`;
