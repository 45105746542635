import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const consentApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'consent',
      'cms',
      'compiled-consent',
      'compiled-consent-version',
    ],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchConsent: builder.query({
          query(initiativeHandle) {
            return `/initiatives/${ initiativeHandle }/consents`;
          },
          providesTags: (result = []) => [
            'consent',
            ...result.map(({ id }) => ({ type: 'consent', id })),
          ],
          transformResponse: (response, _, status) =>
            pathOr([], ['consents'], response),
        }),
        fetchConsentById: builder.query({
          query(payload) {
            const { initiativeHandle, id } = payload;
            return `/initiatives/${ initiativeHandle }/consents/${ id }`;
          },
          providesTags: (_result, _error, id) => [
            { type: 'consent', id },
          ],
          invalidatesTags: (result, error, { id }) => {
            return [{ type: 'consent', id }, 'consent'];
          },
          transformResponse: (response, _, status) => response,
        }),
        patchConsent: builder.mutation({
          query: (body) => {
            const { draftExist, sections, initiativeHandle, id } = body || {};
            // Whenever we are PATCH-ing cosent-content, passing sections is enough.
            const payload = draftExist ? { sections } : body;
            return {
              url: `/initiatives/${ initiativeHandle }/consents/${ id }`,
              method: 'PATCH',
              body: payload,
            };
          },
          invalidatesTags: (result, error, { id }) => {
            return [{ type: 'consent', id: 'LIST' }, { type: 'consent', id }, 'consent'];
          },
          transformResponse: (response, _, status) => {
            return response;
          },
        }),
        createConsent: builder.mutation({
          query: (body) => ({
            url: `/initiatives/${ body.initiativeHandle }/consents`,
            method: 'POST',
            body,
          }),
          invalidatesTags: (result, error, { id }) => {
            return [
              { type: 'consent', id: 'LIST' },
              { type: 'consent', id },
              'consent',
            ];
          },
        }),
        fetchCompiledConsents: builder.query({
          query(initiativeHandle) {
            return `/initiatives/${ initiativeHandle }/compiledConsents`;
          },
          providesTags: (result = []) => [
            'compiled-consent',
            ...result?.map(({ id }) => ({ type: 'compiled-consent', id })),
          ],
          transformResponse: (response, _, status) => response.consents,
        }),

        fetchCompiledConsentByVersion: builder.query({
          query({ initiativeHandle, version }) {
            return `/initiatives/${ initiativeHandle }/compiledConsents/${ version }`;
          },
          providesTags: (result = {}) => ['compiled-consent-version'],
          transformResponse: (response, _, status) => response,
        }),
      };
    },
  });

export const {
  useFetchConsentQuery,
  useFetchConsentByIdQuery,
  useFetchCompiledConsentsQuery,
  useFetchCompiledConsentByVersionQuery,
  usePatchConsentMutation,
  useCreateConsentMutation,
} = consentApiSlice;
