/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable-next-line react/prop-types */

import React, { useCallback } from 'react';
import { Typography, Container } from '@material-ui/core';
import Title from 'components/Title';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Button } from '@nike/eds';
import config from 'conf';

const ChangeEnv = () => {
  const history = useHistory();

  const handleEnvChange = useCallback((envName) => {
    sessionStorage.setItem('envName', JSON.stringify(envName));

    const adminApiPrefix = 'internal-';
    const baseURL = 'https://PREFIXapi-ENVNAME.l2w-test.nikecloud.com';

    const REACT_APP_CONSOLE_USER_API_HOST = `${ baseURL
      .replace('PREFIX', '')
      .replace('ENVNAME', envName) }/v1`;
    const REACT_APP_CONSOLE_ADMIN_API_HOST = `${ baseURL
      .replace('PREFIX', adminApiPrefix)
      .replace('ENVNAME', envName) }/v1/admin`;
    const REACT_APP_CONSOLE_ADMIN_API_HOST_V2 = `${ baseURL
      .replace('PREFIX', adminApiPrefix)
      .replace('ENVNAME', envName) }/v2/admin`;

    sessionStorage.setItem(
      'userApiHost',
      JSON.stringify(REACT_APP_CONSOLE_USER_API_HOST)
    );

    sessionStorage.setItem(
      'adminApiHost',
      JSON.stringify(REACT_APP_CONSOLE_ADMIN_API_HOST)
    );

    sessionStorage.setItem(
      'adminApiHostV2',
      JSON.stringify(REACT_APP_CONSOLE_ADMIN_API_HOST_V2)
    );

    history.push({
      pathname: '/dev/env',
      state: {
        envName,
      },
    });
    window.location.reload(false);
  }, []);

  const isDevEnv = config.env !== 'production';

  const sessionEnvName = JSON.parse(sessionStorage.getItem('envName'));

  return (
    <Container>
      <>
        <Title title={ 'Env' } />
        <hr />
        <Typography variant="h2">API Endpoint Override</Typography>
        <hr />
        { isDevEnv ? (
          <>
            <Typography variant="body1">
              Use this page to override the API endpoint for this session.
            </Typography>
            <br />

            <ButtonGroup>
              <Button
                variant={ sessionEnvName === 'qa1' ? 'primary' : 'secondary' }
                onClick={ () => handleEnvChange('qa1') }
                size="small"
              >
                QA1
              </Button>
              <Button
                variant={ sessionEnvName === 'qa2' ? 'primary' : 'secondary' }
                onClick={ () => handleEnvChange('qa2') }
              >
                QA2
              </Button>
              <Button
                variant={ sessionEnvName === 'stg' ? 'primary' : 'secondary' }
                onClick={ () => handleEnvChange('stg') }
              >
                STG{ ' ' }
              </Button>
            </ButtonGroup>
            <br />
            { sessionEnvName && (
              <>
                <Typography variant="h3">
                  API endpoints: { JSON.parse(sessionStorage.getItem('envName')) }
                </Typography>
                { JSON.parse(sessionStorage.getItem('userApiHost')) }
                <br />
                { JSON.parse(sessionStorage.getItem('adminApiHost')) }
                <br />
                { JSON.parse(sessionStorage.getItem('adminApiHostV2')) }
              </>
            ) }
          </>
        ) : (
          <>This feature is only available in development environments.</>
        ) }
      </>
    </Container>
  );
};

export default ChangeEnv;
