import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const screenerApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['inquiry', 'cms'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchScreener: builder.query({
          query(initiativeHandle) {
            return `/initiatives/${ initiativeHandle }/inquiries`;
          },
          providesTags: (result = []) => [
            'inquiry',
            ...result.map(({ id }) => ({ type: 'inquiry', id })),
          ],
          transformResponse: (response, _, _status) =>
            pathOr([], ['inquiries'], response),
        }),
        fetchScreenerById: builder.query({
          query(payload) {
            const { id, initiativeHandle } = payload;
            return `/initiatives/${ initiativeHandle }/inquiries/${ id }`;
          },
          providesTags: (_result, _error, { id }) => {
            return [{ type: 'inquiry', id }];
          },
          transformResponse: (response, _, _status) => response,
        }),
        fetchScreenerAnswers: builder.query({
          query: (body) => {
            return {
              url: `/initiatives/${ body?.id }/inquiries/${ body?.inquiryHandle }/answers`,
              method: 'GET',
              responseHandler: async (response) => response.blob(),
              cache: 'no-cache',
            };
          },
          providesTags: (_result, _error, _id) => [
            { type: 'inquiry', _result },
          ],
          transformResponse: (response, _, _status) => response,
        }),
        patchScreener: builder.mutation({
          query: (body) => {
            return {
              url: `/initiatives/${ body?.initiativeHandle }/inquiries/${ body?.id }`,
              method: 'PATCH',
              body,
            };
          },
          invalidatesTags: (_result, _error, { id }) => {
            return [{ type: 'inquiry', id }];
          },
          transformResponse: (response, _, status) => {
            return response;
          },
        }),
        createScreener: builder.mutation({
          query: (body) => ({
            url: `/initiatives/${ body.initiativeHandle }/inquiries`,
            method: 'POST',
            body,
          }),
          invalidatesTags: (_result, _error, { id }) => {
            return [
              { type: 'inquiry', id: 'LIST' },
              { type: 'inquiry', id },
              'inquiry',
            ];
          },
        }),
      };
    },
  });

export const {
  useFetchScreenerQuery,
  useFetchScreenerByIdQuery,
  useFetchScreenerAnswersQuery,
  usePatchScreenerMutation,
  useCreateScreenerMutation,
} = screenerApiSlice;
