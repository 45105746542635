import * as Yup from 'yup';
import { invalidCharacterError, nonSpecialCharRegex, requiredError } from '../utils';

export const commonValidationSchema = Yup.object().shape({
  handle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  value: Yup.object().required('Enter valid JSON'),
});

export const validationSchema = commonValidationSchema.shape({
  name: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
});

export const validationSchemaConfigFields = commonValidationSchema.shape({
  description: Yup.string().matches(nonSpecialCharRegex, invalidCharacterError),
});

export const INITIAL_VALUES = {
  name: '',
  handle: '',
  value: {},
};

export const INITIAL_CONFIG_FIELDS_VALUES = {
  description: '',
  handle: '',
  value: {},
};
