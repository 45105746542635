import * as Yup from 'yup';
import { requiredError } from '../utils';

export const validationSchema = Yup.object().shape({
  upmId: Yup.string()
    .required(requiredError),
  requestManner: Yup.string().required(requiredError),
  requestNature: Yup.string().required(requiredError),
  status: Yup.string().required(requiredError),
});

export const REQUEST_MANNER_OPTIONS = [
  {
    label: 'Email',
    value: 'email', // TODO: email is not valid
  },
  {
    label: 'Online',
    value: 'online',
  },
];

export const REQUEST_NATURE_OPTIONS = [
  {
    label: 'Deletion',
    value: 'deletion',
  },
];

export const STATUS_OPTIONS = [
  {
    label: 'Complete',
    value: 'complete',
  },
  {
    label: 'In Progress',
    value: 'inprogress',
  },
];

export const INITIAL_VALUES = {
  upmId: '',
  requestManner: 'online',
  requestNature: '',
  status: '',
};
