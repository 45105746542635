// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe, isEmpty } from 'ramda';
import { withFormik } from 'formik';
import { TextField, Toggle } from 'components/fields';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import { INITIAL_VALUES, validationSchema } from './lib';
import styles from './index.module.scss';
import { Button } from '@nike/eds';

export default applyTo(
  ({
    disabled,
    handleReset,
    handleSubmit,
    touched,
    errors,
    dirty,
    showAutoDelete,
  }) => (
    <form onSubmit={ handleSubmit }>
      <TextField name="email" label="Email" disabled={ disabled } />
      { showAutoDelete && (
        <Toggle name="autoDelete" label="Auto Delete" disabled={ disabled } />
      ) }
      <div className={ styles.btns }>
        <Button
          variant="primary"
          disabled={ disabled || !isEmpty(errors) || !dirty }
          type="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  ),
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) =>
        (initialValues ? initialValues : INITIAL_VALUES),

      validationSchema,

      handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
        onSubmit(values);
        setSubmitting(false);
      },

      displayName: 'EmailLookupForm',
    }),
    propTypes({
      handleReset: PropTypes.func,
      handleSubmit: PropTypes.func,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      showAutoDelete: PropTypes.bool,
    }),
    defaultProps({
      disabled: PropTypes.bool,
      showAutoDelete: false,
      handleReset: () => {},
      handleSubmit: () => {},
      initialValues: null,
      onSubmit: () => {},
    }),
    memo
  )
);
