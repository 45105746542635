import { useWith, identity } from 'ramda';
import { mapKeys } from '@vl/js-lib/node/object';

export const renameKeys = useWith(mapKeys, [
  (keysMap) => (key) => keysMap[key] || key,
  identity,
]);

export function toLowerKeys(obj) {
  const entries = obj && Object.entries(obj);

  return (
    entries &&
    Object.fromEntries(
      entries.map(([key, value]) => {
        return [key.toLowerCase(), value];
      })
    )
  );
}
