import React from 'react';
import { Button, Container } from '@material-ui/core';
import FlexCenter from 'components/FlexCenter';
import { userEntityPermissions } from 'components/RBAC';
import { EntityType } from 'lib/enums';
import Link from 'components/Link';
import styles from 'views/HomeView/HomeView.module.scss';
import { Text } from '@nike/eds';

const Landing = () => {
  return (
    <Container className={ styles.wrapper }>
      <FlexCenter style={{ height: '100%' }}>
        <div
          className={ styles.content }
          style={{ width: '100%', maxWidth: 383 }}
        >
          <Text font={ 'title-3' } as={ 'h1' } style={{ textAlign: 'center' }}>
            Initiatives
          </Text>
          <Text font={ 'body-1' } as={ 'h1' } style={{ textAlign: 'center' }}>
            Quick Links:
          </Text>
          { userEntityPermissions(EntityType.INITIATIVE) && (
            <>
              <Button
                component={ Link }
                variant="outlined"
                to="/initiative/digital-service"
              >
                View Digital Service Initiatives
              </Button>
              <Button component={ Link } variant="outlined" to="/initiative/core">
                View Core Initiatives
              </Button>
              <Button
                component={ Link }
                variant="outlined"
                to="/initiative/activity-survey"
              >
                View Qualtrics Poll Initiatives
              </Button>
            </>
          ) }
        </div>
      </FlexCenter>
    </Container>
  );
};

export default Landing;
