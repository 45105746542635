import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextField } from '@nike/eds';
import FieldWrapper from 'components/FieldWrapper';
import styles from './styles.module.scss';

export const MaskedTextField = (props) => {
  const { handleType, createPage, helperText, ...fieldProps } = props;
  const { onChange, label, className } = fieldProps;
  const [field, meta] = useField(props);
  const handlePrefix = `${ handleType }-`;

  const beforeSlot = useMemo(() => {
    if (createPage) {
      return (
        <div className={ `${ styles.startAudi } ${ styles[handleType] }` }>
          { handlePrefix }
        </div>
      );
    }
  }, [createPage]);

  return (
    <FieldWrapper>
      <TextField
        { ...field }
        { ...fieldProps }
        label={
          <label>
            { label }
            { props.required && !(meta.touched && meta.error) && (
              <span className="asterisk">*</span>
            ) }
          </label>
        }
        message={ helperText }
        onChange={ onChange }
        className={ className }
        beforeSlot={ beforeSlot }
        value={ props.value || '' }
        data-testid={ `masked-${ label }` }
        hasErrors={ !!(meta.touched && meta.error) }
        errorMessage={ !!(meta.touched && meta.error) && meta?.error }
        disabled={ props.disabled }
      />
    </FieldWrapper>
  );
};

MaskedTextField.propTypes = {
  className: PropTypes.string,
  createPage: PropTypes.bool,
  disabled: PropTypes.bool,
  handleType: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  testid: PropTypes.string,
  value: PropTypes.string,
};
