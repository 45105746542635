import { adminApiSlice } from '../adminApiSlice';

const adminApiCacheSlice = adminApiSlice.enhanceEndpoints({
  addTagTypes: ['caches'],
});

const dataRequestsApiSlice = adminApiCacheSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchCaches: builder.query({
        query() {
          return '/caches';
        },
        providesTags: [{ type: 'caches', id: 'LIST' }],
      }),
      deleteCacheByServiceName: builder.mutation({
        query: (serviceName) => {
          return {
            url: `/caches/${ serviceName }`,
            method: 'DELETE',
          };
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'caches', id }],
      }),

      deleteCacheByHandle: builder.mutation({
        query: ({ handle }) => ({
          url: `/caches/all/${ handle }`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: 'caches', id: 'LIST' }],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchCachesQuery,
  useFetchCacheByIdQuery,
  useDeleteCacheByServiceNameMutation,
  useDeleteCacheByHandleMutation,
} = dataRequestsApiSlice;
