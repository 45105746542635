import React from 'react';
import { useModalStyles } from './styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import ModalWrapper from '../ModalWrapper';

export default function Modal(props) {
  const { handleClose, open, title, BodyComponent } = props;
  const classes = useModalStyles();
  return (
    <ModalWrapper handleClose={ handleClose } open={ open }>
      <>
        <div className={ classes.paper }>
          <Grid container={ true } className={ classes.header }>
            <Grid item={ true } sm={ 2 } />

            <Grid item={ true } sm={ 8 }>
              <Typography variant={ 'h3' }>{ title }</Typography>
            </Grid>
            <Grid item={ true } sm={ 2 }>
              <IconButton
                size="medium"
                color="secondary"
                variant="contained"
                aria-label="close"
                onClick={ handleClose }
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container={ true } className={ classes.body }>
            <Grid item={ true } sm={ 12 }>
              <BodyComponent />
            </Grid>
          </Grid>
        </div>
      </>
    </ModalWrapper>
  );
}

Modal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  BodyComponent: PropTypes.any,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
