import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import {
  useFetchCachesQuery,
  useDeleteCacheByHandleMutation,
} from 'features/adminApi/endpoints/cache';
import { bool } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { columns, getActions, getRows } from './lib';
import { Container } from '@material-ui/core';

const Cache = () => {
  const { addToast } = useToasts();
  const { data: cacheData, isLoading } = useFetchCachesQuery();
  const [deleteCache, deleteCacheResult] = useDeleteCacheByHandleMutation();

  const handleCacheClear = useCallback(
    (handle) => {
      deleteCache({ handle });
    },
    [deleteCache]
  );

  useEffect(() => {
    if (deleteCacheResult.isSuccess) {
      addToast('deleted successfully', {
        autoDismiss: true,
        appearance: 'success',
      });
    }
  }, [deleteCacheResult]);

  const actions = getActions({ clear: handleCacheClear });
  const rows = getRows(cacheData?.caches);

  return (
    <Container>
      <div data-testid="cache-container">
        { isLoading ? (
          <Loading text="fetching caches" />
        ) : (
          <DataTable
            rows={ rows }
            columns={ columns }
            actions={ actions }
            heading="Cache"
            loading={ false }
            muiGridProps={{
              getRowId: (row) => row?.handle,
            }}
          />
        ) }
      </div>
    </Container>
  );
};

// prop to use local data while endpoint is being made
Cache.propTypes = {
  localData: bool,
};

export default Cache;
