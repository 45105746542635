import React, { memo } from 'react';
import { applyTo, pipe, pathOr } from 'ramda';
// import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
// aliased
import { propTypes, defaultProps } from 'lib/react';
import FormContainer from 'components/FormContainer';
import { useOktaAuth } from '@okta/okta-react';
import CopyToClipboard from 'components/CopyToClipboard';

// local

export default applyTo(() => {
  const { authState } = useOktaAuth();

  return (
    <>
      <Typography variant='h2'>Access Token</Typography>
      <hr />
      <FormContainer>
        <CopyToClipboard label='Copy OKTA Token to Clipboard' text={ pathOr('', ['accessToken', 'accessToken'], authState) } />
      </FormContainer>
    </>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo
));
