import { innovationApiSlice } from '../innovationApiSlice';
import { pathOr } from 'ramda';

const userApiSlice = innovationApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      deleteUser: builder.mutation({
        query: upmId => ({
          url: `/users/${ upmId }`,
          method: 'DELETE',
        }),
      }),
      fetchUserByEmail: builder.query({
        query(email) {
          return `/users/email/${ encodeURIComponent(email) }`;
        },
        transformResponse: pathOr(null, ['upmId']),
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useDeleteUserMutation,
  useLazyFetchUserByEmailQuery,
} = userApiSlice;
