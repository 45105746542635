import React, { useEffect, useRef } from 'react';
import { useFetchScreenerAnswersQuery } from 'features/adminApi/endpoints/screener';
import { useToasts } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import styles from './index.module.scss';
import { prop } from 'ramda';
import { Link } from '@material-ui/core';

const errorToast = {
  appearance: 'error',
  autoDismiss: true,
};

const CandidateDownload = ({
  id,
  inquiryHandle,
  shortHandle,
  downloadCount,
}) => {
  const { addToast } = useToasts();
  const linkRef = useRef(null);

  const body = { id, inquiryHandle };
  //Conditionally calling hooks breaks the react-hooks rule so need to use skip
  const downloadData = useFetchScreenerAnswersQuery(body, { skip: !body });

  //eslint-disable-next-line max-statements
  async function downloadFile(download) {
    const binaryData = [download.data];
    const currentDate = new Date();
    const formattedDate = format(currentDate, 'MMddyy');

    const objectURL = new Blob(binaryData, {
      //To open file in ms.excel instead of browser
      type: 'application/vnd.ms-excel',
    });
    const customFileName = `${ shortHandle }-${ formattedDate }.csv`;

    linkRef.current.href = window.URL.createObjectURL(objectURL);
    linkRef.current.download = customFileName;
    linkRef.current.click();
    window.URL.revokeObjectURL(linkRef.current.href);
  }

  const showToast = (errorType) => {
    addToast(
      errorType
        ? 'No answers recorded for this screener'
        : 'Error downloading file',
      errorToast
    );
  };

  useEffect(() => {
    const errorStatus = prop('error', downloadData)?.status;
    const isError = errorStatus && errorStatus >= 400;

    if (isError) {
      showToast(isError);
    }

    if (prop('isSuccess', downloadData)) {
      downloadFile(downloadData);
    }
  }, [downloadData, downloadCount]);

  return <Link ref={ linkRef } className={ styles.linkStyle } />;
};

CandidateDownload.propTypes = {
  downloadCount: PropTypes.number,
  id: PropTypes.string,
  inquiryHandle: PropTypes.string,
  shortHandle: PropTypes.string,
};

export default CandidateDownload;
