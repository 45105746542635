/* eslint-disable complexity */
import { Box, Spinner, Text } from '@nike/eds';
import { AppleWatch, CaretCircleLeft } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';
import CtaButton from './components/CtaButton';
import Headline from './components/Headline';
import {
  DetailCardCoverImage,
  DetailCardText,
  PreviewCard,
  StyledContent,
  StyledHeroLogoDesktop,
  StyledHeroLogoMobile,
  StyledTagline,
  LogoStyled,
  HeroContainer,
  DynamicPageContentStyled,
  ButtonWrapper,
  HeroBottomStyled,
  displayCenter,
  PermissionsContainer,
  DetailCardContentContainer,
  CtaButtonContainer,
  AppleWatchContainer,
  LogoImageWrapper,
} from './styles';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import {
  cleanText,
  format,
  formatHighlightText,
  formatTable,
  formatText,
} from 'views/Initiative/Experiences/components/ManageAssets/components/Preview/utils';
import remarkRehype from 'remark-rehype';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import rehypeReact from 'rehype-react';
import { formatCloudinaryImage, permissionsList } from 'lib/layoutUtils';
export const HeroBaseCard = ({ cardType, content, loading }) => {
  const {
    headline,
    tagline,
    text: rawText,
    ctaText,
    backgroundImage,
    logoImage,
  } = content;

  //Removes extra slashes from rich text editor text
  const text = rawText && rawText.replace(/\n\\\n/g, '\n\n').replace(/\\/g, '');

  return (
    <div>
      { loading ? (
        <Spinner size="large" />
      ) : (
        <PreviewCard backgroundImage={ backgroundImage } cardType={ cardType }>
          { cardType === 'mobileReturningCard' && (
            <>
              <Box height="100%" width="100%">
                <img width="80%" src={ logoImage } />
              </Box>
              <div className="eds--dark">
                <CtaButton icon="ArrowRight" />
              </div>
            </>
          ) }
          { cardType === 'mobileOverviewCard' && (
            <>
              <Headline text={ headline } className="eds--dark" />
              <Box height="30px" width="auto">
                <img height="30px" width="auto" src={ logoImage } />
              </Box>
              <Headline tagline={ true } text={ tagline } className="eds--dark" />
              { ctaText && (
                <div className="eds--dark">
                  <CtaButton text={ ctaText } size="small" />
                </div>
              ) }
            </>
          ) }
          { cardType === 'mobileDetailScreen' && (
            <>
              <DetailCardCoverImage
                className="eds--dark"
                backgroundImage={ backgroundImage }
              >
                <CaretCircleLeft
                  className="caret-left-icon"
                  color="white"
                  size="l"
                />
                <LogoImageWrapper>
                  <img src={ logoImage } />
                </LogoImageWrapper>
                <DetailCardContentContainer>
                  <Headline text="Digital Experience" tagline={ true } />
                  <Headline text={ headline } headline={ true } font="title-3" />
                  { ctaText && <CtaButton text={ ctaText } size="medium" /> }
                </DetailCardContentContainer>
              </DetailCardCoverImage>
              <DetailCardText className="eds--light">
                <Headline
                  text={ tagline }
                  subtitle={ true }
                  font="title-2"
                  className="eds-spacing--mb-12"
                />
                <AppleWatchContainer>
                  <AppleWatch />
                  <Text
                    className="eds--light"
                    font="title-6"
                    children="Apple Watch required"
                  />
                </AppleWatchContainer>
                <div className="detail-card-text">
                  <Headline text={ text } tagline={ true } richText={ true } />
                </div>
              </DetailCardText>
              { ctaText && (
                <CtaButtonContainer className="eds--light">
                  <CtaButton text={ ctaText } size="large" variant="primary" />
                </CtaButtonContainer>
              ) }
              <PermissionsContainer className="eds-spacing--mt-48">
                <Headline
                  className="eds--light eds-spacing--mb-12"
                  text="Permissions Required"
                  { ...{ as: 'h5', font: 'title-4' } }
                />
                { permissionsList.map(({ label, icon }, index) => {
                  const PermissionIcon = icon;
                  return (
                    <div style={ displayCenter } key={ index }>
                      <PermissionIcon />
                      <Text font="body-2" className="eds--light">
                        { label }
                      </Text>
                    </div>
                  );
                }) }
              </PermissionsContainer>
            </>
          ) }
          { cardType === 'webDetailPage' && (
            <DynamicPageContentStyled>
              <HeroContainer
                backgroundImage={
                  backgroundImage &&
                  formatCloudinaryImage(backgroundImage, 2000)
                }
                className="eds--dark"
              >
                { logoImage && (
                  <LogoStyled
                    alt="initiative logo"
                    src={ logoImage && formatCloudinaryImage(logoImage, 300) }
                  />
                ) }
                { tagline && (
                  <StyledTagline>
                    <Text font="title-1" className="tagline">
                      { tagline }
                    </Text>
                  </StyledTagline>
                ) }
                <ButtonWrapper>
                  { ctaText && (
                    <CtaButton
                      className="hero-cta"
                      text={ ctaText }
                      size="large"
                      variant="primary"
                    />
                  ) }
                </ButtonWrapper>
              </HeroContainer>
              <HeroBottomStyled className="eds--light">
                { headline && <h1 className="headline">{ headline }</h1> }
                { text && (
                  <div className="body-text">
                    <ReactMarkdown
                      children={ cleanText(text) }
                      remarkPlugins={ [
                        remarkRehype,
                        [remarkGfm, {}],
                        remarkParse,
                      ] }
                      rehypePlugins={ [
                        [
                          rehypeReact,
                          {
                            createElement: React.createElement,
                            fragment: React.Fragment,
                          },
                        ],
                      ] }
                      linkTarget="_blank"
                      components={{
                        h1: formatText,
                        h2: formatText,
                        h3: formatText,
                        h4: formatText,
                        h5: formatText,
                        h6: formatText,
                        ul: format,
                        table: formatTable,
                        blockquote: formatText,
                        mark: formatText,
                        p: formatHighlightText,
                      }}
                    />
                  </div>
                ) }
              </HeroBottomStyled>
            </DynamicPageContentStyled>
          ) }
          { ['webOverviewCard', 'overviewCard'].includes(cardType) && (
            <>
              <StyledContent className="eds--dark">
                <div>
                  <Text font="body-2">{ tagline }</Text>
                  <Headline
                    text={ headline }
                    className="eds-spacing--mb-24 web-overview-card-headline"
                  />
                </div>
                <div>
                  <StyledHeroLogoMobile>
                    <img src={ logoImage } />
                  </StyledHeroLogoMobile>
                  <Headline tagline={ true } text={ text } richText={ true } />
                  { ctaText && <CtaButton preview={ true } text={ ctaText } /> }
                </div>
              </StyledContent>
              <StyledHeroLogoDesktop className="StyledHeroLogoDesktop">
                <img src={ logoImage } />
              </StyledHeroLogoDesktop>
            </>
          ) }
        </PreviewCard>
      ) }
    </div>
  );
};

HeroBaseCard.propTypes = {
  cardType: PropTypes.string.isRequired,
  content: PropTypes.shape({
    text: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
    backgroundImage: PropTypes.string,
    logoImage: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default HeroBaseCard;
