// import * as designTokens from '@nike/nike-design-tokens';
// const tokens = designTokens.nike;
// const fontTokens = tokens.i18nTokens.globalFallback;

// TODO: I tried using nike design tokens for the source of truth for fonts,
//       but mui only supports unitless line-height
// export default {
//   typography: {
//     //fontFamily: 'Nike TG, sans-serif',
//     ...fontTokens.typographyBody1,
//     h1: fontTokens.typographyHeading1,
//     h2: fontTokens.typographyHeading2,
//     h3: fontTokens.typographyHeading3,
//     h4: fontTokens.typographyHeading4,
//     h5: fontTokens.typographyHeading5,
//     h6: fontTokens.typographyHeading5, // no 6
//     button: fontTokens.typographyCta1,
//   },

//   overrides: {

//   },
// };

export default {
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      lineHeight: 2.5,
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 2,
    },
    h3: {
      fontSize: '1.75rem',
      lineHeight: 1.75,
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 1.25,
    },
    h6: {
      fontSize: '1rem',
      lineHeight: 1,
    },
    button: {
      fontSize: '1rem',
    },
  },

  overrides: {

  },
};
