/* eslint-disable react/prop-types */
/* eslint-disable max-statements */
import React, { useCallback, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormContainer } from 'components/FormContainer';
import AudienceInitiatives from 'views/shared/AudienceInitiatives';
import { useFetchInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import {
  Button,
  ButtonGroup,
  Link,
  Popover,
  Tab,
  TabGroup,
  Text,
} from '@nike/eds';
import { PlusCircle } from '@nike/nike-design-system-icons';
import { AudienceTypesEnum } from 'utils';
import styles from './index.module.scss';
import { handleCompleteRedirect } from './utils';
import useUserPermissions from '../../../lib/hooks/useUserPermissions';
import { EntityType } from 'lib/enums';

const Audiences = () => {
  const history = useHistory();
  const location = useLocation();
  const { returnUrl } = location.state || {};
  const tabAudienceType = sessionStorage.getItem('tabAudienceType');
  const { initiativeType, id, handle, viewMode = '' } = useParams();
  const { DYNAMIC_AUDIENCE, STAT_AUDIENCE, SHARED_AUDIENCE, INIT_AUDIENCE } =
    AudienceTypesEnum;
  const [audiencesType, setAudiencesType] = useState(
    tabAudienceType ?? INIT_AUDIENCE
  );
  const initHandle = id ?? handle;
  const initiative = useFetchInitiativeQuery(initHandle, { skip: !initHandle });
  const [popoverShown, setPopoverShown] = useState(false);
  const { isEditAllowed, isCreateAllowed: isCreateAudienceAllowed } =
    useUserPermissions(initHandle, EntityType.AUDIENCE);

  const headerMessage = `Audiences for ${ initiative?.data?.name || '' } ${
    !isEditAllowed ? ' (View Only)' : '(Add or Remove)'
  }`;

  const handleComplete = useCallback(async () => {
    handleCompleteRedirect(history, returnUrl, initiativeType);
  }, [returnUrl, initiativeType]);

  const handleChange = useCallback((event) => {
    const type = event.target.id;
    setAudiencesType(type);
  }, []);

  const handleButtonClick = useCallback(
    (audienceType) => {
      sessionStorage.setItem(
        'tabAudienceType',
        [DYNAMIC_AUDIENCE, STAT_AUDIENCE].includes(audienceType)
          ? SHARED_AUDIENCE
          : INIT_AUDIENCE
      );
      history.push({
        pathname: '/audience/create',
        state: {
          audienceType,
          initiativeHandle: initHandle,
          fromTabAudiences: true,
        },
      });
    },
    [history]
  );

  const handlePopoverClick = useCallback(
    () => setPopoverShown(!popoverShown),
    []
  );

  const handleDynamicClick = useCallback(() => {
    handleButtonClick(DYNAMIC_AUDIENCE);
  });

  const handleStaticClick = useCallback(() => {
    handleButtonClick(STAT_AUDIENCE);
  });

  const handleInitiativeStaticClick = useCallback(() => {
    handleButtonClick(INIT_AUDIENCE);
  });

  return (
    <FormContainer>
      <Text font="title-1" as="h1">
        { headerMessage }
      </Text>
      <TabGroup
        name="audiences-type-tabs"
        activeId={ audiencesType }
        onChange={ handleChange }
        style={ styles.tabGroupContainer }
      >
        <Tab id={ INIT_AUDIENCE }>Initiative Audiences</Tab>
        <Tab id={ SHARED_AUDIENCE }>Shared Audiences</Tab>
      </TabGroup>
      { isCreateAudienceAllowed && (
        <div className={ styles.audienceCreateBtn }>
          { audiencesType === SHARED_AUDIENCE ? (
            <Popover
              onClickOutside={ handlePopoverClick }
              isOpen={ popoverShown }
              bodySlot={
                <ButtonGroup className={ styles.createBtnGroup }>
                  <Link
                    variant="secondary"
                    color="secondary"
                    onClick={ handleDynamicClick }
                    children="New Dynamic audience"
                  />
                  <Link
                    variant="secondary"
                    color="secondary"
                    onClick={ handleStaticClick }
                    children="New Static audience"
                  />
                </ButtonGroup>
              }
              placement="right-start"
            >
              <Button onClick={ handlePopoverClick }>
                Create <PlusCircle />
              </Button>
            </Popover>
          ) : (
            <Button onClick={ handleInitiativeStaticClick }>
              Create <PlusCircle />
            </Button>
          ) }
        </div>
      ) }
      <AudienceInitiatives
        initiativeHandle={ initHandle }
        onComplete={ handleComplete }
        isEditAllowed={ isEditAllowed }
        isCreateAudienceAllowed={ isCreateAudienceAllowed }
        audiencesType={ audiencesType }
        viewMode={ viewMode }
      />
    </FormContainer>
  );
};

export default Audiences;
