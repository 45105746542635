export const dateFormats = [
  { format: 'MMM Do h:mm A', example: 'Sep 5th 4:30 AM' },
  { format: 'MMM Do [at] h:mma', example: 'Sep 5th at 9:00pm' },
  { format: 'MMMM D', example: 'September 5' },
  { format: 'MMMM D, YYYY', example: 'September 5, 2024' },
  { format: 'MMMM Do YYYY', example: 'September 5th 2024' },
  { format: 'D MMMM YYYY', example: '5 September 2024' },
  { format: 'Do MMMM YYYY', example: '5th September 2024' },
  { format: 'D MMM YYYY', example: '5 Sep 2024' },
  { format: 'MMMM D, YYYY h A', example: 'September 5, 2024 4 PM' },
  { format: 'MMM D', example: 'Sep 5' },
  { format: 'MMM D, YYYY', example: 'Sep 5, 2024' },
  { format: 'MMM Do YY', example: 'Sep 5th 24' },
  { format: 'MMMM D, YYYY hh:mm A', example: 'September 5, 2024 04:00 PM' },
  { format: 'MMMM D, YYYY hh:mm a', example: 'September 5, 2024 04:00 pm' },
  { format: 'dddd, MMMM D, YYYY', example: 'Thursday, September 5, 2024' },
  { format: 'dddd, MMMM DD YYYY', example: 'Thursday, September 05 2024' },
  { format: 'dddd, MMMM Do YYYY', example: 'Thursday, September 5th 2024' },
  {
    format: 'dddd, MMMM Do, YYYY h:mm A',
    example: 'Thursday, September 5th, 2024 4:00 PM',
  },
  { format: 'M/D/YYYY h:mm A', example: '9/5/2024 4:00 PM' },
  { format: 'MM/DD/YYYY', example: '09/05/2024' },
  { format: 'h:mm A', example: '4:00 PM' },
  { format: 'h:mm a', example: '4:00 pm' },
  { format: 'hh:mm A', example: '04:00 PM' },
  { format: 'hh:mm a', example: '04:00 pm' },
  { format: 'HH:mm', example: '16:00' },
  { format: 'M', example: '9' },
  { format: 'Mo', example: '9th' },
  { format: 'MM', example: '09' },
  { format: 'MMM', example: 'Sep' },
  { format: 'MMMM', example: 'September' },
  { format: 'Q', example: '3' },
  { format: 'Qo', example: '3rd' },
  { format: 'D', example: '5' },
  { format: 'Do', example: '5th' },
  { format: 'DD', example: '05' },
  { format: 'd', example: '4' },
  { format: 'do', example: '4th' },
  { format: 'dd', example: 'Th' },
  { format: 'ddd', example: 'Thu' },
  { format: 'dddd', example: 'Thursday' },
  { format: 'YYYY', example: '2024' },
  { format: 'YY', example: '24' },
  { format: 'h', example: '4' },
  { format: 'hh', example: '04' },
  { format: 'H', example: '16' },
  { format: 'HH', example: '16' },
  { format: 'k', example: '16' },
  { format: 'kk', example: '16' },
  { format: 'm', example: '4' },
  { format: 'mm', example: '04' },
  { format: 'A', example: 'PM' },
  { format: 'a', example: 'pm' },
  { format: 'do', example: '1st, 2nd, 3rd, 4th, 5th, 6th' },
  { format: 'LT', example: '4:00 PM' },
  { format: 'LTS', example: '4:00:00 PM' },
  { format: 'L', example: '09/05/2024' },
  { format: 'l', example: '9/5/2024' },
  { format: 'LL', example: 'September 5, 2024' },
  { format: 'll', example: 'Sep 5, 2024' },
  { format: 'LLL', example: 'September 5, 2024 8:30 PM' },
  { format: 'lll', example: 'Sep 5, 2024 8:30 PM' },
  { format: 'LLLL', example: 'Thursday, September 5, 2024 8:30 PM' },
  { format: 'llll', example: 'Thu, Sep 5, 2024 8:30 PM' },
  { format: 'MM-DD-YYYY [some text...]', example: '09-05-2024 some text...' },
];

export const stringFormats = [
  { format: 'capitalize', example: 'Weight lifting' },
  { format: 'capitalizeAll', example: 'Weight Lifting' },
];

export const headers = [
  { Header: 'Format String' },
  { Header: 'Example Output' },
  { Header: 'Actions' },
];
