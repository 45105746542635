import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import HeroBaseCard from 'components/CardLayout';
import { Label } from '@nike/eds';
import styles from './styles.module.scss';
import _camelCase from 'lodash/camelCase';
import _mapKeys from 'lodash/mapKeys';
import RichTextPreview from './RichTextPreview';
import { renderCtasBasedOnParticipation } from '../../utils';

const Preview = ({ show, cardType, content }) => {
  const cardContent = _mapKeys(content, (_v, key) => {
    if (key === 'title') return 'headline';
    if (key === 'subtitle') return 'tagline';
    if (key === 'backgroundImageFileId') return 'backgroundImage';
    if (key === 'logoImageFileId') return 'logoImage';

    return _camelCase(key);
  });
  const modifiedCardContent = renderCtasBasedOnParticipation(cardContent);

  return (
    <div className={ styles.preview }>
      <Label font="title-3" className={ styles.title }>
        Preview
      </Label>
      { cardType === 'consentDocument' ? (
        <RichTextPreview content={ content?.updatedFields || content } />
      ) : (
        <HeroBaseCard cardType={ cardType } content={ modifiedCardContent } />
      ) }
    </div>
  );
};

Preview.propTypes = {
  cardType: PropTypes.string,
  content: oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  show: PropTypes.bool,
};

export default Preview;
