import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import { useField } from 'formik';

// NOTE: the "value" (field.value) will be an ISOString.
const DateField = ({
  label,
  minDate,
  maxDate,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = useCallback(value => {
    const {
      setValue,
      setTouched,
    } = helpers;
    setTouched(true);
    setValue(new Date(value).toISOString());
  }, [helpers]);
  
  return (
    <DateTimePicker
      { ...field }
      { ...props }
      onChange={ handleChange }
      variant='inline'
      autoOk={ true }
      fullWidth={ true }
      label={ label }
      minDate={ minDate }
      maxDate={ maxDate }
      error={ !!(meta.touched && meta.error) }
      helperText={ meta.error }
      value={ field.value } />
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

DateField.defaultProps = {
  label: '',
};

export default memo(DateField);
