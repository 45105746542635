import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditConfig from './Edit';
import ConfigListView from './List';

export const ConfigEdit = () => {
  return (
    <Switch>
      <Route path="/dev/config" exact={ true } component={ ConfigListView } />
      <Route
        path="/dev/config/edit/config-admin-permission-roles"
        component={ EditConfig }
      />
      <Route path="/dev/config/edit/:configHandle" component={ EditConfig } />
      <Route component={ ConfigListView } />
    </Switch>
  );
};
export default ConfigEdit;
