import * as Yup from 'yup';
import {
  nonSpecialCharRegex,
  nonSpace,
  invalidCharacterError,
  requiredError,
  uuidFormatError,
  spacesNotAllowedError,
} from '../utils';

export const validationSchema = Yup.object().shape({
  attributes: Yup.object().shape({
    collectionId: Yup.string().uuid(uuidFormatError)
      .nullable(),
    studyId: Yup.string().uuid(uuidFormatError)
      .nullable(),
    clientName: Yup.string().matches(
      nonSpecialCharRegex,
      invalidCharacterError
    ),
  }),
  name: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  handle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  shortHandle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .matches(nonSpace, spacesNotAllowedError)
    .required(requiredError),
  moduleHandle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .matches(nonSpace, spacesNotAllowedError)
    .nullable()
    .required(requiredError),
  initiativeType: Yup.string().required(requiredError),
  users: Yup.boolean(),
  organization: Yup.string().required(requiredError)
    .nullable(true),
  unlimitedParticipants: Yup.boolean(),
  participantLimit: Yup.number()
    .when('unlimitedParticipants', {
      is: false,
      then: Yup.number().min(1)
        .required(requiredError),
    })
    .nullable(true),

  allPpis: Yup.boolean(),
  requiredPpis: Yup.array()
    .of(Yup.string())
    .when('allPpis', {
      is: false,
      then: Yup.array().of(Yup.string())
        .min(4)
        .required(requiredError),
    })
    .nullable(),
});

// Initiative model
// https://github.com/nike-l2w/initiative-api/blob/main/src/initiative/resources/models/InitiativeModel.js#L7
// this.id = { type: 'string' }
// this.handle = { type: 'string' }
// this.name = { type: 'string' }
// this.initiativeType = { type: 'string' }
// this.status = { type: 'string' }
// this.attributes = { type: 'json' }
// this.platforms = { type: 'array' }
// this.webThreadId = { type: 'string' }
// this.mobileThreadId = { type: 'string' }
// this.createTimestamp = { type: 'timestamp' }
// this.updateTimestamp = { type: 'timestamp' }

export const INITIAL_VALUES = {
  name: '',
  handle: '',
  moduleHandle: '',
  initiativeType: '',
  status: 'inactive',
  attributes: {},
  platforms: [],
  webThreadId: '',
  mobileThreadId: '',
  participantLimit: false,
  users: true,
  requiredPpis: [
    'ppi-first-name',
    'ppi-last-name',
    'ppi-email',
    'ppi-date-of-birth',
  ],
  organization: null,
  isConsentRequired: false,
};

export const TYPE_OPTIONS = [
  {
    label: 'Digital Service',
    value: 'digital-service',
  },
  {
    label: 'Core Initiative',
    value: 'core',
  },
  {
    label: 'Activity Survey',
    value: 'activity-survey',
  },
];

// Not Required for Experiences
export const DIGITAL_SERVICE_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  { label: 'Inactive', value: 'inactive' },
];

export const PARTICIPATION_STATUS_OPTIONS = [
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Full',
    value: 'FULL',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Archived',
    value: 'ARCHIVED',
  },
];

export const ORGANIZATION_ASSOCIATION_OPTIONS = [
  {
    label: 'JTL',
    value: 'JTL',
  },
  {
    label: 'NSRL',
    value: 'NSRL',
  },
  {
    label: 'VL',
    value: 'VL',
  },
  {
    label: 'NSS',
    value: 'NSS',
  },
  {
    label: 'SSP-Activity-Data',
    value: 'SSP-Activity-Data',
  },
];

export const PPI_OPTIONS = [
  {
    label: 'First Name',
    value: 'ppi-first-name',
    isFixed: true,
  },
  {
    label: 'Last Name',
    value: 'ppi-last-name',
    isFixed: true,
  },
  {
    label: 'Date of Birth',
    value: 'ppi-date-of-birth',
    isFixed: true,
  },
  {
    label: 'Nike Affiliation',
    value: 'ppi-nike-affiliation',
  },
  {
    label: 'Email',
    value: 'ppi-email',
    isFixed: true,
  },
  {
    label: 'Phone Number',
    value: 'ppi-phone-number',
  },
  {
    label: 'Located Near Portland',
    value: 'ppi-located-near-portland-metro',
  },
  {
    label: 'Height',
    value: 'ppi-height',
  },
  {
    label: 'Weight',
    value: 'ppi-weight',
  },
  {
    label: 'Gender Identity',
    value: 'ppi-gender-identity',
  },
  {
    label: 'Race',
    value: 'ppi-race',
  },
  {
    label: 'Sport Preferences',
    value: 'ppi-sport-preferences',
  },
  {
    label: 'Mens Shoe Size',
    value: 'ppi-mens-shoe-size',
  },
  {
    label: 'Womens Shoe Size',
    value: 'ppi-womens-shoe-size',
  },
  {
    label: 'Mens Top Size',
    value: 'ppi-mens-top-size',
  },
  {
    label: 'Mens Bottom Size',
    value: 'ppi-mens-bottom-size',
  },
  {
    label: 'Womens Top Size',
    value: 'ppi-womens-top-size',
  },
  {
    label: 'Womens Bottom Size',
    value: 'ppi-womens-bottom-size',
  },
  {
    label: 'Shoe Size',
    value: 'ppi-shoe-size',
  },
  {
    label: 'Postal Code',
    value: 'ppi-postal-code',
  },
];

export const PLATFORM_OPTIONS = [
  {
    label: 'Web',
    value: 'WEB',
  },
  {
    label: 'Mobile',
    value: 'MOBILE',
  },
  {
    label: 'Watch',
    value: 'WATCH',
  },
];

export const moduleHandleArray = ['Module Handle', 'moduleHandle'];
