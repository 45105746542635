import React from 'react';
import pt from 'prop-types';
import { Card, Icon } from '@nike/eds';
import CardToolbar from './AssetCardToolbar';
import CardContent from './AssetCardContent';
import { ContentStatus } from 'lib/enums';
import styles from '../../styles.module.scss';
import FlexCenter from 'components/FlexCenter';

const AssetCard = ({ handle, type, title, versions = [], id }) => {
  const status = versions.filter(
    (version) => version.status === ContentStatus.PREVIEW
  ).length
    ? ContentStatus.PREVIEW
    : versions.filter((version) => version.status === ContentStatus.PUBLISHED)
      .length
      ? ContentStatus.PUBLISHED
      : ContentStatus.PREVIEW;

  const cardStatus = status === ContentStatus.PREVIEW ? 'Draft' : status;

  //Sorting details card
  const modifiedVersions =
    versions.length > 1
      ? versions?.sort((itm1, itm2) => {
        const handleA = itm1?.handle?.toLowerCase();
        const handleB = itm2?.handle?.toLowerCase();

        return (
          (handleA?.includes('completed') ? -1 : 0) -
              (handleB?.includes('completed') ? -1 : 0) ||
            (handleA?.includes('full') ? -1 : 0) -
              (handleB?.includes('full') ? -1 : 0)
        );
      })
      : versions;

  return (
    <Card className={ styles.assetCard } data-testid="asset-card">
      { versions.length ? (
        <>
          <CardToolbar title={ title } status={ cardStatus } />
          <CardContent
            handle={ handle }
            versions={ modifiedVersions?.sort((itm1, itm2) =>
              itm1.status.localeCompare(itm2.status)
            ) }
            type={ type }
            id={ id }
          />
        </>
      ) : (
        <FlexCenter>
          <Icon name="PlusCircle" size="l" />
        </FlexCenter>
      ) }
    </Card>
  );
};

AssetCard.propTypes = {
  handle: pt.string,
  id: pt.string,
  status: pt.string,
  title: pt.string,
  type: pt.string,
  /* eslint-disable */
  versions: pt.arrayOf(pt.object),
};

export default AssetCard;
