/* eslint-disable max-statements */
/* eslint-disable react/prop-types */
import React, { Fragment, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormContainer } from 'components/FormContainer';
import AudienceInitiatives from 'views/shared/AudienceInitiatives';
import { useFetchInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import { Button, Text } from '@nike/eds';
import { PlusCircle } from '@nike/nike-design-system-icons';
import styles from './index.module.scss';
import { handleCompleteRedirect } from '../utils';
import useUserPermissions from '../../../../lib/hooks/useUserPermissions';
import { EntityType, ViewMode } from 'lib/enums';

const CreateAudiences = () => {
  const history = useHistory();
  const { initiativeType, id, handle } = useParams();
  const location = useLocation();
  const { returnUrl } = location.state || {};
  const initHandle = id ?? handle;
  const initiative = useFetchInitiativeQuery(initHandle, { skip: !initHandle });
  const { isEditAllowed, isCreateAllowed: isCreateAudienceAllowed } =
    useUserPermissions(initHandle, EntityType.AUDIENCE);

  const headerMessage = `Audiences for ${ initiative?.data?.name || '' }`;
  const subTitleMessage =
    'Create a new audience for the initiative or add existing audience to the initiative';

  const handleComplete = useCallback(async () => {
    handleCompleteRedirect(history, returnUrl, initiativeType);
  }, [returnUrl, initiativeType]);

  const handleButtonClick = useCallback(
    (audienceType) => {
      history.push({
        pathname: '/audience/create',
        state: {
          audienceType,
          initiativeHandle: initHandle ?? null,
          viewMode: ViewMode.CREATE,
          prevURL: history.location.pathname,
          returnUrl,
          initiativeType,
        },
      });
    },
    [initHandle, history]
  );

  const handleInitiativeStaticClick = useCallback(() => {
    handleButtonClick('Initiative Static');
  });

  return (
    <FormContainer>
      <Text font="title-1">{ headerMessage }</Text>
      <Text font="subtitle-1" className={ styles.subTitleMessageStyle }>
        { subTitleMessage }
      </Text>
      { isCreateAudienceAllowed && (
        <Fragment>
          <Text font="title-4" className={ styles.addMessageStyle }>
            Add a New Audience
          </Text>
          <Button
            onClick={ handleInitiativeStaticClick }
            className={ styles.createAudienceBtn }
            size="large"
          >
            Create Audience <PlusCircle />
          </Button>
        </Fragment>
      ) }
      <Text font="title-4" className={ styles.addExistingMessageStyle }>
        Add an Existing Audience
      </Text>
      <AudienceInitiatives
        initiativeHandle={ initHandle }
        isEditAllowed={ isEditAllowed }
        isCreateAudienceAllowed={ isCreateAudienceAllowed }
        onComplete={ handleComplete }
        viewMode={ ViewMode.CREATE }
      />
    </FormContainer>
  );
};

export default CreateAudiences;
