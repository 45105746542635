import add from 'date-fns/add';
import format from 'date-fns/format';

export const statusMap = {
  complete: 'Complete',
  inprogress: 'In Progress',
};

export const getResolutionDeadline = date => {
  const datePlus90 = add(new Date(date), { days: 90 });
  return format(new Date(datePlus90), 'MM/dd/yyyy');
};
