/* eslint-disable max-statements */
import { useCallback, useEffect, useState } from 'react';
import { useCreateImageMutation } from 'features/adminApi/endpoints/image';

const fileErrorMessage =
  'Please upload an image that meets size specifications';

export default ({ defaultImageUrl = null, defaultFileId = null }) => {
  const [uploadFailed, setUploadHasFailed] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(defaultImageUrl);
  const [fileName, setFileName] = useState('');
  const [percentComplete, setPercentComplete] = useState(0);
  const [uploadIsHealthy, setUploadIsHealthy] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileId, setFileId] = useState(defaultFileId);

  const [useCreateImageRequest, imagesResult] = useCreateImageMutation();

  useEffect(() => {
    if (!fileId) {
      return;
    }
    setPreviewUrl(fileId);
  }, [fileId]);

  const resetFileSelection = () => {
    setIsUploading(false);
    setPercentComplete(0);
    setUploadIsHealthy(true);
    setFileId(null);
    if (previewUrl) {
      setPreviewUrl(defaultImageUrl);
    } else {
      setPreviewUrl(null);
    }
  };
  useEffect(() => {
    // if a default changes, then assume it wins -- e.g. upstream changes
    setFileId(defaultFileId);
  }, [defaultFileId]);

  // HANDLE PENDING IMAGE -------------------------------------------
  useEffect(() => {
    setErrorMessage();
    if (imagesResult.status === 'pending') {
      setPercentComplete(50);
    }
  }, [imagesResult]);

  // HANDLE SUCCESSFUL IMAGE POST -------------------------------------------
  useEffect(() => {
    if (imagesResult.status === 'fulfilled') {
      const fileId = imagesResult.data?.url;
      setIsUploading(false);
      setPercentComplete(100);
      setFileId(fileId);
    }
  }, [imagesResult.status]);

  //HANDLE IMAGE ERROR ------------------------------------------------
  useEffect(() => {
    if (imagesResult.isError === true) {
      setUploadHasFailed(true);
      setErrorMessage('Error occurred');
      setPercentComplete(0);
      resetFileSelection();
    }
  }, [imagesResult.error?.error, imagesResult.isError]);

  const onDrop = useCallback((acceptedFiles) => {
    setUploadHasFailed(false);
    const acceptedFile = acceptedFiles[0];
    if (!acceptedFile) {
      setUploadHasFailed(true);
      setErrorMessage(fileErrorMessage);
      return;
    }
    const localPreviewURL = URL.createObjectURL(acceptedFile);

    if (localPreviewURL) {
      setUploadHasFailed(false);
      setErrorMessage('');
      setFileName(acceptedFile.name);
      setPreviewUrl(localPreviewURL);
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', acceptedFile);

      useCreateImageRequest(formData)
        .then((fileId) => {
          setIsUploading(false);
        })
        .catch((error) => {
          setUploadHasFailed(true);
          setErrorMessage('Error occurred');
          setPercentComplete(0);
          resetFileSelection();
        });
    }
  }, []);

  return {
    onDrop,
    percentComplete,
    uploadIsHealthy,
    fileId,
    fileName,
    uploadFailed,
    isUploading,
    previewUrl,
    errorMessage,
    resetFileSelection, // call this to remove the file
  };
};
