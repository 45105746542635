import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const adminApiSliceWithTags = adminApiSlice.enhanceEndpoints({
  addTagTypes: ['userDeletionAudits'],
});

export const fetchProvidesTags = (result) => {
  return result?.length
    ? [
      ...result.map(({ id }) => ({ type: 'userDeletionAudits', id })),
      { type: 'userDeletionAudits', id: 'LIST' },
    ]
    : [{ type: 'userDeletionAudits', id: 'LIST' }];
};

const dataRequestsApiSlice = adminApiSliceWithTags.injectEndpoints({
  endpoints(builder) {
    return {
      fetchDataRequestsInitiatives: builder.query({
        query(id) {
          return `/userDeletionAudits/${ id }/initiatives`;
        },
        providesTags: (result) => fetchProvidesTags(result?.initiatives),
      }),
      fetchDataRequests: builder.query({
        query(status) {
          return `/userDeletionAudits/status/${ status }`;
        },
        providesTags: fetchProvidesTags,
        transformResponse: (response, _, status) =>
          pathOr([], ['userDeletionAudits'], response).map((data) => ({
            ...data,
            status,
          })),
      }),
      fetchDataRequest: builder.query({
        query(id) {
          return `/userDeletionAudits/${ id }`;
        },
        providesTags: (_result, _error, id) => [
          { type: 'userDeletionAudits', id },
        ],
      }),
      patchDataRequest: builder.mutation({
        query: (body) => {
          return {
            url: `/userDeletionAudits/${ body.id }`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: (_result, _error, { id }) => [
          { type: 'userDeletionAudits', id },
        ],
      }),
      patchDataRequestInitiative: builder.mutation({
        query: (body) => {
          return {
            url: `/userDeletionAudits/${ body.id }/initiatives/${ body.handle }`,
            method: 'PATCH',
            body: { status: body.status },
          };
        },
        providesTags: (result, _error, item) => {
          return result.length
            ? [
              ...result.map(({ id }) => ({
                type: 'userDeletionAudits',
                id,
              })),
              { type: 'userDeletionAudits', id: 'LIST' },
            ]
            : [{ type: 'userDeletionAudits', id: 'LIST' }];
        },
        invalidatesTags: ['userDeletionAudits'],
      }),

      createDataRequests: builder.mutation({
        query: (body) => ({
          url: '/userDeletionAudits',
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'userDeletionAudits', id: 'LIST' }],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchDataRequestsInitiativesQuery,
  useFetchDataRequestQuery,
  useFetchDataRequestsQuery,
  useCreateDataRequestsMutation,
  usePatchDataRequestMutation,
  usePatchDataRequestInitiativeMutation,
} = dataRequestsApiSlice;
