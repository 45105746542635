import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { applyTo, pipe, path } from 'ramda';
import config from 'conf';

export const BASE_URL = config.ADMIN_API_HOST;
export const BASE_URL_V2 = config.ADMIN_API_HOST_V2;

const cmsAPIS = [
  'fetchScreener',
  'fetchSurvey',
  'fetchConsent',
  'fetchContent',
];

export const apiHeaders = () => {
  const headers = new Headers();
  const token = JSON.parse(localStorage.getItem('okta-token-storage'))
    ?.accessToken?.accessToken;

  headers.set('x-l2w-token-type', 'okta');
  headers.set('content-type', 'application/json');
  headers.set('authorization', `Bearer ${ token }`);

  return headers;
};

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const baseUrl = cmsAPIS.includes(api.endpoint) ? BASE_URL_V2 : BASE_URL;

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders() {
      return apiHeaders();
    },
  })(args, api, extraOptions);
};

export const adminApiSlice = createApi({
  reducerPath: 'adminApi',
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});

export const adminApiSliceFileUpload = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders(headers) {
      const token = applyTo(
        window.localStorage.getItem('okta-token-storage', '{}'),
        pipe(JSON.parse, path(['accessToken', 'accessToken']))
      );
      headers.set('x-l2w-token-type', 'okta');
      headers.set('accept', 'application/json');
      headers.set('type', 'formData');
      headers.set('authorization', `Bearer ${ token }`);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
