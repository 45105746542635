import { pick } from 'ramda';

export const surveyPrefix = 'svy';
export const activitySurveyPrefix = 'activity-survey';

export const pickFields = pick([
  'handle',
  'shortHandle',
  'initiativeType',
  'mobileThreadId',
  'name',
  'participantCount',
  'participantLimit',
  'participationStatus',
  'platforms',
  'requiredPpis',
  'status',
  'webThreadId',
]);

export const pickAttributeFields = pick([
  'numberOfQuestions',
  'timeToCompletion',
  'description',
  'url',
  'surveyId',
]);
