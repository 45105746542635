import { adminApiSlice } from '../adminApiSlice';

const cmsApiSlice = adminApiSlice.injectEndpoints({
  tagTypes: ['cms', 'compiled-consent'],
  endpoints(builder) {
    return {
      compileLayout: builder.mutation({
        query: (layoutHandle) => ({
          url: `/cms/layouts/${ layoutHandle }/compile`,
          method: 'POST',
        }),
        invalidatesTags: [{ type: 'cms', id: 'LIST' }, 'compiled-consent'],
      }),
      compileInquiry: builder.mutation({
        query: (params) => ({
          url: `/initiatives/${ params.initiativeHandle }/compile/inquiries/${ params.inquiryHandle }`,
          method: 'POST',
        }),
        invalidatesTags: (_result, _error, { id }) => {
          return [{ type: 'inquiry', id }];
        },
      }),
      compileConsent: builder.mutation({
        query: (params) => ({
          url: `/initiatives/${ params.initiativeHandle }/compile/consents`,
          method: 'POST',
        }),
        invalidatesTags: (_result, _error, { id }) => {
          return [{ type: 'consent', id }];
        },
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useCompileLayoutMutation,
  useCompileInquiryMutation,
  useCompileConsentMutation,
} = cmsApiSlice;
