import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import SingleImageUpload from 'components/fields/MediaUpload';
import styles from './index.module.scss';
import { Field } from 'formik';

export const ImageField = ({
  fieldId,
  fieldValue,
  fieldLabel,
  fieldName,
  className,
  placeholder,
  disabled,
  onChange,
}) => {
  return (
    <Field
      key={ fieldId }
      name={ fieldName }
      placeholder={ placeholder || fieldName }
      className={ styles.backgroundImage }
    >
      { ({ form }) => {
        return (
          <FieldWrapper disabled={ disabled } className={ className }>
            <SingleImageUpload
              key={ fieldId }
              id={ fieldName }
              name={ fieldName }
              label={ fieldLabel }
              canAdd={ false }
              defaultValue={ fieldValue }
              setValue={ onChange }
              size="small"
              helperText={
                'Please upload a background image./Recommended size: 1280px x 760px./Max file size: 1 MB.'
              }
              setFieldTouched={ form.setFieldTouched }
            />
          </FieldWrapper>
        );
      } }
    </Field>
  );
};

ImageField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
