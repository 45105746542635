// modules
import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { userEntityPermissions } from 'components/RBAC';

// aliased
import { EntityType } from 'lib/enums';

// local
import styles from './HomeView.module.scss';
import { FlexCenter } from 'components/FlexCenter';
import Link from 'components/Link';

const HomeView = () => {
  return (
    <Container className={ styles.wrapper }>
      <FlexCenter style={{ height: '100%' }}>
        <div className={ styles.content }>
          <Typography variant="h3" align="center">
            JTL Admin Console Dashboard
          </Typography>
          <Typography variant="body1" align="center">
            Quick Links:
          </Typography>
          { /* <Button
            component={ Link }
            variant='outlined'
            to='/initiative/create'>
            Create an Initiative
          </Button> */ }

          { userEntityPermissions(EntityType.INITIATIVE) && (
            <>
              <Button
                component={ Link }
                variant="outlined"
                to="/initiative/digital-service"
              >
                View Initiatives
              </Button>
            </>
          ) }
          { /* <Button
            component={ Link }
            variant='outlined'
            to='/audience/create'>
            Create an Audience
          </Button> */ }
          { userEntityPermissions(EntityType.AUDIENCE) && (
            <Button component={ Link } variant="outlined" to="/audience">
              View Audiences
            </Button>
          ) }
          { /* <Button
            component={ Link }
            variant='outlined'
            to='/dataRequests/create'>
            Create a Delete Request
          </Button> */ }
          { userEntityPermissions(EntityType.DATA_REQUEST) && (
            <Button component={ Link } variant="outlined" to="/dataRequests">
              View Data Requests
            </Button>
          ) }
          { userEntityPermissions(EntityType.CACHE) && (
            <Button component={ Link } variant="outlined" to="/cache">
              View Cache
            </Button>
          ) }
          { userEntityPermissions(EntityType.DEV_TOOLS) && (
            <Button component={ Link } variant="outlined" to="/dev">
              Dev Tools
            </Button>
          ) }
        </div>
      </FlexCenter>
    </Container>
  );
};

export default HomeView;
