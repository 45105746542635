import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import { Field } from 'formik';
import { Checkbox } from '@nike/eds';

export const ChoiceComponent = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  className,
  onChange,
  disabled,
}) => {
  return (
    <Field key={ fieldId } name={ fieldName }>
      { () => (
        <FieldWrapper disabled={ disabled } className={ className }>
          <Checkbox
            id={ fieldId }
            name={ fieldName }
            label={ fieldLabel }
            checked={ fieldValue }
            onChange={ onChange }
            disabled={ disabled }
          />
        </FieldWrapper>
      ) }
    </Field>
  );
};

ChoiceComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.bool,
  onChange: PropTypes.func,
};
