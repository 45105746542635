import { Button as NikeButton } from '@nike/eds';
import { node } from 'prop-types';
import React from 'react';

const Button = ({ children, ...buttonProps }) => {
  return <NikeButton { ...buttonProps }>{ children }</NikeButton>;
};

Button.propTypes = {
  children: node,
};

export default Button;
