import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'components/DataTable';
import {
  useFetchEventJobByIdQuery,
  useFetchEventJobsQuery,
} from 'features/adminApi/endpoints/eventJobs';
import Loading from 'components/Loading';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { columns, devUrl, getRows } from './lib';
import SearchBar from '../SearchBar';
import { isEmpty } from 'lodash';

// eslint-disable-next-line max-statements
export const EventJobs = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const {
    data: eventJobs,
    isLoading,
    isError,
    error,
  } = useFetchEventJobsQuery();
  const evtJobs = getRows(eventJobs);
  const [searchValue, setSearchValue] = useState('');
  const [submit, setSubmit] = useState(false);
  const [filteredRows, setFilteredRows] = useState(evtJobs);

  const actions = [
    {
      key: 'jobId',
      label: 'view',
      url: devUrl,
      action: 'create',
    },
  ];

  const {
    data: eventJob,
    isFetching,
    isError: isJobIdError,
  } = useFetchEventJobByIdQuery(searchValue, {
    skip: !submit || searchValue === '',
  });

  // eslint-disable-next-line max-statements
  useEffect(() => {
    if (searchValue.trim() === '') {
      const jobs = [...evtJobs];

      if (!isEmpty(eventJob)) {
        jobs.unshift(eventJob);
        setFilteredRows(jobs);
        return;
      }
      setFilteredRows(evtJobs);
    } else {
      const filteredRow = evtJobs.filter(
        (row) => row.jobId.toLowerCase() === searchValue.toLowerCase()
      );
      if (filteredRow.length) setFilteredRows(filteredRow);
      if (filteredRow.length === 0 && submit && !isFetching) {
        setSubmit((prevState) => !prevState);
        if (!isJobIdError) {
          if (!isEmpty(eventJob)) {
            setFilteredRows(getRows([eventJob]));
          }
        } else {
          addToast('Job Id is not found', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    }
  }, [eventJobs, searchValue, submit, eventJob, isJobIdError, isFetching]);

  useEffect(() => {
    if (isError && !isLoading) {
      addToast(`${ error?.error }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError]);

  const handleRowClick = useCallback(
    ({ colDef: { field }, row: { jobId } }) => {
      if (field !== 'actions' && jobId) {
        history.push(`${ devUrl }${ jobId }`);
      }
    },
    [history]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleSearchSubmit = useCallback(() => {
    setSubmit((prevState) => !prevState);
  }, [searchValue]);

  return (
    <div data-testid="event-jobs-container">
      { isLoading ? (
        <Loading text="fetching event-jobs" />
      ) : (
        <div>
          <SearchBar
            jobSearch={ true }
            searchValue={ searchValue }
            handleSearchChange={ handleSearchChange }
            handleSearchSubmit={ handleSearchSubmit }
            disabled={ !searchValue.length }
            isFetching={ isFetching }
          />
          { filteredRows.length && (
            <DataTable
              rows={ filteredRows }
              columns={ columns }
              actions={ actions }
              heading="Event Jobs"
              loading={ false }
              muiGridProps={{
                getRowId: (row) => row?.jobId,
              }}
              handleCellClick={ handleRowClick }
            />
          ) }
        </div>
      ) }
    </div>
  );
};
