import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Button } from '@material-ui/core';
import { CreateRule } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';

const Toolbar = ({ createHref, createLabel = 'Create' }) => {
  const actionType = {
    string: 'href',
    function: 'onClick',
  };

  const buttonAction = { [actionType[typeof createHref]]: createHref };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <div style={{ flex: 1 }} />
      { createHref && (
        <Button size="small" { ...buttonAction } endIcon={ <CreateRule /> }>
          { createLabel }
        </Button>
      ) }
    </GridToolbarContainer>
  );
};

Toolbar.propTypes = {
  createHref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  createLabel: PropTypes.string,
};

export default Toolbar;
