// import { digitalServicePrefix } from 'components/forms/utils';
import { adminApiSlice } from '../adminApiSlice';
import { map, pick } from 'ramda';

// const filter = ({ initiativeType }) => initiativeType === digitalServicePrefix;

const initiativeApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'polls',
      'experiences',
      'initiative',
      'audiencesForInitiative',
    ],
  })
  .injectEndpoints({
    tagTypes: ['initiative'],
    endpoints(builder) {
      return {
        fetchInitiative: builder.query({
          query: (handle) => (handle ? `/initiatives/${ handle }` : null),
          providesTags: (_result, _error, handle) => [
            { type: 'experiences', id: handle },
            { type: 'polls', id: handle },
          ],
          transformResponse: (response) => response,
        }),
        fetchinitiativeList: builder.query({
          query() {
            return '/initiatives';
          },
          providesTags: (result) => {
            // const filterInits = result.filter(filter);
            // return [...filterInits, { type: 'initiative', id: 'LIST' }];
            return [{ type: 'initiative', id: 'LIST' }];
          },
          transformResponse: (response, _, status) => {
            return map(
              pick([
                'id',
                'name',
                'handle',
                'initiativeType',
                'status',
                'shortHandle',
                'moduleHandle',
                'participantCount',
                'participantLimit',
                'participationStatus',
                'platforms',
                'requiredPpis',
                'organization',
                'attributes',
                'isConsentRequired',
              ]),
              response.initiatives
            );
          },
        }),
        fetchAudiencesForInitiative: builder.query({
          query(handle) {
            return `/initiatives/${ handle }/audiences`;
          },
          providesTags: (result) => {
            return [{ type: 'audiencesForInitiative', id: 'LIST' }];
          },
        }),
      };
    },
    overrideExisting: true,
  });

export const {
  useFetchinitiativeListQuery,
  useFetchInitiativeQuery,
  useFetchAudiencesForInitiativeQuery,
} = initiativeApiSlice;
