import React, { memo, useState, useCallback } from 'react';
import { applyTo, pipe } from 'ramda';
// import PropTypes from 'prop-types';
import { fromGlobalId } from 'graphql-relay';
// aliased
import { TextField, Text } from '@nike/eds';
import { propTypes, defaultProps } from 'lib/react';
import FormContainer from 'components/FormContainer';

// local

export default applyTo(() => {
  const [globalId, setGlobalId] = useState('');

  const handleChange = useCallback(
    event => {
      setGlobalId(event.target.value);
    },
    [setGlobalId]
  );

  const { type, id } = fromGlobalId(globalId);

  return (
    <>
      <Text font="title-2" as="h2">
        Global ID Tools
      </Text>
      <hr />
      <FormContainer>
        <TextField
          // fullWidth={ true }
          onChange={ handleChange }
          value={ globalId }
          label="Global ID"
        />
        <ul>
          <li>TYPE: { type }</li>
          <li>UUID: { id }</li>
        </ul>
      </FormContainer>
    </>
  );
}, pipe(propTypes({}), defaultProps({}), memo));
