/* eslint-disable max-statements */
import { useParams } from 'react-router-dom';
import { userInitiativePermissions } from 'components/RBAC';
import { all, propEq, toUpper } from 'ramda';

export function withActionPermissions(actions, initiativeHandle) {
  const { id } = useParams();
  const entityType = actions.entity || 'initiative';
  const handle = initiativeHandle || id;
  const userPermissions = userInitiativePermissions(handle, entityType);

  const actionsWithPermissions =
    actions &&
    actions.map((item) => {
      const isActionExists = userPermissions.includes(
        item.action && toUpper(item.action)
      );
      return { ...item, disable: !isActionExists, hidden: !isActionExists };
    });

  const noActionsMenuItem = {
    key: '',
    url: '/',
    label: 'No permissible actions',
    action: 'read',
    entity: 'initiative',
    disable: true,
  };

  const isTrue = propEq('hidden', true);

  const actionsResolved = !all(isTrue, actionsWithPermissions)
    ? actionsWithPermissions
    : [
      noActionsMenuItem,
      ...actions
        .map((item) => {
          return { ...item, disable: true, hidden: true };
        })
        .filter((item, idx) => idx !== actions.length - 1),
    ];

  return actionsResolved;
}

export default withActionPermissions;
