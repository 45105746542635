import React from 'react';
import PropTypes from 'prop-types';
import { FullCenter } from './styles';
import { Spinner, Text } from '@nike/eds';

const Loading = ({ text, show = true }) => {
  return (
    show && (
      <FullCenter>
        <div className="center">
          <Spinner size="large" />
          { text && <Text font="body-2">{ text }</Text> }
        </div>
      </FullCenter>
    )
  );
};

Loading.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
};

export default Loading;
