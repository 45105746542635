/* note: this file cannot be named "config" due to an unexplainable NODE_PATH + webpack bug */

// aliased
import packageJson from '../package.json';
import { prop } from 'ramda';

/* eslint-disable no-process-env, no-undef */
const {
  REACT_APP_GOOGLE_ANALYTICS_ID: GOOGLE_ANALYTICS_ID = '',
  REACT_APP_INFRA_NODE_ENV: env = 'production',
  CI = false,
  REACT_APP_API_HOST: API_HOST = '',
  REACT_APP_OKTA_URL: OKTA_URL = '',
  REACT_APP_CONSOLE_OKTA_CLIENT_ID: OKTA_CLIENT_ID = '',
  REACT_APP_CONSOLE_OIDC_CLIENT_ID: OIDC_CLIENT_ID = '',
  REACT_APP_CONSOLE_USER_API_HOST: USER_API_HOST = '',
  REACT_APP_CONSOLE_ADMIN_API_HOST: ADMIN_API_HOST = '',
  REACT_APP_CONSOLE_ADMIN_API_HOST_V2: ADMIN_API_HOST_V2 = '',
} = process.env;

/* eslint-enable no-process-env, no-undef */

const CALLBACK_ROUTE = '/callback';

// required env vars
const envOkay = [API_HOST].every((env) => env);
if (!envOkay) {
  // eslint-disable-next-line no-console
  console.warn(
    'One or more env vars are missing. Please check your .env file in the project root.'
  );
}

const HOSTS = {
  development: 'https://console.platform.test.vl.nikecloud.com/',
  production: 'https://console.platform.vl.nikecloud.com/',
};

const CONFIG = {
  env,
  version: packageJson.version,
  GOOGLE_ANALYTICS_ID,
  API_HOST,
  OKTA_URL,
  OKTA_CLIENT_ID,
  OIDC_CLIENT_ID,
  //Check for override
  USER_API_HOST:
    JSON.parse(sessionStorage.getItem('userApiHost')) ?? USER_API_HOST,
  ADMIN_API_HOST:
    JSON.parse(sessionStorage.getItem('adminApiHost')) ?? ADMIN_API_HOST,
  ADMIN_API_HOST_V2:
    JSON.parse(sessionStorage.getItem('adminApiHostV2')) ?? ADMIN_API_HOST_V2,
  REDIRECT_URI: `${ window.location.origin }${ CALLBACK_ROUTE }`,
  CI,
  DOMAIN: prop(env, HOSTS),
};

export default CONFIG;
