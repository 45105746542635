import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useExitConfirmation = (hasUnsavedChanges) => {
  const history = useHistory();
  const message = 'You have unsaved changes. Are you sure you want to leave?';

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    const unblock = history.block(() => {
      if (hasUnsavedChanges) {
        // eslint-disable-next-line no-alert
        const confirmLeave = window.confirm(message);
        if (!confirmLeave) {
          return false;
        }
      }
      return true;
    });

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [hasUnsavedChanges, history]);
};
