import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
// import PropTypes from 'prop-types';
import { Typography, Container } from '@material-ui/core';

// aliased
import Title from 'components/Title';
import { propTypes, defaultProps } from 'lib/react';

// local
import GlobalId from './components/GlobalId';
import AuthToken from './components/AuthToken';

export default applyTo(() => {
  return (
    <Container>
      <Title title={ 'Dev' } />
      <hr />
      <Typography variant="h2">Dev</Typography>
      <hr />
      <GlobalId />
      <hr />
      <AuthToken />
      <hr />
    </Container>
  );
}, pipe(propTypes({}), defaultProps({}), memo));
