// modules
import React, { useCallback } from 'react';
import { useParams } from 'react-router';

// aliased
import { InitiativeForm } from 'components/forms';
import { ViewMode } from 'lib/enums';
import { useFetchinitiativeListQuery } from 'features/adminApi/endpoints/initiative';
import { FormContainer } from 'components/FormContainer';
import AddEditContents from '../ManageAssets/views/AddEditContent';
import useInitTypeValidation from '../../utils';
import { Text } from '@nike/eds';

export const filter = (data, id) =>
  data?.find((initiative) => initiative.handle === id);

const DetailExperience = () => {
  const { id } = useParams();

  // REDUX API HOOKS ----------------------------------------------------------
  const { resultData, isFetching } = useFetchinitiativeListQuery(undefined, {
    selectFromResult: useCallback(({ data }) => {
      return { resultData: filter(data, id) };
    }, []),
  });
  const initType = resultData?.initiativeType;
  useInitTypeValidation(initType);

  return (
    <FormContainer>
      <Text font={ 'title-1' } as={ 'h1' }>
        Experience Detail { id }
      </Text>
      { !isFetching && resultData && (
        <>
          <InitiativeForm
            disabled={ isFetching }
            viewMode={ ViewMode.READ }
            submitText="Edit"
            initialValues={ resultData }
          />
          <AddEditContents viewMode={ ViewMode.READ } isFetching={ isFetching } />
        </>
      ) }
    </FormContainer>
  );
};

export default DetailExperience;
