import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { applyTo, pipe, mergeDeepRight } from 'ramda';
import { nike as tokens } from '@nike/nike-design-tokens';
import BASE_THEME from './base';

// TODO: this needs much more work...
const DARK_THEME = {
  palette: {
    type: 'dark',
    background: {
      // default: '#111111',
      default: tokens.colorBackgroundAlwaysDark,
    },
    primary: {
      // main: '#fafafa',
      // text: '#ffffff',
      main: tokens.colorContentPrimaryInverse,
      text: tokens.colorContentPrimaryInverse,
    },
    secondary: {
      // main: '#3498DB',
      main: tokens.colorContentSecondaryInverse,
    },
  },

  overrides: {

    MuiAppBar: {
      colorPrimary: {
        // backgroundColor: '#111111',
        backgroundColor: tokens.colorBackgroundAlwaysDark,
      },
    },

  },
};

export default applyTo(DARK_THEME, pipe(
  mergeDeepRight(BASE_THEME),
  createTheme,
  responsiveFontSizes
));
