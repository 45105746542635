import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './FormContainer.module.scss';

export const FieldMargin = ({ children, className, disabled }) => {
  const disabledStyles = disabled ? styles.disabled : '';
  return (
    <div
      className={ cx(
        styles.formContainer,
        disabledStyles,
        styles[className],
        className
      ) }
    >
      { children }
    </div>
  );
};

FieldMargin.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

FieldMargin.defaultProps = {
  children: null,
  className: null,
  disabled: false,
};

export default memo(FieldMargin);
