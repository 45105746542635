import { adminApiSlice } from '../adminApiSlice';
import { omit, pathOr } from 'ramda';

const participationApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['participation', 'cms'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchParticipations: builder.query({
          query(upmid) {
            return `/users/${ upmid }/participations`;
          },

          providesTags: (result = []) => [
            'participation',
            ...result.map(({ id }) => ({ type: 'participation', id })),
          ],
          transformResponse: (response, _, status) =>
            pathOr([], ['inquiries'], response),
        }),
        patchParticipations: builder.mutation({
          query: (body) => {
            return {
              url: `/users/${ body?.upmid }/participations`,
              method: 'PATCH',
              body: omit(['upmid'], body),
            };
          },
          invalidatesTags: (result, error, { id }) => {
            return [{ type: 'participation', id }];
          },
          transformResponse: (response, _, status) => {
            return response;
          },
        }),
      };
    },
  });

export const { useFetchParticipationsQuery, usePatchParticipationsMutation } =
  participationApiSlice;
