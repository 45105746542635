// modules
import React from 'react';
import { Container } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

// aliased

// local
import NotFound from 'components/NotFound';
import List from './components/List';
import Create from './components/Create';
import Edit from './components/Edit';
import View from './components/View';

const Initiative = () => {
  return (
    <Container>
      <Switch>
        <Route path="/:entity/create" exact={ true } component={ Create } />
        <Route path="/:entity" exact={ true } component={ List } />
        <Route path="/:entity/edit/:id" exact={ true } component={ Edit } />
        <Route path="/:entity/:id" exact={ true } component={ View } />
        <Route component={ NotFound } />
      </Switch>
    </Container>
  );
};

export default Initiative;
