import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@nike/eds';
import Link from 'components/Link';
import withActionPermissions from 'lib/hooks/withActionPermissions';
import { actions } from './subnavUtils.js';

const MenuItems = ({ handle, menuOpen, setMenuOpen, initiativeType }) => {
  const history = useHistory();

  const menuItems = actions(initiativeType, handle);

  const handleClickMenuItem = useCallback(
    (url) => {
      setMenuOpen(!menuOpen);
      return history.push(url);
    },
    [menuOpen]
  );

  return (
    <div>
      { withActionPermissions(menuItems, handle).map(
        ({ disable, hidden, label, url }, i) =>
          !hidden && (
            <Link
              disabled={ disable }
              key={ `link_${ i }` }
              onClick={ useCallback(() => handleClickMenuItem(url), [url]) }
            >
              <MenuItem key={ label }>{ label }</MenuItem>
            </Link>
          )
      ) }
    </div>
  );
};

MenuItems.propTypes = {
  handle: PropTypes.string,
  initiativeType: PropTypes.string,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
};

export default MenuItems;
