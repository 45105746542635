// modules
import React, { useMemo } from 'react';

// local
import DataTable from 'components/DataTable';
import { useFetchAudiencesQuery } from 'features/adminApi/endpoints/audiences';
import { shape, string } from 'prop-types';
import { columns, actions } from './tableConfig';

const HomeView = ({ location: { state } }) => {
  const { data = [], isFetching, isLoading } = useFetchAudiencesQuery();
  const initialFilter = useMemo(() => state?.initialFilter);

  return (
    <DataTable
      loading={ isLoading || isFetching }
      heading="Audiences"
      createHref="/audience/create"
      createLabel="Create"
      columns={ columns }
      actions={ actions }
      rows={ data || [] }
      initialFilter={ initialFilter }
    />
  );
};

HomeView.propTypes = {
  location: shape({
    state: shape({
      initialFilter: shape({
        field: string,
        operator: string,
        value: string,
      }),
    }),
  }),
};

export default HomeView;
