/* eslint-disable max-statements */
// modules
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

// aliased
import { DataRequestForm, EmailLookupForm } from 'components/forms';
import { FormContainer } from 'components/FormContainer';
import SimpleDialog from 'components/SimpleDialog';

//
import { useCreateDataRequestsMutation } from 'features/adminApi/endpoints/dataRequests';
import {
  useDeleteUserMutation,
  useLazyFetchUserByEmailQuery,
} from 'features/innovationApi/endpoints/user';
import { Text } from '@nike/eds';

const CreateDeleteRequest = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [autoCreate, setAutoCreate] = useState(false);
  const [showDataRequestForm, setShowDataRequestForm] = useState(false);

  // REDUX API HOOKS ----------------------------------------------------------
  const [fetchUpmId, { data: upmId, error: upmIdError, isLoading }] =
    useLazyFetchUserByEmailQuery();
  const [postDeleteRequest, dataRequestResult] =
    useCreateDataRequestsMutation();
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();

  // MODAL HANDLERS -----------------------------------------------------------
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState();
  const closeDeleteConfirmModal = useCallback(
    () => setOpenDeleteConfirm(false),
    []
  );
  const [deleteUserError, setDeleteUserError] = useState();
  const closeDeleteUserModal = useCallback(() => {
    setDeleteUserError(false);
  }, []);
  const [emailError, setEmailError] = useState();
  const closeEmailErrorModal = useCallback(() => setEmailError(false), []);
  const [dataRequestError, setDataRequestError] = useState();
  const closeDataRequestErrorModal = useCallback(
    () => setDataRequestError(false),
    []
  );

  // API CALLS ----------------------------------------------------------------
  const handleFetchUpmId = useCallback((values) => {
    setAutoCreate(values.autoDelete);
    fetchUpmId(values.email);
  }, []);

  const handleDeleteUser = useCallback(() => {
    closeDeleteConfirmModal();
    deleteUser(upmId);
  }, [upmId]);

  const handleCreateUserDeleteRequest = useCallback(async (values) => {
    postDeleteRequest(values);
  }, []);

  // HANDLE USER LOOKUP ERROR -------------------------------------------------
  useEffect(() => {
    // effect for handling email errors
    if (upmIdError) {
      addToast(upmIdError.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
      setEmailError(upmIdError.data.errorMessage);
    }
  }, [upmIdError]);

  // HANDLE OPENING CONFIRMATION MODAL ----------------------------------------
  useEffect(() => {
    if (upmId && autoCreate) {
      setOpenDeleteConfirm(true);
      setShowDataRequestForm(false);
    } else if (upmId) {
      setShowDataRequestForm(true);
    }
  }, [upmId, autoCreate]);

  // HANDLE SUCCESSFUL DATA REQUEST -------------------------------------------
  useEffect(() => {
    if (dataRequestResult.isSuccess) {
      history.push('/dataRequests', {
        initialFilter: {
          field: 'upmId',
          operator: 'equals',
          value: upmId,
        },
      });
    }
  }, [history, dataRequestResult.isSuccess, upmId]);

  // HANDLE SUCCESSFUL USER DELETE --------------------------------------------
  useEffect(() => {
    if (deleteUserResult.isSuccess) {
      history.push('/dataRequests', {
        initialFilter: {
          field: 'upmId',
          operator: 'equals',
          value: upmId,
        },
      });
    }
  }, [history, deleteUserResult.isSuccess, upmId]);

  // HANDLE DATA REQUEST ERROR ------------------------------------------------
  useEffect(() => {
    if (dataRequestResult.error?.data.errorMessage) {
      setDataRequestError(dataRequestResult.error?.data.errorMessage);
    }
  }, [dataRequestResult.error?.data.errorMessage]);

  // HANDLE USER DELETE ERROR -------------------------------------------------
  useEffect(() => {
    if (deleteUserResult.error?.data.errorMessage) {
      setDeleteUserError(deleteUserResult.error?.data.errorMessage);
    }
  }, [deleteUserResult.error?.data.errorMessage]);

  return (
    <FormContainer>
      <SimpleDialog
        onClose={ closeDeleteConfirmModal }
        onSubmit={ handleDeleteUser }
        open={ openDeleteConfirm }
        cancelLabel="Cancel"
        confirmLabel="Ok"
        title="Are you sure?"
        description="This will delete the user!"
      />
      <SimpleDialog
        onClose={ closeEmailErrorModal }
        onSubmit={ closeEmailErrorModal }
        open={ emailError }
        cancelLabel={ null }
        confirmLabel="Ok"
        title="Invalid Email"
        description={ emailError }
      />
      <SimpleDialog
        onClose={ closeDeleteUserModal }
        onSubmit={ closeDeleteUserModal }
        open={ deleteUserError }
        cancelLabel={ null }
        confirmLabel="Ok"
        title="Error Deleting User"
        description={ deleteUserError }
      />
      <SimpleDialog
        onClose={ closeDataRequestErrorModal }
        onSubmit={ closeDataRequestErrorModal }
        open={ dataRequestError }
        cancelLabel={ null }
        confirmLabel="Ok"
        title="Error Creating the Data Request"
        description={ dataRequestError }
      />
      <Text font={ 'title-1' } as={ 'h1' }>
        Edit Data Request
      </Text>
      { !showDataRequestForm && (
        <EmailLookupForm
          onSubmit={ handleFetchUpmId }
          showAutoDelete={ true }
          disabled={ isLoading }
        />
      ) }
      { showDataRequestForm && (
        <DataRequestForm
          disabled={ dataRequestResult.isLoading }
          onSubmit={ handleCreateUserDeleteRequest }
          initialValues={{ upmId }}
        />
      ) }
    </FormContainer>
  );
};

export default CreateDeleteRequest;
