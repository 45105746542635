import styled from '@emotion/styled';

export const FullCenter = styled.div`
  height: 50vh;
  .center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;
