import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { Text, Toggle, Tooltip } from '@nike/eds';

const autoApproveToggleOnMsg =
  'Athletes will be accepted to the initiatives unless they select an answer that is toggled to reject.';

const autoApproveToggleOffMsg =
  'Athletes will be reviewed and approved to join the initiative manually.';

const autoApproveTooltipBody =
  '⚠️ Once saved, the screener review status cannot be toggled again';

export const AutoApprovedSurvey = ({
  isApproved = false,
  handleIsApproved,
  isCreatePage,
}) => {
  // eslint-disable-next-line react/no-multi-comp
  const ToggleComp = () => {
    return (
      <Toggle
        id="autoApproved"
        label="Auto-accept survey"
        onChange={ handleIsApproved }
        checked={ isApproved }
        disabled={ !isCreatePage }
      />
    );
  };

  return (
    <div className={ styles.autoApproved }>
      { !isCreatePage ? (
        <Tooltip
          bodySlot={ autoApproveTooltipBody }
          placement="right-end"
          enableFocus={ true }
          isDark={ true }
        >
          <ToggleComp />
        </Tooltip>
      ) : (
        <ToggleComp />
      ) }
      <Text as="p" font="body-2">
        { isApproved ? autoApproveToggleOnMsg : autoApproveToggleOffMsg }
      </Text>
    </div>
  );
};

AutoApprovedSurvey.propTypes = {
  handleIsApproved: PropTypes.func,
  isApproved: PropTypes.bool,
  isCreatePage: PropTypes.bool,
};
