/* eslint-disable max-statements */
import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import styles from './index.module.scss';
import AceEditor from 'react-ace';
import Ajv from 'ajv';

const JSONField = ({
  disabled,
  label,
  multiple,
  className,
  onChange,
  value: initialValue,
  name,
  required,
  ...props
}) => {
  const [{ value }, meta, helpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);
  const errorMessage = meta.error;

  const [annotations, setAnnotations] = useState([]);
  const [code, setCode] = useState(JSON.stringify(initialValue, null, 2));

  const ajv = useMemo(() => new Ajv({ strict: false, allErrors: false }), []);
  const validate = ajv.compile({});

  // Sync `code` state with Formik `value` whenever `value` changes
  useEffect(() => {
    if (value !== undefined) {
      setCode(JSON.stringify(value, null, 2));
    }
  }, [value]);

  const tryParseJSONObject = (jsonString, err) => {
    try {
      const obj = JSON.parse(jsonString);
      if (obj && typeof obj === 'object') {
        return obj;
      }
    } catch (error) {
      setAnnotations([]);
      const newAnnotation = [
        {
          row: err.end.row,
          column: err.end.column,
          type: 'error',
          text: error,
        },
      ];
      return error && setAnnotations([...newAnnotation]);
    }
    return false;
  };

  const handleChange = useCallback(
    (response, option) => {
      const parsedJSON = tryParseJSONObject(response, option);
      const valid = validate(response);
      if (!valid) console.log(validate.errors);
      setCode(parsedJSON);

      const { setValue, setTouched } = helpers;
      setTouched(true);
      setValue(parsedJSON);
    },
    [validate, helpers]
  );

  return (
    <FieldWrapper>
      <label className="eds-label eds-type--title-6 ">
        { label }
        { required && (
          <span className={ `asterisk ${ hasError ? 'hasErrors' : '' }` }>*</span>
        ) }
      </label>
      <div className={ styles.aceEditorWrapper }>
        <AceEditor
          defaultValue={ JSON.stringify(value ?? code, null, 2) }
          value={ JSON.stringify(value ?? code, null, 2) }
          placeholder="Insert JSON code"
          mode="json"
          name={ name }
          onChange={ handleChange }
          fontSize={ 14 }
          annotations={ annotations || [] }
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            tabSize: 2,
          }}
          showGutter={ false }
        />
      </div>
      { hasError && <span className="hasErrors"> { errorMessage }</span> }
    </FieldWrapper>
  );
};

JSONField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasErrors: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.shape({}),
};

JSONField.defaultProps = {
  label: '',
};

export default memo(JSONField);
