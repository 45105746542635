import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import { ViewMode, EntityType } from 'lib/enums';
import { useToasts } from 'react-toast-notifications';
import SearchBar from 'views/DevView/SearchBar';
import {
  useFetchConfigFieldsQuery,
  useFetchConfigFieldQuery,
} from 'features/adminApi/endpoints/fields';
import { useParams, useHistory } from 'react-router-dom';
import { map, pick, addIndex } from 'ramda';
import { debounce, isEmpty } from 'lodash';

const columns = [
  {
    field: 'handle',
    headerName: 'Initiative Field Name',
    flex: 1,
    minWidth: 300,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 300,
  },
];

const getRows = (configFields = []) => {
  const addId = (row, index) => ({ ...row, id: index });
  const fields = addIndex(map)(addId, configFields);
  return map(pick(['id', 'handle', 'description']), fields);
};
// eslint-disable-next-line max-statements
const ConfigFieldsListView = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { initiativeType, id } = useParams();
  const {
    data: configFields,
    isLoading,
    isError,
    error,
  } = useFetchConfigFieldsQuery(id);

  const confFields = getRows(configFields?.initiativeFields);
  const [searchValue, setSearchValue] = useState('');
  const [submit, setSubmit] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);

  const actions = [
    {
      key: 'id',
      url: `/initiative/${ initiativeType }/${ id }/fields/`,
      label: 'edit',
      action: ViewMode.EDIT,
      entity: EntityType.INITIATIVE,
    },
  ];

  const {
    data: configField,
    isFetching,
    isError: isConfigFieldError,
  } = useFetchConfigFieldQuery(
    { initiativeHandle: id, fieldHandle: searchValue },
    {
      skip: !submit || searchValue === '',
    }
  );

  // eslint-disable-next-line max-statements
  const debouncedEffect = debounce(() => {
    if (searchValue.trim() === '') {
      const fields = [...confFields];

      if (!isEmpty(configField)) {
        fields.unshift(configField);
        setFilteredRows(fields);
        return;
      }
      setFilteredRows(confFields);
    } else {
      const filteredRow = confFields.filter(
        (row) => row.handle.toLowerCase() === searchValue.toLowerCase()
      );
      if (filteredRow.length) setFilteredRows(filteredRow);
      if (filteredRow.length === 0 && submit && !isFetching) {
        setSubmit((prevState) => !prevState);
        if (!isConfigFieldError) {
          if (!isEmpty(configField)) {
            setFilteredRows(getRows([configField]));
          }
        } else {
          addToast('Config Field is not found', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    }
  }, 1000);

  useEffect(() => {
    debouncedEffect();
    return () => {
      debouncedEffect.cancel();
    };
  }, [
    configFields,
    searchValue,
    submit,
    configField,
    isConfigFieldError,
    isFetching,
  ]);

  useEffect(() => {
    if (isError && !isLoading) {
      addToast(`${ error?.error }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError]);

  const handleRowClick = useCallback(
    ({ colDef: { field }, row: { handle } }) => {
      if (field !== 'actions' && handle) {
        history.push(`/initiative/${ initiativeType }/${ id }/fields/${ handle }`);
      }
    },
    [history]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleSearchSubmit = useCallback(() => {
    setSubmit((prevState) => !prevState);
  }, [searchValue]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(delay);
  }, []);

  return (
    <div data-testid="configuration-fields-container">
      { isLoading ? (
        <Loading text="fetching configuration-fields" />
      ) : (
        <div>
          <SearchBar
            searchValue={ searchValue }
            handleSearchChange={ handleSearchChange }
            handleSearchSubmit={ handleSearchSubmit }
            disabled={ !searchValue.length }
            isFetching={ isFetching }
          />
          <DataTable
            rows={ filteredRows.length > 0 ? filteredRows : [] }
            columns={ columns }
            actions={ actions }
            heading={ `Configuration Fields for initiative: ${ id }` }
            loading={ loading && !(filteredRows.length > 0) }
            handleCellClick={ handleRowClick }
            createHref={ `/initiative/${ initiativeType }/${ id }/fields/create` }
            createLabel="add"
          />
        </div>
      ) }
    </div>
  );
};

export default ConfigFieldsListView;
