import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const RadioLabel = ({ label, value, handleMouseEnter }) => (
  <div
    // eslint-disable-next-line
    onMouseEnter={useCallback(() => handleMouseEnter(value))}
  >
    { label }
  </div>
);

RadioLabel.propTypes = {
  handleMouseEnter: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
};

export default RadioLabel;
