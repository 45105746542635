/* eslint-disable max-statements */
import { useFetchContentQuery } from 'features/adminApi/endpoints/content.js';
import { useFetchConsentQuery } from 'features/adminApi/endpoints/consent';
import { useFetchAudiencesForInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import { isEqual, filter, matchesProperty, reject } from 'lodash';
import { useEffect, useState } from 'react';
import { CARD_CONSTANTS, DEFAULT_MEDIA } from '../layoutUtils';
import { ContentStatus } from 'lib/enums';
import { InitiativeTypesEnum } from 'utils';
import { useFetchInitiativeAudiencesQuery } from 'features/adminApi/endpoints/initiativeAudiences';

const splitArray = (array = [], filterCallback) => {
  const match = filter(array, filterCallback);
  const notMatch = reject(array, filterCallback);
  return array.length ? [match, notMatch] : [];
};

//TODO: admin is able to change status to active then reverse some conditions.
//Add useActiveConditions to submit button or formik check?

const useActiveConditions = (values) => {
  const { handle, status, isConsentRequired, platforms, initiativeType } =
    values;
  const initiativeHandle = handle;
  const [consentRequired, setConsentRequired] = useState(isConsentRequired);
  const [platformChosen, setPlatformChosen] = useState();
  const [criteria, setCriteria] = useState([]);
  const { data: audienceData } =
    useFetchAudiencesForInitiativeQuery(initiativeHandle);
  const { data: initiativeAudiencesData = [] } =
    useFetchInitiativeAudiencesQuery(initiativeHandle);
  const { data: contentData, isLoading: isContentLoading } =
    useFetchContentQuery(initiativeHandle);
  const { data: consentData, isLoading: isConsentLoading } =
    useFetchConsentQuery(initiativeHandle);
  const isCoreInit = !!(initiativeType === InitiativeTypesEnum.COR);

  useEffect(() => {
    setConsentRequired(isConsentRequired);
  }, [isConsentRequired]);

  useEffect(() => {
    const platform = platforms && platforms.length > 0;
    setPlatformChosen(platform);
  }, [platforms]);

  //eslint-disable-next-line complexity
  useEffect(() => {
    const splitByDefault =
      !isContentLoading &&
      splitArray(contentData, matchesProperty('contentType', DEFAULT_MEDIA));

    const [defaultMedia, contentCards] = splitByDefault || [];

    const uniqueContentTypes = contentCards && [
      ...new Set(contentCards.map((item) => item.contentType)),
    ];

    const hasAllCards =
      uniqueContentTypes &&
      isEqual(uniqueContentTypes.sort(), CARD_CONSTANTS.sort());

    const consentDocumentPresent = consentRequired
      ? !isConsentLoading &&
        consentData &&
        consentData.some((form) => form.status === ContentStatus.PUBLISHED)
      : true;

    const hasAudience =
      audienceData?.audiences?.length || initiativeAudiencesData?.length;

    const conditions = [
      {
        criteria:
          'Audience should be set or Initiative Audience should be created',
        pass: isCoreInit || !!hasAudience,
      },
      {
        criteria: 'Default media in CMS should be created',
        // Don't show error until loading finishes
        pass: isCoreInit || !!defaultMedia?.length,
      },
      {
        criteria: 'All cards in CMS should be published',
        pass: isCoreInit || !!hasAllCards,
      },
      {
        criteria: 'Consent form should be published',
        pass: !!consentDocumentPresent,
      },
      {
        criteria: 'A platform should be selected',
        pass: !!platformChosen,
      },
    ];

    const failedCriteria = filter(conditions, ['pass', false]);

    setCriteria(failedCriteria);
  }, [
    audienceData,
    contentData,
    consentData,
    isContentLoading,
    isConsentLoading,
    consentRequired,
    platformChosen,
  ]);

  // const showCriteria = status !== 'active' ? criteria.length : false;
  // return criteria.length > 0 ? criteria : [];

  return status !== 'active' ? criteria : [];

  // return [
  //   criteria,
  //   children => {
  //     return criteria.length && status === 'inactive' ? (
  //       <Tooltip
  //         size="medium"
  //         placement="bottom"
  //         title="Cannot set status to active:"
  //         body={
  //           <>
  //             { criteria?.map(({ criteria }, i) => (
  //               <div key={ i }> ⚠️ { criteria }</div>
  //             )) }
  //           </>
  //         }
  //       >
  //         <div>{ children }</div>
  //       </Tooltip>
  //     ) : (
  //       <>{ children }</>
  //     );
  //   },
  // ];
};

export default useActiveConditions;
