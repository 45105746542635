/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { useFetchAudiencesQuery } from 'features/adminApi/endpoints/audiences';
import { useFetchInitiativeAudiencesQuery } from 'features/adminApi/endpoints/initiativeAudiences';
import AudienceToggle from './AudienceToggle';
import styles from './index.module.scss';
import { AudienceTypesEnum } from 'utils';
import { ViewMode } from 'lib/enums';
import { Button, Spinner, Text } from '@nike/eds';
import { isEmptyArray } from 'formik';

const AudienceInitiatives = ({
  initiativeHandle,
  onComplete,
  isEditAllowed,
  isCreateAudienceAllowed,
  audiencesType,
  viewMode,
}) => {
  const { data: audiences = [], isError, isLoading } = useFetchAudiencesQuery();
  const {
    data: initiativeAudiences = [],
    isError: isInitiativeAudiencesError,
    isLoading: isInitiativeAudiencesLoading,
  } = useFetchInitiativeAudiencesQuery(initiativeHandle);

  const isInitiativeAudiencePage =
    audiencesType === AudienceTypesEnum.INIT_AUDIENCE;

  const filteredAudiences = isInitiativeAudiencePage
    ? initiativeAudiences
    : audiences;

  const titleText = isCreateAudienceAllowed
    ? `Create${
      isInitiativeAudiencePage ? ' Initiative' : ''
    } Audiences to view here.`
    : `You don't have permission to create${
      isInitiativeAudiencePage ? ' Initiative' : ''
    } Audiences.`;

  return (
    <div>
      <div
        className={
          isEmptyArray(filteredAudiences)
            ? styles.textContainer
            : styles.toggleFieldsContainer
        }
      >
        { isLoading || isInitiativeAudiencesLoading ? (
          <Spinner size="large" />
        ) : isEmptyArray(filteredAudiences) ? (
          <Text font="title-3" as="h3">
            { titleText }
          </Text>
        ) : (
          filteredAudiences.map(
            ({ name, handle, audienceHandle, status }, index) => (
              <div key={ index }>
                <AudienceToggle
                  name={ name }
                  audienceHandle={ handle || audienceHandle }
                  initiativeHandle={ initiativeHandle }
                  isEditAllowed={ isEditAllowed }
                  audiencesType={ audiencesType }
                  status={ status }
                />
              </div>
            )
          )
        ) }
      </div>
      { viewMode === ViewMode.CREATE && (
        <div className={ styles.btns }>
          <Button
            appearance="primary"
            disabled={
              isError ||
              isLoading ||
              isInitiativeAudiencesError ||
              isInitiativeAudiencesLoading ||
              !isCreateAudienceAllowed
            }
            type="button"
            onClick={ onComplete }
          >
            Submit
          </Button>
        </div>
      ) }
    </div>
  );
};

AudienceInitiatives.propTypes = {
  audiencesType: PropTypes.string,
  initiativeHandle: PropTypes.string.isRequired,
  isCreateAudienceAllowed: PropTypes.bool,
  isEditAllowed: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  viewMode: PropTypes.string,
};

export default AudienceInitiatives;
