/* eslint-disable camelcase */
import config from 'conf';

const oidcConfig = () => {
  // TODO: make this env aware
  const clientId = config.OIDC_CLIENT_ID;

  const origin = window?.location?.origin;
  const redirectUrl = `${ origin }/oidc/authorize_code/v1`;
  const logoutUrl = `${ origin }/oidc/logout`;
  const issuer = config.env === 'production' ? 'https://accounts.nike.com' : 'https://accounts.nikedev.com';

  return {
    authority: 'https://accounts.nikedev.com',
    client_id: clientId,
    //scope: 'nike.digital openid profile email phone flow offline_access', // TODO: enable once ready
    scope: 'nike.digital openid profile email phone flow',
    response_type: 'code',
    redirect_uri: redirectUrl,
    post_logout_redirect_uri: logoutUrl,
    metadata: {
      issuer,
      authorization_endpoint: `${ issuer }/authorize/v1`,
      token_endpoint: `${ issuer }/token/v1`,
      userinfo_endpoint: `${ issuer }/user_info/v1`,
      end_session_endpoint: `${ issuer }/rp_logout/v1`,
      jwks_uri: `${ issuer }/discovery/keys`,
      check_session_iframe: `${ issuer }/checksessioniframe`,
    },
    signingKeys: [
      {
        kty: 'RSA',
        e: 'AQAB',
        use: 'sig',
        kid: 'a92a454a-1e3d-475a-a4ec-f94c6cca0c41sig',
        alg: 'RS256',
        n:
          'teGjODCwiAHwehdHU9abNW3ugpOCjkQqMCOoUVkksfVDedeCvtriTV-LJiOLZ0PZflWbzjXibw3yd8d0H9IqdGvCj4OL27F568fw9JcetrzgdmnltThzFbTZdpBxnsf15KaHfXnV3rOkELISUESa_PO_df0kj5GcuDLdeRhBQ7LMM_rMtTFgHrcvvCuxTq6zaDN1LgU76VRQpcUEXwyEzEZ0BlOO6lJ2sJ2JBoOTouhi9kIv-_jz_PcyDQjobLQdWbw0xVKaoPzJhoTO5HdQsv12jV0jTyFfu0kKKBw_TEf055ULCp2VPA0KpG0-8HnmxzML4n48d0RfATl5ggKP0Q',
      },
      {
        kty: 'RSA',
        e: 'AQAB',
        use: 'sig',
        kid: 'd9ec3d90-dcb6-4c91-901c-882bb8449a85sig',
        alg: 'RS256',
        n:
          'k74YO75r5UVx88p68zj3hpIRZBK142klnD6q4m-NVz_ypJuSx2Zc4pZB9Y0TG-7HXN6zz-igpXztxWBIaLfj6aaEtyT0TT53ubiCnnZqaqd9BslExhwbnv1oL2r41kg7a_EcwlXGQv67MvMJGmG80LnjaA3qg4ZGnQTi8cQntAiwqscJFlXYnAXuN2uSVy-RlrG8NLlUoibPQcj-nox-8KCKs8ipkFYqQC8b634EpB747KsgRk4YFqpGUlV2Xc35fJQFQRORPc032jLHcrPi2rzL6lAIq_5Z2hHa_Yp97r050ZwR-HQga23R-dNeOEyHrKEH58uG7sGS4U5ulPML6Q',
      },
    ],
    loadUserInfo: false,
    monitorSession: true,
    silentRequestTimeout: 2000,
  };
};

export default oidcConfig;
