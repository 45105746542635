import React from 'react';
import { shape } from 'prop-types';
import { Skeleton } from '@nike/eds';

const FormSkeleton = ({ fields }) => {
  const fieldArray = Object.keys(fields);
  return fieldArray.map((item) => {
    return (
      <div key={ item }>
        <Skeleton height="50px" width="20%" style={{ marginBottom: 10 }} />
        <Skeleton height="70px" width="100%" style={{ marginBottom: 10 }} />
      </div>
    );
  });
};

FormSkeleton.propTypes = {
  fields: shape({}),
};

export default FormSkeleton;
