/* eslint-disable max-statements */
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { userInitiativePermissions } from 'components/RBAC';
import { getEntitySubtype } from 'lib/auth.js';
import NoAccess from 'components/NoAccess';

export function withEntityPermissions(Component, accessRequested) {
  const ProtectedRoute = (props) => {
    const { id, type, entity } = useParams();

    const location = useLocation();
    const locationPath = location.pathname.split('/')[1];

    const entityType = type
      ? getEntitySubtype(type)
      : entity
        ? entity
        : locationPath;

    const userAccessForThisEntity = userInitiativePermissions(id, entityType);
    if (!userAccessForThisEntity.includes(accessRequested?.toUpperCase())) {
      return <NoAccess />;
    }

    return <Component { ...props } />;
  };

  return ProtectedRoute;
}

export default withEntityPermissions;
