/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  useCreateAudienceMutation,
  useCreateAudienceMemberMutation,
  useCreateAudienceMemberWithAthleteIdMutation,
} from 'features/adminApi/endpoints/audiences';
import { ViewMode } from 'lib/enums';
import AudienceFormWrapper from '../AudienceFormWrapper';
import { modifyDataBasedOnPreference } from 'views/Initiative/Experiences/utils';
import { isArray, isBoolean } from 'lodash';
import { AudienceTypesEnum } from 'utils';
import {
  useCreateInitAudienceMemberWithAthleteIdMutation,
  useCreateInitiativeAudienceMemberMutation,
  useCreateInitiativeAudienceMutation,
} from 'features/adminApi/endpoints/initiativeAudiences';
import { filter, propEq } from 'ramda';

const CreateAudience = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [postCreateAudience, createAudienceResult] =
    useCreateAudienceMutation();
  const [postCreateInitiativeAudience, createInitiativeAudienceResult] =
    useCreateInitiativeAudienceMutation();

  const location = useLocation();
  const {
    audienceType,
    initiativeHandle,
    viewMode = '',
    returnUrl = '',
    initiativeType = '',
    fromTabAudiences = false,
  } = location.state || {};

  const isCreateAudienceMode = viewMode === ViewMode.CREATE;

  const [createAudienceMember] = useCreateAudienceMemberMutation();
  const [createAudienceMemberWithAthleteId] =
    useCreateAudienceMemberWithAthleteIdMutation();

  const [createInitiativeAudienceMember] =
    useCreateInitiativeAudienceMemberMutation();
  const [createInitiativeAudienceMemberWithAthleteId] =
    useCreateInitAudienceMemberWithAthleteIdMutation();

  const [audienceMemberResult, setAudienceMemberResult] = useState([]);
  const [audienceHandle, setAudienceHandle] = useState('');
  const [createAudienceType, setCreateAudienceType] = useState('');
  const [successFalseMembers, setSuccessFalseMembers] = useState(0);
  const [successTrueMembers, setSuccessTrueMembers] = useState(0);
  const [shouldAddAudienceMembers, setShouldAddAudienceMembers] =
    useState(null);
  const [isSuccessToastShown, setIsSuccessToastShown] = useState(false);

  const isInitiativeAudiencePage =
    initiativeHandle && audienceType === AudienceTypesEnum.INIT_AUDIENCE;

  const isValidArray = (array) =>
    isArray(array) &&
    array.length > 0 &&
    array.every((uuid) => uuid.trim() !== '');

  const addUserToAudience = useCallback(
    async (values) => {
      const handleAudienceMembersById = async (members, idKey) => {
        const results = await Promise.allSettled(
          members.map(async (id) => {
            const { handle } = values;
            const data = { [idKey]: id, handle };
            const initiativeAudienceData =
              audienceType === AudienceTypesEnum.INIT_AUDIENCE
                ? {
                  [idKey]: id,
                  audienceHandle: handle,
                  initiativeHandle,
                }
                : null;

            try {
              const result = await (idKey === 'upmId'
                ? audienceType === AudienceTypesEnum.INIT_AUDIENCE
                  ? createInitiativeAudienceMember(initiativeAudienceData)
                  : createAudienceMember(data)
                : audienceType === AudienceTypesEnum.INIT_AUDIENCE
                  ? createInitiativeAudienceMemberWithAthleteId(
                    initiativeAudienceData
                  )
                  : createAudienceMemberWithAthleteId(data));

              if (result.data?.handle === handle) {
                //Remove users added or already part of audience from input field
                setAudienceMemberResult((prevResult) => [...prevResult, id]);
                return { id, success: true };
              }
              return { id, success: false };
            } catch (error) {
              return { id, success: false };
            }
          })
        );

        const audienceMemberResults = results.map((result) => {
          if (result.status === 'fulfilled') {
            return result.value;
          }
          return result.reason;
        });

        const successTrue = filter(
          propEq('success', true),
          audienceMemberResults
        );
        const successFalse = filter(
          propEq('success', false),
          audienceMemberResults
        );

        setSuccessFalseMembers(successFalse);
        setSuccessTrueMembers(successTrue);

        audienceMemberResults.forEach(({ id, success }) => {
          const chipElement = document.getElementById(`chip-${ id }`);
          if (chipElement) {
            const spanElement = chipElement.querySelector('span');
            if (spanElement) {
              spanElement.style.color = success
                ? 'var(--eds-color-green-50)'
                : 'var(--eds-color-brand-red)';
            }
          }
        });
      };

      if (isValidArray(values.upmids)) {
        await handleAudienceMembersById(values.upmids, 'upmId');
      } else if (isValidArray(values.athleteids)) {
        await handleAudienceMembersById(values.athleteids, 'athleteId');
      }
    },
    [
      createAudienceMember,
      createAudienceMemberWithAthleteId,
      createInitiativeAudienceMember,
      createInitiativeAudienceMemberWithAthleteId,
      setAudienceMemberResult,
    ]
  );

  const handleSubmit = useCallback(async (data) => {
    const removeKey = (key, { [key]: _, ...rest }) => rest;
    const modifiedData = modifyDataBasedOnPreference({
      status: 'active',
      ...data,
    });
    if (audienceType === AudienceTypesEnum.INIT_AUDIENCE) {
      let initiativeAudienceData = {
        ...modifiedData,
        initiativeHandle,
        audienceHandle: modifiedData.handle,
      };
      initiativeAudienceData = {
        ...removeKey('handle', initiativeAudienceData),
      };

      await postCreateInitiativeAudience(
        removeKey(
          data.addIdsPreference === 'upmid' ? 'upmids' : 'athleteids',
          initiativeAudienceData
        )
      );
    } else {
      await postCreateAudience(
        removeKey(
          data.addIdsPreference === 'upmid' ? 'upmids' : 'athleteids',
          modifiedData
        )
      );
    }
    await addUserToAudience(modifiedData);
    setAudienceHandle(data.handle || data.audienceHandle);
    setCreateAudienceType(data.audienceType);
    setShouldAddAudienceMembers(data?.shouldAddAudienceMembers);
  }, []);

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const showToast = (message, appearance) => {
    addToast(message, {
      appearance,
      autoDismiss: true,
    });
  };

  const handleNavigation = () => {
    if (isInitiativeAudiencePage) {
      if (isCreateAudienceMode) {
        history.replace({
          pathname: `/initiative/${ initiativeType }/create/${ initiativeHandle }/audiences`,
          state: { returnUrl },
        });
      } else if (fromTabAudiences) {
        history.goBack();
      } else {
        history.replace({
          pathname: `/initiative/${ initiativeType }/${ viewMode }/${ initiativeHandle }/audiences`,
          state: { returnUrl },
        });
      }
    } else if (fromTabAudiences) {
      history.goBack();
    } else {
      // eslint-disable-next-line
      successFalseMembers.length > 0
        ? history.push({
          pathname: `/audience/edit/${ audienceHandle }`,
          state: {
            audienceType,
            initiativeHandle,
          },
        })
        : history.push('/audience');
    }
  };

  const handleCreateAudienceStatus = () => {
    const isSuccess =
      createAudienceResult.isSuccess ||
      createInitiativeAudienceResult.isSuccess;
    const isAudienceCreateError =
      createAudienceResult.isError || createInitiativeAudienceResult.isError;
    if (createAudienceType !== 'Dynamic') {
      if (isSuccess && !isSuccessToastShown) {
        showToast('Audience created successfully', 'success');
        setIsSuccessToastShown(true);
      } else if (isAudienceCreateError) {
        showToast(
          isInitiativeAudiencePage
            ? createInitiativeAudienceResult.error?.data.errorMessage
            : createAudienceResult.error?.data.errorMessage,
          'error'
        );
      }
      if (isBoolean(shouldAddAudienceMembers) && !shouldAddAudienceMembers) {
        handleNavigation();
      }
    }
  };

  useEffect(() => {
    handleCreateAudienceStatus();
  }, [
    createAudienceResult,
    createInitiativeAudienceResult,
    shouldAddAudienceMembers,
    isInitiativeAudiencePage,
    fromTabAudiences,
    isCreateAudienceMode,
    initiativeType,
    initiativeHandle,
    viewMode,
    returnUrl,
    createAudienceType,
  ]);

  //Create Audience and AudienceMembers with upmId and athleteId success & error handling
  useEffect(() => {
    const successMessage =
      'Member(s) have been successfully added to the audience';
    const errorMessage =
      'One or more members(s) cannot be added due to an error. Please try adding again';

    if (successTrueMembers.length > 0) {
      showToast(successMessage, 'success');
    }
    if (successFalseMembers.length > 0) {
      showToast(errorMessage, 'error');
    }
    if (successTrueMembers.length > 0 || successFalseMembers.length > 0) {
      handleNavigation();
    }
  }, [
    successFalseMembers,
    successTrueMembers,
    isInitiativeAudiencePage,
    isCreateAudienceMode,
    fromTabAudiences,
    initiativeType,
    audienceHandle,
    initiativeHandle,
    audienceType,
    viewMode,
  ]);

  useEffect(() => {
    if (createAudienceResult.isSuccess && createAudienceType === 'Dynamic') {
      showToast('Audience created successfully', 'success');
      if (fromTabAudiences) {
        history.goBack();
      } else history.push('/audience');
    }
  }, [createAudienceResult.isSuccess, createAudienceType]);

  return (
    <AudienceFormWrapper
      isLoading={ false }
      viewMode={ ViewMode.CREATE }
      onSubmit={ handleSubmit }
      onCancel={ handleCancel }
      disabled={ false }
      onTagAdd={ audienceMemberResult }
      audienceType={ audienceType }
    />
  );
};

export default CreateAudience;
