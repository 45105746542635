import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { applyTo, pipe, mergeDeepRight } from 'ramda';
import { nike as tokens } from '@nike/nike-design-tokens';
import BASE_THEME from './base';

const LIGHT_THEME = {
  palette: {
    type: 'light',
    background: {
      // default: '#ffffff',
      default: tokens.colorBackgroundPrimary,
    },
    primary: {
      // main: '#111111',
      // text: '#111111',
      main: tokens.colorContentPrimary,
      text: tokens.colorContentPrimary,
    },
    secondary: {
      // main: '#3498DB',
      main: tokens.colorContentSecondary,
    },
  },

  overrides: {
    
    MuiAppBar: {
      colorPrimary: {
        // backgroundColor: '#ffffff',
        backgroundColor: tokens.colorBackgroundPrimary,
      },
    },
  },
};

export default applyTo(LIGHT_THEME, pipe(
  mergeDeepRight(BASE_THEME),
  createTheme,
  responsiveFontSizes
));
