/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useCallback } from 'react';
import { convertHandleToTitle } from 'components/forms/Assets/lib';
import Loading from 'components/Loading';
import {
  ASSET_CONSTANTS,
  DEFAULT_MEDIA,
  getAssetType,
  SURVEY_FORM,
} from 'lib/layoutUtils';
import { AssetCard, AssetGrid } from '../../components';
import styles from '../../styles.module.scss';
import { Button, Text, TextGroup } from '@nike/eds';
import CreateOptions from '../../components/CreateOptions';
import { isEmpty, kebabCase } from 'lodash';
import { userInitiativePermissions } from 'components/RBAC';
import { getEntitySubtype } from 'lib/auth.js';
import { useHistory, useParams } from 'react-router-dom';

export function groupByAssetType(
  data = [],
  assetType,
  initiativeHandle,
  { isLoading, contentType }
) {
  const { initiativeType, id } = useParams();
  const history = useHistory();
  const isContentAsset = assetType === 'content';
  const entityType = getEntitySubtype(kebabCase(assetType));
  const canAdd = userInitiativePermissions(id, entityType).includes('CREATE');

  const dataByAssetType = data
    .filter((itm) => ASSET_CONSTANTS.includes(itm.contentType))
    .filter((itm) => getAssetType(itm.contentType) === assetType)
    .map((item) => [item.contentType, item]);

  const dataByType =
    assetType === SURVEY_FORM
      ? [...new Set(dataByAssetType.map((survey) => survey[1].handle))]
        .map((value) =>
          dataByAssetType.find((asset) => asset[1].handle === value)
        )
        .map((survey) => survey[1])
      : dataByAssetType.length
        ? [...new Map(dataByAssetType).values()]
        : [];

  const checkIfDefaultMediaExists =
    data.filter((itm) => itm.contentType === DEFAULT_MEDIA).length > 0;
  const allCardsPresent = isContentAsset && dataByType.length === 5;

  const handleClick = useCallback(() => {
    history.push({
      state: { editDefaultMedia: true },
      pathname: `/initiative/${ initiativeType }/edit/${ id }`,
    });
  }, [initiativeType, id]);

  const isDefaultMediaContentEmpty =
    isLoading &&
    isContentAsset &&
    isEmpty(dataByType) &&
    contentType === DEFAULT_MEDIA;

  return isLoading || isDefaultMediaContentEmpty ? (
    <Loading text="Fetching Assets" />
  ) : !isEmpty(dataByType) ? (
    <>
      <AssetGrid count={ dataByType?.length || 1 }>
        { dataByType.map((item, idx) => {
          return (
            <div key={ `${ item.handle }-${ idx }` }>
              <AssetCard
                data-testid="asset-card"
                type={ item.contentType }
                handle={ initiativeHandle }
                title={
                  item.contentType === SURVEY_FORM
                    ? convertHandleToTitle(item?.handle)
                    : convertHandleToTitle(item.contentType)
                }
                versions={
                  item.contentType === SURVEY_FORM
                    ? data.filter((itm) => itm.handle === item.handle)
                    : data.filter((itm) => itm.contentType === item.contentType)
                }
              />
            </div>
          );
        }) }
      </AssetGrid>
      { canAdd &&
        isContentAsset &&
        (!checkIfDefaultMediaExists ? (
          <CreateOptions assetType={ assetType } />
        ) : (
          !allCardsPresent && (
            <>
              <Text font="title-4" className="eds-spacing--mb-24">
                Edit the Default Media on the Initiative Edit Page to generate
                the remaining cards.
              </Text>
              <Button onClick={ handleClick }>Edit Default Media</Button>
            </>
          )
        )) }
      { assetType === SURVEY_FORM && canAdd && (
        <CreateOptions assetType={ assetType } />
      ) }
    </>
  ) : (
    <div className={ styles.noContent }>
      <TextGroup density="low">
        <Text font="title-4">No items found.</Text>
      </TextGroup>
      { canAdd && <CreateOptions assetType={ assetType } /> }
    </div>
  );
}
