import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InputTextStyle = styled.input`
  width: 100%;
  padding: 8px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  :focus-visible {
    outline: none;
    border-radius: 4px;
    border: 1.5px solid #999;
  }
`;

const fontStyle = {
  fontFamily: 'Helvetica Neue',
  fontSize: '16px',
  lineHeight: '18px',
};

export const DeleteButton = styled.button`
  background-color: gray;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  ${ fontStyle };
  :hover {
    background-color: black;
  }
`;

export const CancelButtonStyle = {
  color: 'blue',
  textDecoration: 'underline',
  paddingLeft: '16px',
  ...fontStyle,
};

export const ErrorText = styled.div`
  color: red;
  ${ fontStyle };
  font-size: 14px;
  font-weight: bold;
  margin-top: 12px;
`;

export const headerTextStyle = css`
  ${ fontStyle };
  font-size: 18px;
  font-weight: bold;
`;

export const modalBottomViewContainerStyle = { paddingTop: '24px' };

export const modalContainerStyle = css`
  .eds-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 440px !important;
    height: 275px !important;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 14px;
  }
  .eds-modal__header {
    ${ headerTextStyle };
  }
  .eds-button {
    display: none;
  }
`;
