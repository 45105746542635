import React from 'react';

const AppIcon = () => {
  return (
    <svg
      data-testid="app-icon-svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4272 11.3235C11.4592 11.5928 10.6796 11.5592 10.2349 11.2179C9.09615 10.3221 9.96577 8.53481 10.274 8C9.7186 8.65149 9.1649 9.31117 8.73242 10.0201C8.05377 11.1372 7.76272 12.3605 8.22404 13.1472C9.04923 14.5667 11.0254 13.9117 12.2875 13.3404L24 8.05177L12.4272 11.3235Z"
        fill="#111111"
      />
      <path
        d="M12.7642 28.576H14.8922L19.2922 40H17.1482L16.0762 36.976H11.5162L10.4442 40H8.38019L12.7642 28.576ZM12.0602 35.456H15.5482L13.8362 30.544H13.7882L12.0602 35.456ZM20.3801 28.576H25.4201C26.2307 28.576 26.8921 28.6933 27.4041 28.928C27.9161 29.1627 28.3107 29.456 28.5881 29.808C28.8654 30.16 29.0521 30.544 29.1481 30.96C29.2547 31.376 29.3081 31.76 29.3081 32.112C29.3081 32.464 29.2547 32.848 29.1481 33.264C29.0521 33.6693 28.8654 34.048 28.5881 34.4C28.3107 34.752 27.9161 35.0453 27.4041 35.28C26.8921 35.504 26.2307 35.616 25.4201 35.616H22.3801V40H20.3801V28.576ZM22.3801 33.984H25.3081C25.5321 33.984 25.7614 33.952 25.9961 33.888C26.2307 33.824 26.4441 33.7227 26.6361 33.584C26.8387 33.4347 26.9987 33.2427 27.1161 33.008C27.2441 32.7627 27.3081 32.4587 27.3081 32.096C27.3081 31.7227 27.2547 31.4133 27.1481 31.168C27.0414 30.9227 26.8974 30.7307 26.7161 30.592C26.5347 30.4427 26.3267 30.3413 26.0921 30.288C25.8574 30.2347 25.6067 30.208 25.3401 30.208H22.3801V33.984ZM31.0519 28.576H36.0919C36.9026 28.576 37.5639 28.6933 38.0759 28.928C38.5879 29.1627 38.9826 29.456 39.2599 29.808C39.5373 30.16 39.7239 30.544 39.8199 30.96C39.9266 31.376 39.9799 31.76 39.9799 32.112C39.9799 32.464 39.9266 32.848 39.8199 33.264C39.7239 33.6693 39.5373 34.048 39.2599 34.4C38.9826 34.752 38.5879 35.0453 38.0759 35.28C37.5639 35.504 36.9026 35.616 36.0919 35.616H33.0519V40H31.0519V28.576ZM33.0519 33.984H35.9799C36.2039 33.984 36.4333 33.952 36.6679 33.888C36.9026 33.824 37.1159 33.7227 37.3079 33.584C37.5106 33.4347 37.6706 33.2427 37.7879 33.008C37.9159 32.7627 37.9799 32.4587 37.9799 32.096C37.9799 31.7227 37.9266 31.4133 37.8199 31.168C37.7133 30.9227 37.5693 30.7307 37.3879 30.592C37.2066 30.4427 36.9986 30.3413 36.7639 30.288C36.5293 30.2347 36.2786 30.208 36.0119 30.208H33.0519V33.984Z"
        fill="#111111"
      />
    </svg>
  );
};

export default AppIcon;
