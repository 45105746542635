// modules
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import config from 'conf';

const oktaAuth = new OktaAuth({
  issuer: config.OKTA_URL,
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: config.REDIRECT_URI,
  pkce: true,
});


// display preformatted code
export default applyTo(({ children }) => {
  const history = useHistory();

  const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }, []);

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
      { children }
    </Security>
  );
},
pipe(
  propTypes({
    children: PropTypes.node,
  }),
  defaultProps({
    children: null,
  }),
  memo
)
);
