// modules
import React, { memo } from 'react';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import FlexCenter from 'components/FlexCenter';

export default applyTo(({ hidden, className, style }) => {
  if (hidden) return null;
  return (
    <FlexCenter style={ style } className={ className }>
      <CircularProgress color='secondary' />
    </FlexCenter>
  );
}, pipe(
  propTypes({
    className: PropTypes.string,
  }),
  defaultProps({}),
  memo,
));
