export const ViewMode = {
  CREATE: 'create',
  EDIT: 'edit',
  READ: 'read',
  VIEW: 'view',
  DELETE: 'delete',
  PREVIEW: 'preview',
};

// These are based on current NCSS
export const BreakPointEnum = {
  breakpointXs: '320px',
  breakpointS: '600px',
  breakpointM: '960px', // 1080 in Figma
  breakpointL: '1440px',
  breakpointXl: '1920px',
};

export const PPIGroups = {
  PROFILE_PPI: 'Profile',
  OTHER_PPI: 'Other',
};

export const InitiativeParticipationStatuses = ['OPEN', 'FULL', 'COMPLETED'];

export const ContentStatus = {
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  PREVIEW: 'preview',
  COMPILING: 'compiling',
};

export const StatusMessage = {
  PUBLISHED: 'published',
  UNPUBLISHED: 'previous version',
  PREVIEW: 'draft',
  COMPILING: 'pending publish',
};

export const PermissionType = {
  CREATE: 'CREATE',
  READ: 'READ',
  VIEW: 'READ',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

export const EntityType = {
  INITIATIVE: 'initiative',
  AUDIENCE: 'audience',
  CANDIDATE_REVIEW: 'candidateReview',
  ACCESS_CONTROL: 'accessControl',
  CACHE: 'cache',
  DATA_REQUEST: 'dataRequests',
  DEV_TOOLS: 'dev',
  ASSET_SCREENER: 'assetScreener',
  ASSET_CONTENT: 'assetContent',
  ASSET_CONSENT: 'assetConsent',
  ASSET_SURVEY: 'assetSurvey',
  PPI: 'ppi',
};

export const PermissionGroups = {
  DEFAULT_ORG: 'JTL',
  DEFAULT_ALL: 'ALL',
};
