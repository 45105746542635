/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {
  createRef,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import PlaceholderImage from '../../../assets/img_placeholder.png';
import './styles.scss';
import { useFileUpload } from 'lib/upload';
import { Modal } from 'components/Modals';
import { Label, Text } from '@nike/eds';
import { ViewMode } from 'lib/enums';
import { useDropzone } from 'react-dropzone';
import { IconButton } from '@material-ui/core';
export default function SingleImageUpload(props) {
  const {
    value,
    label,
    name,
    defaultValue,
    setValue,
    canAdd = true,
    isEditable = true,
    helperText,
    viewMode,
    errors,
    setFieldTouched,
    size,
  } = props;

  const [prev, setPrev] = useState(value || PlaceholderImage);
  const dropzoneRef = createRef();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState(value || '');

  const { onDrop, fileId, previewUrl, percentComplete, errorMessage } =
    useFileUpload({
      defaultImageUrl: PlaceholderImage,
      defaultFileId: defaultValue,
    });

  const isUploading = percentComplete > 0 && percentComplete < 100;

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    // Limit to maximum image size allowed in Cloudinary account
    // maxSize: 104857600 //( 100MB )
    maxSize: 1000000, //( 1MB )
    onDrop,
  });

  const openDialog = useCallback(() => {
    if (dropzoneRef.current) dropzoneRef.current.open();
  }, []);

  const handleRemove = useCallback(() => {
    setValue(name, null);
    setFieldTouched(name, true);
    setPrev(PlaceholderImage);
  }, []);

  const showPreview = (img) => {
    setModalOpen(true);
    setPreviewImage(img);
  };

  const hasMounted = useRef(false);

  useEffect(() => {
    if (previewUrl) {
      setPrev(previewUrl);
    }
  }, [previewUrl]);

  useEffect(() => {
    if (hasMounted.current) {
      if (fileId) setValue(name, fileId);
    } else {
      hasMounted.current = true;
    }
  }, [fileId]);

  return (
    <>
      <Label className="label" font="title-6">
        { label || '' }
      </Label>
      <div className={ `single-image-upload ${ size }` } disabled={ isUploading }>
        <div { ...getRootProps({ className: 'dropzone' }) }>
          <input name={ name } { ...getInputProps() } />
          <button
            type="button"
            onClick={ openDialog }
            className={ 'upload-trigger-button' }
          >
            <img src={ prev } alt={ 'profile' } width={ '100%' } />
          </button>
        </div>
        { isEditable &&
          !canAdd &&
          previewImage !== prev &&
          viewMode !== ViewMode.VIEW && (
          <IconButton
            className={ 'reset-asset' }
            size="medium"
            color="primary"
            variant="contained"
            onClick={ handleRemove }
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        ) }
        { isEditable && canAdd && previewImage && (
          <IconButton
            className={ 'reset-asset' }
            size="medium"
            color="primary"
            variant="contained"
            onClick={ handleRemove }
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        ) }
        { !isEditable && previewImage && (
          <IconButton
            className={ 'preview-asset' }
            size="medium"
            color="primary"
            variant="contained"
            onClick={ useCallback(() => showPreview(prev), [prev]) }
          >
            <SearchIcon fontSize="large" />
          </IconButton>
        ) }
        <Modal
          handleClose={ useCallback(() => setModalOpen(false), []) }
          open={ modalOpen }
          BodyComponent={ useCallback(
            () => (
              <div>
                <img
                  src={ previewImage || value }
                  alt={ 'profile' }
                  width={ '100%' }
                />
              </div>
            ),
            []
          ) }
          title={ 'Image Preview' }
        />
      </div>
      { helperText.split('/').map((text) => (
        <Text key={ text } font="subtitle-2" className="helper-text">
          { text }
        </Text>
      )) }
      { isUploading && (
        <span className="uploadingMessage">Uploading Image...</span>
      ) }
      { (errorMessage || errors?.[name]) && !isUploading && (
        <>
          <span className="errorMessage">{ errorMessage || errors?.[name] }</span>
        </>
      ) }
    </>
  );
}

SingleImageUpload.propTypes = {
  canAdd: PropTypes.bool,
  defaultValue: PropTypes.string,
  errors: PropTypes.shape({
    logoImage: PropTypes.string,
    backgroundImage: PropTypes.string,
  }),
  helperText: PropTypes.string,
  isEditable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  setFieldTouched: PropTypes.func,
  setValue: PropTypes.func,
  size: PropTypes.string,
  value: PropTypes.string,
  viewMode: PropTypes.string,
};
