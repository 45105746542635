import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

import { slugify } from 'lib/string';

const SlugField = ({
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = useCallback(event => {
    const { setValue, setTouched } = helpers;
    const value = event.target.value;
    setValue(slugify(value));
    setTouched(true);
  }, [helpers]);

  return (
    <TextField
      { ...field }
      { ...props }
      fullWidth={ true }
      error={ !!(meta.touched && meta.error) }
      helperText={ meta.error }
      onChange={ handleChange }
      label={ label } />
  );
};

SlugField.propTypes = {
  label: PropTypes.string,
};

SlugField.defaultProps = {
  label: '',
};

export default memo(SlugField);
