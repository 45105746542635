/* eslint-disable react/prop-types */
// modules
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
/* eslint-disable-next-line no-unused-vars*/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StatusIndicator, Text, Modal } from '@nike/eds';
import { capitalize } from '@vl/js-lib/browser/string';
import { FormContainer } from 'components/FormContainer';
// aliased
import { InitiativeForm } from 'components/forms';
import { useFetchinitiativeListQuery } from 'features/adminApi/endpoints/initiative';
import { usePatchExperienceRequestMutation } from 'features/adminApi/endpoints/experiences';
import { useDeleteUserApiCacheMutation } from 'features/adminApi/endpoints/userApiCache.js';
import { ViewMode } from 'lib/enums';
import { transformValues, useScroll } from 'lib/util.js';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import AddEditContent from '../ManageAssets/views/AddEditContent';
import styles from './styles.module.scss';
import { omit } from 'ramda';

import {
  CancelButtonStyle,
  DeleteButton,
  ErrorText,
  InputTextStyle,
  modalBottomViewContainerStyle,
  modalContainerStyle,
} from './styles';
import useInitTypeValidation from '../../utils';

export const filter = (data, id) =>
  data?.find((initiative) => initiative.handle === id);

//eslint-disable-next-line max-statements
const EditExperience = () => {
  const { id, initiativeType } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();

  const [executeScroll, elRef] = useScroll();
  const location = useLocation();
  const isDeleteAction = location.pathname.includes('/delete');

  const [modalIsOpen, setModalOpen] = useState(isDeleteAction);
  const [inputValue, setInputValue] = useState('');
  const [deleted, setDeleted] = useState(false);
  const [errorValue, setErrorValue] = useState(false);

  // DELETE MODAL TEXT CONSTANTS ----------------------------------------------------------
  const deleteModalInputLabel = 'Type DELETE to confirm';
  const errorModalText =
    'Sorry, please enter the text as exactly displayed to confirm.';
  const deleteBtnText = 'Delete';
  const cancelBtnText = 'Cancel';

  // EXPERIENCE REDUX API HOOKS ----------------------------------------------------------
  const [usePatchExperienceRequest, patchExperienceResult] =
    usePatchExperienceRequestMutation();

  //the delete init route was not complete enough to use, it doesn't delete
  //all the related data on the backend and therefore is problematic to use.
  // const [useDeleteExperienceRequest, deleteExperienceResult] =
  //   useDeleteExperienceRequestMutation();

  const { resultData, isFetching } = useFetchinitiativeListQuery(undefined, {
    selectFromResult: useCallback(({ data }) => {
      return { resultData: filter(data, id) };
    }, []),
  });

  const initiativeData = resultData;

  const [useDeleteUserApiCache, deleteUserApiCacheResult] =
    useDeleteUserApiCacheMutation();

  const initType = initiativeData?.initiativeType;

  useInitTypeValidation(initType);

  useEffect(() => {
    if (initiativeData?.status === 'deleted') {
      addToast(
        ` Cannot access the edit form for the deleted initiative with the handle ${ initiativeData?.handle }`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
      history.push({
        pathname: `/initiative/${ initiativeType }`,
      });
    }
  }, [history, initiativeData]);

  // API CALLS ----------------------------------------------------------------
  const handleFormSubmit = useCallback(async (values) => {
    usePatchExperienceRequest(transformValues(values, initiativeType));
  }, []);

  const deleteInitiative = useCallback(async () => {
    if (inputValue === 'DELETE') {
      setModalOpen(false);
      const cleanUpdatedInitiativeData = omit(['id'], {
        ...initiativeData,
        status: 'deleted',
      });

      await usePatchExperienceRequest(
        transformValues(cleanUpdatedInitiativeData, initiativeType)
      );
      setDeleted(!deleted);
      // useDeleteExperienceRequest(initiativeData.handle);
    } else {
      setErrorValue(true);
    }
  }, [inputValue]);

  const deleteUserApiCache = useCallback(async () => {
    await useDeleteUserApiCache();
  }, []);

  // HANDLE SUCCESSFUL EXPERIENCE REQUEST -------------------------------------------
  useEffect(() => {
    if (patchExperienceResult.isSuccess) {
      deleteUserApiCache();
      addToast(
        `${
          !deleted
            ? 'Initiative Properties Saved'
            : 'Initiative status property updated to deleted'
        }`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      );
      if (deleted) {
        history.push({
          pathname: `/initiative/${ initiativeType }`,
        });
      }
    }
  }, [history, patchExperienceResult.isSuccess]);

  // HANDLE CREATE EXPERIENCE ERROR -------------------------------------------------
  useEffect(() => {
    if (patchExperienceResult?.error?.data?.errorMessage) {
      addToast(patchExperienceResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchExperienceResult?.error?.data?.errorMessage]);

  // HANDLE REDIRECT -----------
  useEffect(() => {
    if (history?.location?.state?.redirectReason === 'no-assets') {
      const element = document.getElementById('default-asset-form');
      return element?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [history?.location?.state]);

  useEffect(executeScroll, []);

  // HANDLE DELETE USER API CACHE RESULTS -----------
  useEffect(() => {
    if (deleteUserApiCacheResult?.isSuccess) {
      addToast('User API cache cleared', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else if (deleteUserApiCacheResult?.error) {
      addToast(deleteUserApiCacheResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [deleteUserApiCacheResult]);

  // DELETE MODAL HELPER FUNCTIONS -----------
  const onValueChange = useCallback(
    (event) => {
      setErrorValue(false);
      setInputValue(event.target.value);
    },
    [errorValue, inputValue]
  );

  const openDeleteModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    if (isDeleteAction) openDeleteModal();
  }, []);

  const isFormReady = !isFetching && resultData;

  const actionText = isDeleteAction ? 'Delete' : 'Edit';
  const initiativeTypeText = initiativeType === 'core' ? 'Core' : 'Experience';

  const addEditContentRef = useRef(null);
  useEffect(() => {
    if (location.state?.editDefaultMedia) {
      addEditContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <FormContainer className={ styles.assetContainer }>
        <div className={ styles.assetContainer } ref={ elRef }>
          <Text as="h1" font="title-1" style={{ width: '80%' }}>
            { `${ actionText } ${ initiativeTypeText } ${ id }` }
          </Text>
          <div>
            <Modal
              isOpen={ modalIsOpen }
              onDismiss={ closeDeleteModal }
              headerSlot={ deleteModalInputLabel }
              css={ modalContainerStyle }
            >
              <InputTextStyle
                type="search"
                id="validate-delete-text"
                name="validateDelete"
                value={ inputValue }
                onChange={ onValueChange }
              />
              { errorValue && <ErrorText>{ errorModalText }</ErrorText> }
              <div style={ modalBottomViewContainerStyle }>
                <DeleteButton onClick={ deleteInitiative }>
                  { deleteBtnText }
                </DeleteButton>
                <Link
                  to={{
                    pathname: `/initiative/${ initiativeType }`,
                  }}
                  onClick={ closeDeleteModal }
                  style={ CancelButtonStyle }
                >
                  { cancelBtnText }
                </Link>
              </div>
            </Modal>
          </div>
          <StatusIndicator
            style={{ margin: 8 }}
            label={ capitalize(resultData?.status) }
            status={
              { active: 'success', inactive: 'inactive' }[resultData?.status]
            }
          />
        </div>
        { isFormReady && (
          <InitiativeForm
            disabled={ isFetching }
            viewMode={ ViewMode.EDIT }
            onSubmit={ handleFormSubmit }
            submitText="Edit"
            initialValues={ initiativeData }
            id={ id }
          />
        ) }
      </FormContainer>
      { isFormReady && (
        <div ref={ addEditContentRef }>
          <AddEditContent isFetching={ isFetching } viewMode={ ViewMode.EDIT } />
        </div>
      ) }
    </>
  );
};

export default EditExperience;
