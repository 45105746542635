import * as Yup from 'yup';

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const validationSchema = Yup.object().shape({
  upmids: Yup.array().of(
    Yup.string().matches(
      uuidRegex,
      'One or more ids are not in correct format.'
    )
  ),
});

export const INITIAL_VALUES = {
  upmids: [''],
};
