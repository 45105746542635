import styled from '@emotion/styled';
import { Modal } from '@nike/eds';

export const TooltipMessage = styled.div`
  color: #111111;
  text-align: center;
  white-space: pre-line;
`;

export const PublishModalStyled = styled(Modal)`
  .eds-modal__content {
    max-width: 450px;
    overflow-y: auto;
  }
`;
