import styled from '@emotion/styled';

export const ToggleConditionalWrapper = styled.div`
  background: lightgrey;
  width: fit-content;
  padding: 12px;
  border-radius: 4px;
  cursor: ${ (props) => (props.isChecked ? 'not-allowed' : 'initial') };
  .eds-toggle {
    font: var(--eds-type-subtitle-2) !important;
  }

  label.eds-toggle--disabled {
    color: var(--eds-color-grey-1) !important;
  }
`;
