import React from 'react';
import { Container, Typography } from '@material-ui/core';

const NoAccess = () => {
  return (
    <Container>
      <Typography variant="h3">Access Denied</Typography>
      <Typography>You do not have permissions to access this page.</Typography>
    </Container>
  );
};

export default NoAccess;
