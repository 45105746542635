import React, { memo, useCallback, useState } from 'react';
import { RadioGroup, Radio } from '@nike/eds';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import styles from './RadioSet.module.scss';
import { isTextOrHiddenType, requiredError } from 'components/forms/utils';
import RadioLabel from './RadioLabel';

const RadioSet = ({
  onChange,
  disabled,
  label,
  multiple,
  className,
  options,
  selectedValue,
  hasErrors,
  errorMessage,
  answerOptions,
  isConditionalToggled,
  ...props
}) => {
  const [{ ...field }, meta, helpers] = useField(props);
  const [isHovered, setIsHovered] = useState(false);
  const [isDisableHiddenType, setIsDisableHiddenType] = useState(false);
  const handleChange = useCallback(
    (response) => {
      const { setValue, setTouched } = helpers;
      setTouched(true);
      setValue(response?.target?.id);
    },
    [helpers]
  );

  const handleMouseEnter = useCallback(
    (value) => {
      setIsHovered(
        (isConditionalToggled && value === 'hidden') ||
          (!isTextOrHiddenType(selectedValue) &&
            answerOptions?.length > 0 &&
            isTextOrHiddenType(value))
      );
      setIsDisableHiddenType(isConditionalToggled && value === 'hidden');
    },
    [selectedValue, answerOptions]
  );

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  });

  return (
    <FieldWrapper>
      <div onMouseOut={ handleMouseLeave }>
        <RadioGroup
          className={ styles.radioFieldLabel }
          label={ label }
          name={ field.name }
          valueSelected={ selectedValue }
          orientation="horizontal"
          onChange={ onChange || handleChange }
          hasErrors={
            !!(meta.touched && hasErrors) || !!(meta.touched && meta.error)
          }
          errorMessage={ errorMessage || requiredError }
        >
          { options &&
            options.map((opt) => {
              const isDisabled =
                (isConditionalToggled && opt.value === 'hidden') ||
                (isTextOrHiddenType(opt.value) && answerOptions?.length > 0);

              return (
                <Radio
                  key={ `${ field.name }-${ opt.value }` }
                  className={ `${ isDisabled ? styles.disabled : '' }` }
                  label={
                    <RadioLabel
                      label={ opt.label }
                      value={ opt.value }
                      handleMouseEnter={ handleMouseEnter }
                    />
                  }
                  value={ opt.value }
                  id={ `${ field.name }-${ opt.value }` }
                  disabled={
                    isDisabled ? true : disabled ? disabled : opt.disabled
                  }
                  // eslint-disable-next-line
                  onMouseEnter={useCallback(() => handleMouseEnter(opt.value))}
                />
              );
            }) }
        </RadioGroup>

        { isHovered && (
          <div
            className={ `${ styles.tooltip } ${
              isDisableHiddenType
                ? styles.hiddenStyle
                : styles.textAndHiddenStyle
            }` }
          >
            <span> ⚠️ </span>
            { isDisableHiddenType
              ? 'Conditional question cannot have a hidden question type'
              : 'Question Type cannot be changed when Answer Options are present.' }
          </div>
        ) }
      </div>
    </FieldWrapper>
  );
};

RadioSet.propTypes = {
  answerOptions: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasErrors: PropTypes.bool,
  isConditionalToggled: PropTypes.bool,
  label: PropTypes.element,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedValue: PropTypes.string,
};

export default memo(RadioSet);
