import React from 'react';
import { Text } from '@nike/eds';
import PropTypes from 'prop-types';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import {
  formatHighlightText,
  formatTable,
  formatText,
  format,
  cleanText,
} from 'views/Initiative/Experiences/components/ManageAssets/components/Preview/utils';
import remarkRehype from 'remark-rehype';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import rehypeReact from 'rehype-react';

const Headline = ({
  text,
  tagline,
  subtitle,
  style,
  headline,
  richText = false,
  ...restProps
}) => {
  const textProps = tagline
    ? { as: 'p', font: 'body-2' }
    : { as: 'h5', font: 'title-1' };
  return richText ? (
    <div style={{ wordBreak: 'break-word', ...style }}>
      <ReactMarkdown
        children={ cleanText(text) }
        remarkPlugins={ [remarkRehype, [remarkGfm, {}], remarkParse] }
        rehypePlugins={ [
          [
            rehypeReact,
            {
              createElement: React.createElement,
              fragment: React.Fragment,
            },
          ],
        ] }
        linkTarget="_blank"
        components={{
          h1: formatText,
          h2: formatText,
          h3: formatText,
          h4: formatText,
          h5: formatText,
          h6: formatText,
          ul: format,
          table: formatTable,
          blockquote: formatText,
          mark: formatText,
          p: formatHighlightText,
        }}
      />
    </div>
  ) : (
    <Text
      { ...(!headline && !subtitle && textProps) }
      { ...restProps }
      style={{ wordBreak: 'break-word', ...style }}
    >
      { text }
    </Text>
  );
};

Headline.propTypes = {
  headline: PropTypes.bool,
  richText: PropTypes.bool,
  style: PropTypes.shape({}),
  subtitle: PropTypes.bool,
  tagline: PropTypes.bool,
  text: PropTypes.string,
};
export default Headline;
