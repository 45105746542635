import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { createRoot } from 'react-dom/client';

import ApolloProvider from 'components/ApolloProvider';
import { Provider } from 'react-redux';
import App from 'views/App';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Authentication from 'components/Authentication';
import NikeAuthProvider from 'components/NikeAuthProvider';
import { setupStore } from './store';
import './index.scss';
import '@nike/eds/dist/index.css';
import { createTheme } from '@mui/material/styles';
import { suppressSpecificWarnings } from 'supressWarning';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
  },
});

suppressSpecificWarnings();

const app = (
  <Provider store={ setupStore() }>
    <Router>
      <Authentication>
        <ApolloProvider>
          <StylesProvider injectFirst={ true }>
            <ToastProvider placement="bottom-right">
              <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <MuiThemeProvider theme={ theme }>
                  <NikeAuthProvider>
                    <App />
                  </NikeAuthProvider>
                </MuiThemeProvider>
              </MuiPickersUtilsProvider>
            </ToastProvider>
          </StylesProvider>
        </ApolloProvider>
      </Authentication>
    </Router>
  </Provider>
);

createRoot(document.getElementById('root')).render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
