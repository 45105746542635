import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { transformValues } from 'lib/util.js';

// aliased
import { InitiativeForm } from 'components/forms';
import { FormContainer } from 'components/FormContainer';
import { useCreateExperienceMutation } from 'features/adminApi/endpoints/experiences';
import { ViewMode } from 'lib/enums';
import { Text } from '@nike/eds';

const CreateExperience = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { initiativeType } = useParams();
  const [postExperience, experienceResult] = useCreateExperienceMutation();

  const handleCreateInitiative = useCallback(async (values) => {
    postExperience(transformValues(values, initiativeType));
  }, []);

  // HANDLE CREATE EXPERIENCE ERROR -------------------------------------------------
  useEffect(() => {
    if (experienceResult.error?.data.errorMessage) {
      addToast(experienceResult.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [experienceResult.error?.data.errorMessage]);

  // HANDLE SUCCESSFUL DATA REQUEST --------------------------------------------
  // HANDLE ROUTE CHANGE TO AUDIENCES WITH EXPERIENCE HANDLE -------------------
  useEffect(() => {
    if (experienceResult.isSuccess) {
      addToast('Success', { appearance: 'success', autoDismiss: true });
      history.push(
        // eslint-disable-next-line max-len
        `/initiative/${ initiativeType }/${ experienceResult.data.handle }/create/audiences`,
        {
          // returnUrl: `/initiative/experiences/${ experienceResult.data.handle }/assets/create/default-media`,
          // eslint-disable-next-line max-len
          returnUrl: `/initiative/${ initiativeType }/create/${ experienceResult.data.handle }/assets/create/default-media`,
        }
      );
    }
  }, [experienceResult.isSuccess]);

  return (
    <FormContainer>
      <Text font={ 'title-1' } as={ 'h1' }>
        Create Experience
      </Text>
      <InitiativeForm
        onSubmit={ handleCreateInitiative }
        disabled={ experienceResult.isLoading }
        initialValues={{}}
        viewMode={ ViewMode.CREATE }
      />
    </FormContainer>
  );
};

export default CreateExperience;
