import React from 'react';
import { ToggleConditionalWrapper } from './styles';
import { Toggle } from '@nike/eds';
import PropTypes from 'prop-types';

const ToggleComp = ({
  hasChecked = false,
  fieldName = '',
  fieldValue = false,
  lastNonConditionalQuestionLabel = '',
  onChange,
  disabled = false,
}) => {
  return (
    <ToggleConditionalWrapper isChecked={ hasChecked }>
      <Toggle
        id={ fieldName }
        name={ fieldName }
        label={
          fieldValue
            ? `Conditional Question:  ${ lastNonConditionalQuestionLabel }`
            : 'Conditional Question'
        }
        onChange={ onChange }
        checked={ fieldValue }
        disabled={ disabled }
      />
    </ToggleConditionalWrapper>
  );
};

ToggleComp.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.bool,
  hasChecked: PropTypes.bool,
  lastNonConditionalQuestionLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default ToggleComp;
