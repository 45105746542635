// modules
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// aliased
import { PollForm } from 'components/forms';
import { FormContainer } from 'components/FormContainer';
import {
  useFetchPollQuery,
  useEditPollMutation,
} from 'features/adminApi/endpoints/polls';
import { ViewMode } from 'lib/enums';
import { parsePoll } from '../../lib';
import Loading from 'components/Loading';
import { Text } from '@nike/eds';

const EditPoll = () => {
  const { id, initiativeType } = useParams();
  const history = useHistory();
  const { data = [], isLoading } = useFetchPollQuery(id);
  const [editPoll, pollResult] = useEditPollMutation();

  const handleEdit = useCallback(async (values) => {
    editPoll(values);
  }, []);

  const handleCancel = useCallback(async () => {
    history.push(`/initiative/${ initiativeType }`);
  }, []);

  useEffect(() => {
    if (pollResult.isSuccess) {
      history.goBack(); // or should the submit button change?
    }
  }, [pollResult.isSuccess]);

  const pollData = useMemo(() => parsePoll(data), [data]);

  return (
    <>
      { isLoading ? (
        <Loading text="Fetching Poll details" />
      ) : (
        <FormContainer>
          <Text font={ 'title-1' } as={ 'h1' }>
            Edit Poll
          </Text>
          <PollForm
            key={ `poll_${ id }_${ isLoading }` }
            onSubmit={ handleEdit }
            disabled={ isLoading || pollResult.isLoading }
            initialValues={ pollData }
            viewMode={ ViewMode.EDIT }
            submitText="Save"
            cancelText="Cancel"
            onCancel={ handleCancel }
          />
        </FormContainer>
      ) }
    </>
  );
};

export default EditPoll;
