import React from 'react';
import { SelectField } from 'components/fields';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const PropertyOperatorPicker = ({ options, type, label, name, disabled }) => {
  return (
    <div className={ styles.ppiOperator }>
      { type === 'select' ? (
        <SelectField
          placeholder="Select one"
          defaultValue={ options[0] }
          label={ label }
          name={ name }
          options={ options }
          multiple={ false }
          required={ true }
          disabled={ disabled }
        />
      ) : null }
    </div>
  );
};

PropertyOperatorPicker.defaultProps = {
  disabled: false,
  label: '',
  name: '',
  options: [],
  type: '',
};
PropertyOperatorPicker.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  type: PropTypes.string.isRequired,
};

export default PropertyOperatorPicker;
