import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const contentApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['content', 'cms', 'experiences'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchContent: builder.query({
          query(initiativeHandle) {
            return `/initiatives/${ initiativeHandle }/contents`;
          },
          providesTags: (result = []) => [
            'content',
            ...result.map(({ id }) => ({ type: 'content', id })),
          ],
          transformResponse: (response, _, status) =>
            pathOr([], ['contents'], response),
        }),
        patchContent: builder.mutation({
          query: (body) => {
            return {
              url: `/initiatives/${ body?.initiativeHandle }/contents/${ body?.id }`,
              method: 'PATCH',
              body,
            };
          },
          invalidatesTags: (result, error, { id, initiativeHandle }) => {
            // Invalidates experience results for particular initHandle
            return [
              { type: 'content', id },
              { type: 'experiences', id: initiativeHandle },
            ];
          },
        }),
        createContent: builder.mutation({
          query: (body) => ({
            url: `/initiatives/${ body.initiativeHandle }/contents`,
            method: 'POST',
            body,
          }),
          invalidatesTags: (result, error, { id }) => {
            return [
              { type: 'content', id: 'LIST' },
              { type: 'content', id },
              'content',
            ];
          },
        }),
        fetchContentById: builder.query({
          query(content) {
            const { initiativeHandle, id } = content;
            return `/initiatives/${ initiativeHandle }/contents/${ id }`;
          },
          transformResponse: (response, _, _status) => response,
        }),
      };
    },
  });

export const {
  useFetchContentQuery,
  useFetchContentByIdQuery,
  usePatchContentMutation,
  useCreateContentMutation,
} = contentApiSlice;
