import { adminApiSlice } from '../adminApiSlice';
import { head } from 'ramda';
import { formatDate } from 'views/DevView/EventJobs/lib';

const eventJobsSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['event', 'jobId'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchEventJobs: builder.query({
          query() {
            return '/jobs';
          },
          providesTags: (_result, _error, _data) => [
            { type: 'event', id: 'LIST' },
          ],
          transformResponse: (response, _, _status) => {
            const { jobItems } = response || {};
            return jobItems?.sort(
              (fir, sec) =>
                new Date(sec.createTimestamp) - new Date(fir.createTimestamp)
            );
          },
        }),
        fetchEventJobById: builder.query({
          query(jobId) {
            return `/jobs/${ jobId }`;

            // TODO will be using this in future
            //return `/jobs?filter = jobId(${ jobId })`;
          },
          providesTags: (_result, _error, jobId) => [
            { type: 'jobId', id: jobId },
          ],
          transformResponse: (response, _, _status) => {
            const { createTimestamp, updateTimestamp, ...rest } =
              response && head(response.jobItems);

            const modifyCreateTimestamp = formatDate(createTimestamp);
            const modifyUpdateTimestamp = formatDate(updateTimestamp);

            return {
              ...rest,
              createTimestamp: modifyCreateTimestamp,
              updateTimestamp: modifyUpdateTimestamp,
            };
          },
        }),
      };
    },
  });

export const { useFetchEventJobsQuery, useFetchEventJobByIdQuery } =
  eventJobsSlice;
