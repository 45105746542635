import React, { memo, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { applyTo, pipe, head } from 'ramda';
import cx from 'classnames';

import { propTypes, defaultProps } from 'lib/react';

import styles from './DropZone.module.scss';
import uploadIcon from './upload.svg';

export default applyTo(({ onChange, url }) => {

  const onDrop = useCallback(acceptedFiles => {
    const file = head(acceptedFiles);
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => onChange({ url: reader.result });
    reader.readAsDataURL(file);
  }, [onChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div { ...getRootProps() } className={ cx(styles.dropZone, isDragActive && styles.hover, url && styles.occupied) }>
      { url && <img src={ url } alt='preview' className={ styles.preview } /> }
      <div className={ styles.overlay }>
        <img src={ uploadIcon } alt='upload icon' className={ styles.uploadIcon } />
      </div>
      { !url && <img src={ uploadIcon } alt='upload icon' className={ styles.uploadIcon } /> }
      <input { ...getInputProps() } />
    </div>
  );
}, pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
