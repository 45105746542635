import { adminApiSliceFileUpload } from '../adminApiSlice';

const imagesApiSlice = adminApiSliceFileUpload.injectEndpoints({
  endpoints(builder) {
    return {
      createImage: builder.mutation({
        query: body => ({
          url: '/images',
          method: 'POST',
          body,
        }),
      }),
    };
  },
  overrideExisting: true,
});

export const { useCreateImageMutation } = imagesApiSlice;
