import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@nike/eds';

const CustomFieldLabel = ({ label, font = 'body-2' }) => (
  <Text font={ font } as={ 'h3' }>
    { label }
  </Text>
);

CustomFieldLabel.propTypes = {
  font: PropTypes.string,
  label: PropTypes.string,
};

export default CustomFieldLabel;
