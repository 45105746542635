// modules
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// aliased
import { PollForm } from 'components/forms';
import { FormContainer } from 'components/FormContainer';
import { useCreatePollMutation } from 'features/adminApi/endpoints/polls';
import { useToasts } from 'react-toast-notifications';
import { Text } from '@nike/eds';

const CreatePoll = () => {
  const history = useHistory();
  const { initiativeType } = useParams();
  const { addToast } = useToasts();
  const [postPoll, pollResult] = useCreatePollMutation();

  const handleCreatePoll = useCallback(async (values) => {
    postPoll(values);
  }, []);

  useEffect(() => {
    if (pollResult.isSuccess) {
      history.push(
        `/initiative/${ initiativeType }/${ pollResult.data.handle }/audiences`,
        {
          returnUrl: '/initiative/polls',
        }
      );
    }
  }, [pollResult]);

  // HANDLE CREATE POLL ERROR -------------------------------------------------
  useEffect(() => {
    const errorData = pollResult.error?.data;
    if (errorData?.errorType) {
      const errorMessage = errorData.errorMessage
        ? ` : ${ errorData.errorMessage }`
        : '';
      const message = `${ errorData.errorType }${ errorMessage }`;

      addToast(message, {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push(`/initiative/${ initiativeType }`);
    }
  }, [pollResult.error]);

  return (
    <FormContainer>
      <Text font={ 'title-1' } as={ 'h1' }>
        Create Poll
      </Text>
      <PollForm
        onSubmit={ handleCreatePoll }
        disabled={ pollResult.isLoading }
        audiences={ [] }
        initialValues={{}}
        submitText="Save"
      />
    </FormContainer>
  );
};

export default CreatePoll;
