import React, { memo, useRef, useCallback } from 'react';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './index.module.scss';

export default applyTo(
  ({ className, text, label, title, onCopy }) => {
    const textAreaRef = useRef();

    const handleCopy = useCallback(() => {
      textAreaRef.current.select();
      document.execCommand('copy');
      onCopy(textAreaRef.current.value);
    }, [onCopy]);

    return (
      <>
        <Button className={ className } title={ title } onClick={ handleCopy }>
          { label }
        </Button>
        <textarea
          ref={ textAreaRef }
          readOnly={ true }
          className={ styles.textArea }
          value={ text }
        />
      </>
    );
  },
  pipe(
    propTypes({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      text: PropTypes.string,
      title: PropTypes.string,
      onCopy: PropTypes.func,
    }),
    defaultProps({
      label: 'Copy',
      title: '',
      text: '',
      onCopy: _ => {},
    }),
    memo
  )
);
