// modules
import React, { memo, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe, path } from 'ramda';
import cx from 'classnames';
import { useTheme } from '@material-ui/core/styles';


// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './Code.module.scss';

// display preformatted code
export default applyTo(({
  children,
  className,
  inline,
  hidden,
  ...rest
}) => {
  if (hidden) return null;

  const theme = useTheme();
  const palette = path(['palette', 'type'], theme);

  const classNames = cx(className, styles.code, {
    [styles.inline]: inline,
    [styles[palette]]: true,
  });

  if (typeof children === 'object' && !isValidElement(children)) {
    const spaces = (inline ? 0 : 2);
    children = JSON.stringify(children, null, spaces);
  }

  return (
    <pre className={ classNames } { ...rest }>
      { children }
    </pre>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
    hidden: PropTypes.bool,
    className: PropTypes.string,
    inline: PropTypes.bool,
  }),
  defaultProps({
    className: '',
    hidden: false,
    inline: true,
  }),
  memo,
));
