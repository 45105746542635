export const suppressSpecificWarnings = () => {
  const suppressedWarnings = [
    'ReactDOM.render is no longer supported in React 18',
  ];

  const originalConsoleError = console.error;

  console.error = (...args) => {
    if (args.length > 0 && typeof args[0] === 'string') {
      const isSuppressedWarning = suppressedWarnings.some((warning) =>
        args[0].includes(warning)
      );
      const isFromRichMarkdownEditor = args.some((arg) =>
        arg.includes('RichMarkdownEditor')
      );

      if (isSuppressedWarning && isFromRichMarkdownEditor) {
        return;
      }
    }
    originalConsoleError(...args);
  };
};
