/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { FieldArray } from 'formik';
import PropertyValuePicker from './propertyValuePicker';
import PropertyOperatorPicker from './propertyOperatorPicker';
import {
  profilePropertiesSelectOptions,
  getAudienceProfilePropertyValueOptions,
  getAudienceProfilePropertyOperatorOptions,
} from './lib';
import Button from 'components/Button';
import { SelectField } from 'components/fields';
import { CreateRule, Delete } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Text } from '@nike/eds';

const PpiDefinition = ({
  title,
  disabled,
  ppiDefinition,
  render,
  addButtonLabel,
  propertyLabel,
  valueLabel,
  operatorLabel,
}) =>
  render && (
    <>
      <Text font="title-3" className={ styles.title }>
        { title }
      </Text>
      <FieldArray
        name="ppiDefinition"
        ppiDefinition={ ppiDefinition }
        render={ ({ push, remove }) => (
          <React.Fragment>
            { ppiDefinition && ppiDefinition.length > 0
              ? ppiDefinition.map((definition, index) => {
                return (
                  <div
                    key={ `profile-property-${ index }` }
                    className={ styles.ppiGroupWrapper }
                  >
                    <div className={ styles.ppiGroup }>
                      <div className={ styles.ppiProperty }>
                        <SelectField
                          placeholder="Select one"
                          label={ propertyLabel }
                          name={ `ppiDefinition.${ index }.handle` }
                          options={ profilePropertiesSelectOptions }
                          required={ true }
                          disabled={ disabled }
                        />
                      </div>
                      <PropertyOperatorPicker
                        { ...getAudienceProfilePropertyOperatorOptions(
                          definition.handle
                        ) }
                        index={ index }
                        disabled={ disabled }
                        label={ operatorLabel }
                        name={ `ppiDefinition.${ index }.operator` }
                      />
                      <PropertyValuePicker
                        { ...getAudienceProfilePropertyValueOptions(
                          definition.handle
                        ) }
                        disabled={ disabled }
                        label={ valueLabel }
                        range={{ min: 0 }}
                        name={ `ppiDefinition.${ index }.value` }
                      />
                    </div>
                    <Button
                      size="small"
                      disabled={ disabled }
                      onClick={ () => {
                        remove(index);
                      } }
                      variant="secondary"
                      disableRipple={ true }
                    >
                      <Delete />
                    </Button>
                  </div>
                );
              })
              : null }
            <Button
              className={ `${ styles.addButton } eds-spacing--mt-24` }
              size="medium"
              afterSlot={ <CreateRule /> }
              disabled={ disabled }
              onClick={ () => {
                push({ handle: '', value: '', operator: '' });
              } }
              variant="secondary"
            >
              { addButtonLabel }
            </Button>
          </React.Fragment>
        ) }
      />
    </>
  );

PpiDefinition.defaultProps = {
  addButtonLabel: '',
  disabled: false,
  operatorLabel: '',
  ppiDefinition: [],
  propertyLabel: '',
  render: true,
  title: '',
  valueLabel: '',
};

PpiDefinition.propTypes = {
  addButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  operatorLabel: PropTypes.string.isRequired,
  ppiDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
  propertyLabel: PropTypes.string.isRequired,
  render: PropTypes.bool,
  title: PropTypes.string.isRequired,
  valueLabel: PropTypes.string.isRequired,
};

export default PpiDefinition;
