import React from 'react';
import { Tooltip } from '@nike/eds';
import PropTypes from 'prop-types';

const TooltipWrapper = ({ active, message, children, placement }) => {
  return active ? (
    <Tooltip
      placement={ placement }
      bodySlot={ message }
      isDark={ true }
      enableFocus={ true }
    >
      <div>{ children }</div>
    </Tooltip>
  ) : (
    <div>{ children }</div>
  );
};

TooltipWrapper.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.element,
  message: PropTypes.element,
  placement: PropTypes.string,
};

TooltipWrapper.defaultProps = {
  active: false,
  placement: 'bottom',
  size: 'medium',
  message: {
    title: '',
    body: '',
  },
};

export default TooltipWrapper;
