import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@nike/eds';
import { useField } from 'formik';

const DateField = ({ fieldName, label, setHasUnsavedChanges, ...props }) => {
  const [field, meta, helpers] = useField(fieldName);

  const { setValue, setTouched } = helpers;
  const { value } = field;
  const { error, touched } = meta;

  const handleFieldChange = useCallback((event) => {
    setValue(event.target.value);
    setHasUnsavedChanges(true);
  }, []);

  const handleFieldTouched = useCallback(() => setTouched(true), []);

  return (
    <TextField
      type="date"
      name={ fieldName }
      id={ fieldName }
      value={ value }
      onChange={ handleFieldChange }
      hasErrors={ error && touched }
      errorMessage={ error }
      onBlur={ handleFieldTouched }
      { ...props }
      label={
        <label>
          { label }
          { (!error || !touched) && <span className="asterisk">*</span> }
        </label>
      }
    />
  );
};

DateField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setHasUnsavedChanges: PropTypes.func,
};

export default DateField;
