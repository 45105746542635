import React from 'react';
import { SelectField, TextField } from 'components/fields';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const PropertyValuePicker = ({
  options,
  range,
  type,
  disabled,
  label,
  name,
}) => {
  let component;
  const commonProps = { name, label, disabled, required: true };

  switch (type) {
    case 'select':
    case 'select-multi':
      component = (
        <SelectField
          { ...commonProps }
          placeholder="Select one"
          options={ options }
          multiple={ type === 'select-multi' }
        />
      );
      break;

    case 'input-number':
      component = (
        <TextField
          { ...commonProps }
          type="number"
          inputProps={{ min: range?.min, max: range?.max }}
        />
      );
      break;

    default:
      component = null;
      break;
  }

  return <div className={ styles.ppiValue }>{ component }</div>;
};

PropertyValuePicker.defaultProps = {
  disabled: false,
  label: '',
  name: '',
  options: [],
  range: {},
  type: '',
};

PropertyValuePicker.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  range: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  type: PropTypes.string.isRequired,
};

export default PropertyValuePicker;
