import { getResolutionDeadline, statusMap } from '../../utils';

export const columns = [
  {
    field: 'initiativeHandle',
    headerName: 'Initiative',
    minWidth: 200,
    flex: 1,
    filterable: true,
  },
  {
    field: 'resolutionDeadline',
    headerName: 'Resolution Deadline',
    minWidth: 200,
    flex: 1,
    filterable: true,
    valueGetter: (params) => getResolutionDeadline(params.row.createTimestamp),
  },
  {
    field: 'createTimestamp',
    headerName: 'Date Requested',
    minWidth: 200,
    flex: 1,
    filterable: true,
    type: 'dateTime',
  },

  {
    field: 'status',
    headerName: 'Status',
    minWidth: 200,
    flex: 1,
    filterable: true,
    valueFormatter: ({ value }) => statusMap[value] || value,
  },
];

export const getActions = (props) => {
  return [
    {
      key: 'initiativeHandle',
      label: 'resolve',
      onClick: props.resolve,
      url: '',
      disable: props.disable,
    },
  ];
};
