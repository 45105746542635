import React from 'react';
import PropTypes from 'prop-types';

import styles from './FieldError.module.scss';

const FieldError = ({
  children,
}) => (
  <p className={ styles.fieldError }>
    { children }
  </p>
);

FieldError.propTypes = {
  children: PropTypes.node,
};

FieldError.defaultProps = {
  children: null,
};

export default FieldError;
