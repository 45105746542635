import * as Yup from 'yup';
import {
  invalidCharacterError,
  nonSpecialCharRegex,
  requiredError,
} from '../utils';

export const validationSchema = Yup.object().shape({
  handle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  value: Yup.object().required('Enter valid JSON'),
});

export const INITIAL_VALUES = {
  handle: '',
  value: {},
};
