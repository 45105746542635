import React, { useCallback, useEffect } from 'react';

//API
import {
  useFetchEventJobByIdQuery,
  useFetchEventJobsQuery,
} from 'features/adminApi/endpoints/eventJobs';

//local
import EventDetailForm from './detailsForm';
import Loading from 'components/Loading';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { devUrl } from '../lib';

export const EventJobViewPage = () => {
  const { jobId } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    data: jobDetails,
    isLoading,
    isError,
    error,
  } = useFetchEventJobsQuery(undefined, {
    // Instead of making one more API call to fetch job details by jobId
    // we can search in here from already fetched job details
    selectFromResult: ({ data, error, isLoading }) => ({
      data: data?.find((job) => job.jobId === jobId),
      error,
      isLoading,
    }),
  });

  const {
    data: jobDetailsById,
    isLoading: isLoadingById,
    isError: isErrorById,
    error: errorById,
    refetch,
  } = useFetchEventJobByIdQuery(jobId, {
    skip: jobDetails && jobDetails.jobId === jobId,
  });

  useEffect(() => {
    if (isErrorById) {
      addToast(`${ errorById?.error }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isErrorById]);

  useEffect(() => {
    if (isError) {
      addToast(`${ error?.error }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError]);

  const handleGoBack = useCallback(() => {
    history.push(devUrl);
  }, []);

  useEffect(() => {
    if (
      !jobDetails &&
      !isLoading &&
      !isError &&
      !isLoadingById &&
      !isErrorById
    ) {
      refetch();
    }
  }, [jobDetails, isLoading, isError, isLoadingById, isErrorById]);

  return (
    <div>
      { isLoading && <Loading text="fetching event-job details" /> }
      { jobDetails && <EventDetailForm { ...jobDetails } goBack={ handleGoBack } /> }
      { !isLoading && !jobDetails && (
        <EventDetailForm
          { ...jobDetailsById }
          loadingError={ isError ? error?.error : null }
          goBack={ handleGoBack }
        />
      ) }
    </div>
  );
};
