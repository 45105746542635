import { withStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-cell': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      font: 'var(--eds-type-subtitle-2)',
    },
    '&.MuiDataGrid-root': {
      height: '100vh',
    },
    '&.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButton-root': {
      color: '#111',
      font: 'var(--eds-type-subtitle-2)',
    },
    '&.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButtonBase-root .MuiButton-startIcon .MuiBadge-root .MuiBadge-badge':
      {
        backgroundColor: 'var(--eds-color-black)',
      },
    ' & .MuiDataGrid-row.Mui-selected': {
      background: 'var(--eds-color-grey-2) !important',
    },
  },
})(DataGrid);
