import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import styles from './index.module.scss';
import { Field } from 'formik';
import { getFieldLabel } from './lib';
import { formTypes } from 'lib/propTypes';
import { MediaUpload } from 'components/fields';
import { ViewMode } from 'lib/enums';

const ImageFields = React.memo(
  ({
    fields,
    setFieldValue,
    setFieldTouched,
    setFormState,
    viewMode,
    errors,
    touched,
    requiredFields,
  }) => {
    const handleImageChange = useCallback((key, value) => {
      setFieldValue(key, value);
      if (viewMode === ViewMode.CREATE) setFieldTouched(key, true);
      setFormState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }, []);

    const imageFields =
      Array.isArray(fields) && fields.filter(({ type }) => type === 'image');

    return (
      <Grid
        item={ true }
        container={ true }
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={ 4 }
        className={ styles.mediaUpload }
        style={{ marginBottom: 8, marginTop: 10 }}
      >
        { Array.isArray(imageFields) &&
          imageFields.map(({ field, helperText }, index) => {
            return (
              <Grid item={ true } xs={ 12 } md={ 6 } key={ `${ field }-upload` }>
                <Field name={ field }>
                  { ({ field: formikField, meta }) => {
                    return (
                      <MediaUpload
                        name={ formikField.name }
                        label={
                          <label>
                            { getFieldLabel(formikField.name) }
                            { requiredFields[formikField.name] && (
                              <span
                                className={ `asterisk ${
                                  errors?.[formikField.name] &&
                                  touched[formikField.name]
                                    ? 'hasErrors'
                                    : ''
                                }` }
                              >
                                *
                              </span>
                            ) }
                          </label>
                        }
                        canAdd={ false }
                        setValue={ handleImageChange }
                        defaultValue={ imageFields[index].value }
                        helperText={ helperText }
                        viewMode={ viewMode }
                        errors={
                          errors && touched[formikField.name] ? errors : {}
                        }
                        setFieldTouched={ setFieldTouched }
                      />
                    );
                  } }
                </Field>
              </Grid>
            );
          }) }
      </Grid>
    );
  }
);

ImageFields.propTypes = formTypes;

export default ImageFields;
