// modules
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { applyTo, pipe, isEmpty, pick } from 'ramda';
import { Field, useFormikContext, withFormik } from 'formik';
import { TextField, SelectField, Toggle } from 'components/fields';
import { useHistory, useParams } from 'react-router-dom';
import SelectWithCriteria from '../../fields/SelectWithCriteria';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import {
  INITIAL_VALUES,
  validationSchema,
  TYPE_OPTIONS,
  PPI_OPTIONS,
  PARTICIPATION_STATUS_OPTIONS,
  PLATFORM_OPTIONS,
  DIGITAL_SERVICE_STATUS_OPTIONS,
  ORGANIZATION_ASSOCIATION_OPTIONS,
  moduleHandleArray,
} from './lib';
import styles from './index.module.scss';
import { CheckboxGrouped } from 'components/CheckboxSet';
import { ViewMode } from 'lib/enums';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import {
  defaultRequiredPpis,
  helperText,
  digitalServicePrefix,
  trimAndReplace,
  initiativePrefix,
  moduleHandleHelperText,
  textFormatter,
} from '../utils';
import { isNil } from 'lodash';
import { InitiativeTypesEnum, getRequiredFields } from 'utils';
import { Button, TextField as NikeTextField, Text } from '@nike/eds';
import FieldWrapper from 'components/FieldWrapper';

const requestFields = [
  'handle',
  'name',
  'status',
  'platforms',
  'requiredPpis',
  'participantLimit',
  'participationStatus',
  'clientName',
  'shortHandle',
  'moduleHandle',
  'campaignMonitorListId',
  'campaignMonitorListIds',
  'isConsentRequired',
  'studyId',
  'collectionId',
  'segmentIds',
  'organization',
  'attributes',
  //TODO: By Pablo - add when API is ready
  //Permissions
];

export default applyTo(
  //eslint-disable-next-line
  ({
    handleSubmit,
    errors,
    initialValues,
    dirty,
    values,
    viewMode,
    setFieldValue,
    touched,
  }) => {
    const {
      allPpis,
      requiredPpis,
      unlimitedParticipants,
      participantLimit,
      participationStatus,
      isConsentRequired,
      organization,
      shortHandle,
      moduleHandle,
      platforms,
    } = values;

    const { setFieldTouched } = useFormikContext();
    const history = useHistory();
    const formRef = useRef(null);
    const disabled = viewMode === ViewMode.READ;
    const createPage = viewMode === ViewMode.CREATE;
    const editUrl = `/initiative/${ values.initiativeType }/edit/${ values.handle }`;
    const { initiativeType } = useParams();
    const [selectedPPIs, setSelectedPPIs] = useState(requiredPpis);
    const [moduleHandleLabel, moduleHandleKey] = moduleHandleArray;
    const isModuleHandleMissing =
      moduleHandle === undefined || moduleHandle === null;

    useEffect(() => {
      if (unlimitedParticipants || !participantLimit) {
        setFieldValue('participantLimit', null);
      }
    }, [participantLimit, unlimitedParticipants]);

    useEffect(() => {
      if (!initialValues.participantLimit) {
        setFieldValue('unlimitedParticipants', true);
      }
    }, [initialValues.participantLimit]);

    useEffect(() => {
      if (participantLimit) {
        setFieldValue('unlimitedParticipants', false);
      }
    }, [participantLimit]);

    //participationStatus field is optional, but it's default value should be set to "Open".
    useEffect(() => {
      if (!participationStatus) {
        setFieldValue(
          'participationStatus',
          PARTICIPATION_STATUS_OPTIONS[0].value
        );
      }
    }, []);

    useEffect(() => {
      if (
        JSON.stringify(initialValues.platforms) !== JSON.stringify(platforms)
      ) {
        setFieldTouched('platforms', true);
      }
    }, [initialValues.platforms, platforms]);

    useEffect(() => {
      if (organization) {
        setFieldValue('organization', organization);
      }
      //eslint-disable-next-line
      !createPage &&
        !disabled &&
        !organization &&
        setFieldTouched('organization', true);
    }, [organization]);

    useEffect(() => {
      if (createPage && shortHandle && !touched?.moduleHandle) {
        setFieldValue(moduleHandleKey, shortHandle);
      } else if (moduleHandle) {
        setFieldValue(moduleHandleKey, moduleHandle);
      } else if (!createPage && !disabled && isModuleHandleMissing) {
        setFieldValue(moduleHandleKey, shortHandle);
        setFieldTouched(moduleHandleKey, true);
      }
    }, [touched?.moduleHandle, createPage, moduleHandle, shortHandle]);

    useEffect(() => {
      const isRequiredPpiNull = requiredPpis === null;
      if (allPpis) {
        setSelectedPPIs(isRequiredPpiNull ? defaultRequiredPpis : requiredPpis);
        setFieldValue('requiredPpis', null);
      } else {
        setFieldValue('requiredPpis', selectedPPIs);
      }
    }, [allPpis]);

    useEffect(() => {
      setFieldValue('allPpis', !requiredPpis?.length);
    }, [requiredPpis]);

    useEffect(() => {
      const val = trimAndReplace(values?.name);
      if (!isNil(val) && createPage) setFieldValue('handle', val);
    }, [values?.name]);

    useEffect(() => {
      const curInitiativeType =
        initiativeType === InitiativeTypesEnum.COR
          ? InitiativeTypesEnum.COR
          : values?.initiativeType
            ? values?.initiativeType
            : InitiativeTypesEnum.EXP;
      setFieldValue('initiativeType', curInitiativeType);
    }, [initiativeType, values?.initiativeType]);

    //USER HANDLE EVENTS
    const handleCancel = useCallback((event) => {
      event.preventDefault();
      history.goBack();
    }, []);

    const handleEditRoute = useCallback(() => {
      history.push(editUrl);
    }, []);

    const handleChange = useCallback((ev) => {
      const val = ev.target?.value;
      const key = ev.target?.name;
      setFieldTouched(key, true);
      return setFieldValue(
        key,
        key === moduleHandleKey ? val : trimAndReplace(val)
      );
    }, []);

    useEffect(() => {
      if (
        errors &&
        errors?.attributes &&
        Object.keys(errors.attributes).length > 0
      ) {
        const initialErrorField =
          'attributes' in errors
            ? Object.keys(errors?.attributes)[0]
            : Object.keys(errors)[0];

        const query = initialErrorField
          ? `[name="attributes.${ initialErrorField }"]`
          : `[name="${ initialErrorField }"]`;
        //scrolling to the particular error field element
        const elementToScroll = formRef.current.querySelector(query);
        if (elementToScroll) {
          elementToScroll.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }, [errors]);

    const requiredFields = getRequiredFields(validationSchema);

    const getModuleHandleValue = () => {
      if (!createPage) {
        return isModuleHandleMissing ? shortHandle : moduleHandle;
      }
      return (
        moduleHandle ?? (touched?.moduleHandle ? moduleHandle : shortHandle)
      );
    };

    return (
      <form onSubmit={ handleSubmit } ref={ formRef }>
        <fieldset disabled={ !!disabled } data-testid="fieldSet">
          <>
            <Grid
              container={ true }
              direction="row"
              spacing={ 0 }
              className={ styles.fieldSetGrid }
            >
              <Grid
                container={ true }
                direction="column"
                item={ true }
                xs={ 12 }
                spacing={ 4 }
              >
                <Grid item={ true }>
                  <Text font={ 'title-6' } as={ 'h6' }>
                    Properties
                  </Text>
                </Grid>
                <Grid
                  item={ true }
                  container={ true }
                  alignItems="center"
                  spacing={ 1 }
                  justifyContent="flex-start"
                >
                  <SelectWithCriteria
                    values={ values }
                    viewMode={ viewMode }
                    options={ DIGITAL_SERVICE_STATUS_OPTIONS }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <CheckboxGrouped
                  orientation="horizontal"
                  name="platforms"
                  label="Platform"
                  supportText="Select which platforms you wish to your poll to support"
                  options={ PLATFORM_OPTIONS }
                  disabled={ disabled }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="name"
                  label="Name"
                  required={ requiredFields.name }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <SelectField
                  name="initiativeType"
                  label="Initiative Type"
                  options={ TYPE_OPTIONS }
                  disabled={ true }
                  className={ styles.greyOut }
                  required={ requiredFields.initiativeType }
                />
              </Grid>
            </Grid>
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 6 }>
                <MaskedTextField
                  name="handle"
                  label="Handle"
                  onChange={ handleChange }
                  //TODO function to convert init type to appropriate prefix
                  //   handleType={
                  //     createPage ? experiencePrefix : digitalServicePrefix
                  //   }
                  handleType={
                    createPage
                      ? initiativePrefix(initiativeType)
                      : digitalServicePrefix
                  }
                  createPage={ createPage }
                  helperText={ helperText }
                  value={
                    !createPage
                      ? values?.handle
                      : values?.handle || touched?.handle
                        ? values?.handle
                        : trimAndReplace(values?.name)
                  }
                  disabled={ (!createPage && true) || disabled }
                  className={ !createPage ? styles.greyOut : '' }
                  required={ requiredFields.handle }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="shortHandle"
                  label="Short Handle"
                  helperText={ helperText }
                  disabled={ (!createPage && true) || disabled }
                  required={ requiredFields.shortHandle }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <Field
                  key={ moduleHandleKey }
                  name={ moduleHandleKey }
                  placeholder={ moduleHandleLabel }
                >
                  { ({ field, meta }) => {
                    const hasError = !!(meta.touched && meta.error);
                    return (
                      <FieldWrapper disabled={ disabled }>
                        <NikeTextField
                          id={ textFormatter(moduleHandleLabel) }
                          name={ field.name }
                          label={
                            <label>
                              { moduleHandleLabel }
                              { requiredFields[moduleHandleKey] && !hasError && (
                                <span className="asterisk">*</span>
                              ) }
                            </label>
                          }
                          value={ getModuleHandleValue() ?? '' }
                          message={ moduleHandleHelperText }
                          onChange={ handleChange }
                          className={ styles.moduleIdTextField }
                          hasErrors={ hasError }
                          errorMessage={ hasError && meta?.error }
                        />
                      </FieldWrapper>
                    );
                  } }
                </Field>
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <SelectField
                  name="requiredPpis"
                  label="Required Profile Properties"
                  multiple={ true }
                  options={ PPI_OPTIONS }
                  disabled={ disabled || allPpis }
                  required={ !(disabled || allPpis) }
                />
                <Toggle
                  name="allPpis"
                  label="All PPIs"
                  checked={ !requiredPpis?.length }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="attributes.collectionId"
                  label="Collection Id"
                  className={ styles.greyOut }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="attributes.studyId"
                  label="Study Id"
                  className={ styles.greyOut }
                />
              </Grid>

              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="attributes.clientName"
                  label="Client Name"
                  helperText={ helperText }
                  disabled={ (!createPage && true) || disabled }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <SelectField
                  name="organization"
                  label="Organization Association"
                  options={ ORGANIZATION_ASSOCIATION_OPTIONS }
                  disabled={
                    (!createPage && initialValues?.organization !== null) ||
                    disabled
                  }
                  message={
                    createPage
                      ? helperText
                      : !isEmpty(errors?.organization) && helperText
                  }
                  required={ requiredFields.organization }
                />
              </Grid>
              <Grid
                container={ true }
                direction="row"
                spacing={ 3 }
                className={ styles.fieldSetGrid }
              >
                <Grid item={ true } xs={ 9 }>
                  <Text font={ 'title-6' } as={ 'h6' }>
                    Participation
                  </Text>
                </Grid>
                <Grid item={ true } xs={ 3 }>
                  <></>
                </Grid>
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <SelectField
                  name="participationStatus"
                  label="Participation Status"
                  options={ PARTICIPATION_STATUS_OPTIONS }
                  disabled={ disabled }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="participantLimit"
                  label="Participant Limit"
                  disabled={ !!unlimitedParticipants }
                  type="number"
                  className={ unlimitedParticipants ? styles.greyOut : '' }
                  required={ !unlimitedParticipants }
                />
                <Grid container={ true } style={{ marginTop: '10px' }}>
                  <Grid item={ true } xs={ 6 }>
                    <Toggle name="unlimitedParticipants" label="Unlimited" />
                  </Grid>
                  <Grid item={ true } xs={ 6 } style={{ textAlign: 'right' }}>
                    Participant Count: { values?.participantCount || 0 } /{ ' ' }
                    { participantLimit || '∞' }
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container={ true }
                direction="row"
                spacing={ 3 }
                style={{ borderBottom: '1px solid #cccccc', margin: '12px 0' }}
              >
                <Grid item={ true } xs={ 9 }>
                  <Text font={ 'title-6' } as={ 'h6' }>
                    Consent
                  </Text>
                </Grid>
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <Toggle
                  name="isConsentRequired"
                  label="Requires Informed Consent Form"
                  checked={ isConsentRequired }
                />
              </Grid>
            </Grid>
          </>
        </fieldset>
        <div className={ styles.btns }>
          <Button variant="secondary" onClick={ handleCancel }>
            Cancel
          </Button>
          { !disabled ? (
            <Button
              variant="primary"
              disabled={ !isEmpty(errors) || !dirty || isEmpty(touched) }
              type="submit"
            >
              Submit
            </Button>
          ) : (
            <Button
              data-testid="editRoute"
              variant="secondary"
              onClick={ handleEditRoute }
            >
              Edit
            </Button>
          ) }
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) =>
        (isEmpty(initialValues) ? INITIAL_VALUES : initialValues),
      validationSchema,
      handleSubmit: (
        values,
        { setSubmitting, props: { onSubmit, viewMode }, resetForm }
      ) => {
        const request = pick(requestFields, values);

        const { handle } = request;
        //TODO use prefix based on init type here
        const digitalHandle =
          (viewMode === ViewMode.CREATE &&
            `${ initiativePrefix(values?.initiativeType) }-${ handle }`) ||
          handle;

        const modifiedRequest = { ...request, handle: digitalHandle };
        onSubmit(modifiedRequest);
        resetForm({ values });
        setSubmitting(false);
      },
      displayName: 'InitiativeForm',
    }),
    propTypes({
      id: PropTypes.string,
      handleReset: PropTypes.func,
      handleSubmit: PropTypes.func,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      statusConditions: PropTypes.array,
    }),
    defaultProps({
      handleReset: () => {},
      handleSubmit: () => {},
      requiredPPI: [],
      onSubmit: () => {},
    }),
    memo
  )
);
