import React, { useCallback } from 'react';
import { TextField } from '@nike/eds';
import { useField } from 'formik';
import styles from './index.module.scss';
import FieldWrapper from 'components/FieldWrapper';
import { textFormatter } from 'components/forms/utils';

const NikeTextField = ({
  label,
  className,
  maxLength,
  helperText,
  ...props
}) => {
  const [field, meta, { setError, setTouched }] = useField(props.name);

  const hasError = !!(meta.touched && meta.error);

  const handleBlur = useCallback((evt) => {
    field.onBlur(evt);
    setTouched(true);
  }, []);

  const handleChange = useCallback(
    (evt) => {
      const val = evt.target.value.length;
      if (val > maxLength) {
        // Stops input before it goes over limit
        return setError(`Max. Length: ${ maxLength }`);
      }
      setTouched(true);
      return field.onChange(evt);
    },
    [field.value]
  );

  const handleKeyDown = useCallback((evt) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      return;
    }
  }, []);

  return (
    <FieldWrapper disabled={ props.disabled }>
      <TextField
        { ...field }
        { ...props }
        id={ textFormatter(label) }
        value={ field.value || '' }
        label={
          <label>
            { label }
            { props.required && !hasError && <span className="asterisk">*</span> }
          </label>
        }
        className={ `${ styles.inputField } ${ className }` }
        maxLength={ maxLength }
        message={ helperText }
        maxChars={ maxLength }
        onKeyDown={ handleKeyDown }
        onChange={ handleChange }
        onBlur={ handleBlur }
        hasErrors={ hasError }
        errorMessage={ hasError && meta?.error }
        disabled={ props.disabled }
        data-testid={ `${ label }-input` }
      />
    </FieldWrapper>
  );
};

NikeTextField.propTypes = TextField.propTypes;

NikeTextField.defaultProps = {
  className: null,
  label: '',
  disabled: false,
};

export default NikeTextField;
