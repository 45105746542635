import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const SimpleDialog = ({
  onClose,
  open,
  onSubmit,
  title,
  description,
  confirmLabel,
  cancelLabel,
}) => {
  return (
    <Dialog
      onClose={ onClose }
      open={ open }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          { description }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onSubmit } variant="outlined">
          { confirmLabel }
        </Button>
        { cancelLabel && (
          <Button onClick={ onClose } variant="contained">
            { cancelLabel }
          </Button>
        ) }
      </DialogActions>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SimpleDialog;
