import { useEffect, useState } from 'react';
import { PermissionType } from 'lib/enums';
import { userInitiativePermissions } from 'components/RBAC';

const useUserPermissions = (initiativeHandle, entity) => {
  const [isEditAllowed, setIsEditAllowed] = useState(true);
  const [isCreateAllowed, setIsCreateAllowed] = useState(false);

  const userPermissions = userInitiativePermissions(initiativeHandle, entity);

  useEffect(() => {
    const hasOnlyViewPermission =
      userPermissions?.length === 1 &&
      userPermissions[0] === PermissionType.VIEW;
    setIsEditAllowed(!hasOnlyViewPermission);
  }, [userPermissions]);

  useEffect(() => {
    const hasCreatePermission = userPermissions?.includes(
      PermissionType.CREATE
    );
    setIsCreateAllowed(hasCreatePermission);
  }, [userPermissions]);

  return {
    isEditAllowed,
    isCreateAllowed,
  };
};

export default useUserPermissions;
