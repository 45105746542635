import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FormContainer } from 'components/FormContainer';
import { ViewMode } from 'lib/enums';
import styles from '../../../DevView/ConfigEdit/Edit/styles.module.scss';
import { useCreateConfigFieldMutation } from 'features/adminApi/endpoints/fields';
import { ConfigForm } from 'components/forms';
import { useScroll } from 'lib/util';
import { Text } from '@nike/eds';

const CreateConfigField = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { initiativeType, id: initiativeHandle } = useParams();
  const [postConfigField, configResult] = useCreateConfigFieldMutation();
  const [elRef] = useScroll();

  const handleCreateConfigField = useCallback(async (values) => {
    postConfigField({ initiativeHandle, ...values });
  }, []);

  useEffect(() => {
    if (configResult.error?.data.errorMessage) {
      addToast(configResult.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [configResult.error?.data.errorMessage]);

  useEffect(() => {
    if (configResult.isSuccess) {
      addToast('Initiative config field created', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push(`/initiative/${ initiativeType }/${ initiativeHandle }/fields`);
    }
  }, [configResult.isSuccess]);

  return (
    <>
      <FormContainer className={ styles.assetContainer }>
        <div className={ styles.assetContainer } ref={ elRef }>
          <Text as="h1" font="title-1" style={{ width: '80%' }}>
            Create Configuration Field
          </Text>
        </div>
        <ConfigForm
          viewMode={ ViewMode.CREATE }
          onSubmit={ handleCreateConfigField }
          isConfigFieldType={ true }
        />
      </FormContainer>
    </>
  );
};

export default CreateConfigField;
