import React, { memo, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { applyTo, pipe, isEmpty } from 'ramda';
import { withFormik } from 'formik';
import { JSONField } from 'components/fields';
import { useHistory } from 'react-router-dom';
import { propTypes, defaultProps } from 'lib/react';
import { INITIAL_VALUES, validationSchema } from './lib';
import styles from './index.module.scss';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import { trimAndReplace } from '../utils';
import { Button, Text } from '@nike/eds';
import { getRequiredFields } from 'utils';

export default applyTo(
  ({
    handleSubmit,
    errors,
    dirty,
    values,
    initialValues,
    setFieldValue,
    setFieldTouched,
  }) => {
    const history = useHistory();

    useEffect(() => {
      if (initialValues?.activeItems) {
        setFieldValue('value', { activeItems: [...initialValues.activeItems] });
        setFieldTouched('value', false);
      }
    }, [initialValues?.activeItems]);

    const handleChange = useCallback((event) => {
      setFieldValue('handle', trimAndReplace(event.target?.value));
    }, []);

    const handleCancel = useCallback((event) => {
      event.preventDefault();
      history.goBack();
    }, []);

    const requiredFields = getRequiredFields(validationSchema);

    return (
      <form onSubmit={ handleSubmit }>
        <fieldset>
          <>
            <Grid
              container={ true }
              direction="row"
              spacing={ 0 }
              className={ styles.fieldSetGrid }
            >
              <Grid
                container={ true }
                direction="column"
                item={ true }
                xs={ 12 }
                spacing={ 4 }
              >
                <Grid item={ true }>
                  <Text font="title-6" as="h6">
                    Properties
                  </Text>
                </Grid>
              </Grid>
            </Grid>
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <MaskedTextField
                  name="handle"
                  label="Handle"
                  onChange={ handleChange }
                  handleType={ 'ftr' }
                  value={ values?.handle }
                  disabled={ true }
                  className={ styles.greyOut }
                  required={ requiredFields.handle }
                />
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <JSONField
                  name="value"
                  label="JSON Value"
                  disabled={ false }
                  value={ values?.value }
                  required={ requiredFields.value }
                />
              </Grid>
            </Grid>
          </>
        </fieldset>
        <div className={ styles.btns }>
          <Button variant="secondary" onClick={ handleCancel }>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={ !isEmpty(errors) || !dirty }
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) =>
        (isEmpty(initialValues) ? INITIAL_VALUES : initialValues),
      validationSchema,
      handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
        const { handle, value } = values;
        onSubmit({
          handle,
          value,
        });
        setSubmitting(false);
      },
      displayName: () => 'Feature Flag Form',
    }),
    propTypes({
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
    }),
    defaultProps({
      initialValues: {},
      onSubmit: () => {},
    }),
    memo
  )
);
