import React, { useCallback } from 'react';
import { IconButton } from '@nike/eds';
import PropTypes from 'prop-types';

export const DeleteButton = ({
  id,
  disabled,
  fieldName,
  onChange,
  setTouched,
  answerOption,
  setAnswerOption,
}) => {
  const handleDeleteOption = useCallback(() => {
    const options = answerOption?.filter((item) => item.id !== id);
    setAnswerOption(options);
    setTouched(true);
    onChange({ target: { name: fieldName } }, options);
  }, [answerOption]);

  return (
    <IconButton
      id={ 'delete-' + id }
      icon={ 'Delete' }
      variant={ 'ghost' }
      size={ 'medium' }
      disabled={ disabled }
      onClick={ handleDeleteOption }
    />
  );
};

DeleteButton.propTypes = {
  answerOption: PropTypes.arrayOf(Object),
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  onChange: PropTypes.func,
  setAnswerOption: PropTypes.func,
  setTouched: PropTypes.func,
};
