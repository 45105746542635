import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@nike/eds';

const CtaButton = ({
  preview,
  text,
  icon,
  onClick = () => null,
  ...buttonProps
}) => {
  return (
    <div className="eds-spacing--mt-24">
      { icon ? (
        <IconButton icon={ icon } onClick={ onClick } />
      ) : (
        <Button
          disableRipple={ preview }
          variant="primary"
          type="button"
          { ...buttonProps }
        >
          { text }
        </Button>
      ) }
    </div>
  );
};

CtaButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  preview: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
};
export default CtaButton;
