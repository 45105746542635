import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Toggle as NikeToggle } from '@nike/eds';
import FieldWrapper from 'components/FieldWrapper';

const Toggle = ({ label, className, ...props }) => {
  const [field, , { setValue, setTouched }] = useField(props);

  const handleToggle = useCallback(() => {
    setValue(!field.value);
    setTouched(true);
  }, [setValue, setTouched, field.value]);

  const handleBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  return (
    <FieldWrapper>
      <NikeToggle
        { ...field }
        { ...props }
        checked={ !!field.value }
        label={ label }
        className={ className }
        onBlur={ handleBlur }
        onChange={ handleToggle }
      />
    </FieldWrapper>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

Toggle.defaultProps = {
  className: null,
  label: '',
};

export default Toggle;
