/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import { Button, Icon, Menu, MenuItem, Text } from '@nike/eds';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getContentOptions } from '../utils';

// eslint-disable-next-line max-statements
const CreateOptions = ({ assetType }) => {
  const options = getContentOptions(assetType);
  const history = useHistory();
  const { url } = useRouteMatch();
  const multipleOptions = options && options.length > 1;

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(!open), [open]);
  const handleClick = useCallback(
    (option) => {
      history.push({
        pathname: `${ url }/create/${ option }`,
        state: {
          prevUrl: history.location.pathname,
        },
      });
    },
    [history, url]
  );

  const withMenu = (children) =>
    (multipleOptions ? (
      <Menu
        onClickOutside={ handleOpen }
        isOpen={ open }
        bodySlot={ options.map((option) => {
          return (
            <MenuItem key={ option } onClick={ () => handleClick(option) }>
              <Text font="body-2">{ startCase(option) }</Text>
            </MenuItem>
          );
        }) }
      >
        { children }
      </Menu>
    ) : (
      children
    ));

  return withMenu(
    <Button
      onClick={ multipleOptions ? handleOpen : (_e) => handleClick(options[0]) }
      afterSlot={
        <Icon
          name={ multipleOptions ? (open ? 'CaretUp' : 'CaretDown') : 'Plus' }
        />
      }
    >
      Add { multipleOptions ? `${ assetType } card` : startCase(options[0]) }
    </Button>
  );
};

CreateOptions.propTypes = {
  assetType: PropTypes.string,
};

export default CreateOptions;
