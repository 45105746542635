// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe, isEmpty } from 'ramda';
import { withFormik } from 'formik';
import { TextField, SelectField } from 'components/fields';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import {
  REQUEST_MANNER_OPTIONS,
  INITIAL_VALUES,
  validationSchema,
  REQUEST_NATURE_OPTIONS,
  STATUS_OPTIONS,
} from './lib';
import styles from './index.module.scss';
import { ViewMode } from 'lib/enums';
import { Button } from '@nike/eds';
import { getRequiredFields } from 'utils';

export default applyTo(
  ({
    disabled,
    viewMode,
    handleSubmit,
    touched,
    errors,
    dirty,
    isInitialValid,
    submitText,
  }) => {
    const requiredFields = getRequiredFields(validationSchema);

    return (
      <form onSubmit={ handleSubmit }>
        <TextField
          name="upmId"
          label="Upm Id"
          disabled={
            viewMode === ViewMode.EDIT || viewMode === ViewMode.READ || disabled
          }
          required={ requiredFields.upmId }
        />
        <SelectField
          name="requestManner"
          label="Request Manner"
          options={ REQUEST_MANNER_OPTIONS }
          disabled={ viewMode === ViewMode.READ || disabled }
          required={ requiredFields.requestManner }
        />
        <SelectField
          name="requestNature"
          label="Request Nature"
          options={ REQUEST_NATURE_OPTIONS }
          disabled={ viewMode === ViewMode.READ || disabled }
          required={ requiredFields.requestNature }
        />
        <SelectField
          name="status"
          label="Status"
          options={ STATUS_OPTIONS }
          disabled={ viewMode === ViewMode.READ || disabled }
          required={ requiredFields.status }
        />
        <div className={ styles.btns }>
          <Button
            variant="primary"
            disabled={
              disabled ||
              !isEmpty(errors) ||
              (!dirty && viewMode !== ViewMode.READ)
            }
            type="submit"
          >
            { submitText }
          </Button>
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) =>
        (initialValues
          ? { ...INITIAL_VALUES, ...initialValues }
          : INITIAL_VALUES),

      validationSchema,

      handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
        onSubmit(values);
        setSubmitting(false);
      },

      displayName: 'DataRequestForm',
    }),
    propTypes({
      disabled: PropTypes.bool,
      handleReset: PropTypes.func,
      handleSubmit: PropTypes.func,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      submitText: PropTypes.string,
      readOnly: PropTypes.bool,
      viewMode: PropTypes.oneOf(
        Object.keys(ViewMode).map((key) => ViewMode[key])
      ),
    }),
    defaultProps({
      handleReset: () => {},
      handleSubmit: () => {},
      initialValues: null,
      onSubmit: () => {},
      submitText: 'Submit',
      viewMode: ViewMode.CREATE,
    }),
    memo
  )
);
