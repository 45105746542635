// modules
import React, { memo, useCallback } from 'react';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import Code from 'components/Code';
import config from 'conf';

// local
import styles from './Error.module.scss';

export default applyTo(
  ({ error, debug }) => {
    const {
      stack,
      message = 'Oops, something went wrong',
      // code = 'ERROR',
      name = 'Error',
    } = error || {};

    const history = useHistory();

    const handleRefresh = useCallback(() => history.go(0), [history]);
    const handleBack = useCallback(() => history.goBack(), [history]);

    return (
      <div className={ styles.wrapper }>
        <h2 className={ styles.header }>{ name }</h2>
        <Typography className={ styles.message }>{ message }</Typography>
        <div className={ styles.buttons }>
          <Button variant="outlined" onClick={ handleRefresh }>
            Refresh Page
          </Button>
          <Button variant="outlined" onClick={ handleBack }>
            { 'Back' }
          </Button>
        </div>
        <Code className={ styles.stack } inline={ false } hidden={ !debug || !stack }>
          { stack }
        </Code>
      </div>
    );
  },
  pipe(
    propTypes({
      debug: PropTypes.bool,
      error: PropTypes.shape({
        code: PropTypes.string,
        message: PropTypes.string,
        name: PropTypes.string,
        stack: PropTypes.string,
      }),
    }),
    defaultProps({
      debug: config.env === 'development',
      error: {},
    }),
    memo
  )
);
