import * as Yup from 'yup';
import { nonSpecialCharRegex, nonSpace, spacesNotAllowedError, requiredError, invalidCharacterError } from '../utils';
import { activitySurveyPrefix } from './data';

export const TYPE_OPTIONS = [
  {
    label: 'Qualtrics Survey',
    value: activitySurveyPrefix,
  },
];

export const POLL_STATUS_OPTIONS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Published',
    value: 'PUBLISHED',
  },
  {
    label: 'Unpublished',
    value: 'UNPUBLISHED',
  },
];

export const PARTICIPATION_STATUS_OPTIONS = [
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Full',
    value: 'FULL',
  },
];

export const PLATFORM_OPTIONS = [
  {
    label: 'Web',
    value: 'WEB',
  },
  {
    label: 'Mobile',
    value: 'MOBILE',
  },
];

export const PPI_OPTIONS = [
  {
    label: 'First Name',
    value: 'ppi-first-name',
  },
  {
    label: 'Last Name',
    value: 'ppi-last-name',
  },
  {
    label: 'Date of Birth',
    value: 'ppi-date-of-birth',
  },
  {
    label: 'Nike Affiliation',
    value: 'ppi-nike-affiliation',
  },
  {
    label: 'Email',
    value: 'ppi-email',
  },
  {
    label: 'Phone Number',
    value: 'ppi-phone-number',
  },
  {
    label: 'Located Near Portland',
    value: 'ppi-located-near-portland-metro',
  },
  {
    label: 'Height',
    value: 'ppi-height',
  },
  {
    label: 'Weight',
    value: 'ppi-weight',
  },
  {
    label: 'Gender Identity',
    value: 'ppi-gender-identity',
  },
  {
    label: 'Race',
    value: 'ppi-race',
  },
  {
    label: 'Sport Preferences',
    value: 'ppi-sport-preferences',
  },
  {
    label: 'Mens Shoe Size',
    value: 'ppi-mens-shoe-size',
  },
  {
    label: 'Womens Shoe Size',
    value: 'ppi-womens-shoe-size',
  },
  {
    label: 'Mens Top Size',
    value: 'ppi-mens-top-size',
  },
  {
    label: 'Mens Bottom Size',
    value: 'ppi-mens-bottom-size',
  },
  {
    label: 'Womens Top Size',
    value: 'ppi-womens-top-size',
  },
  {
    label: 'Womens Bottom Size',
    value: 'ppi-womens-bottom-size',
  },
];

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  handle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  shortHandle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .matches(nonSpace, spacesNotAllowedError)
    .required(requiredError),
  unlimitedParticipants: Yup.boolean(),
  platforms: Yup.array()
    .of(Yup.string())
    .min(1, requiredError)
    .required(requiredError),
  participantLimit: Yup.number()
    .when('unlimitedParticipants', {
      is: false,
      then: Yup.number().required(requiredError)
        .min(0),
    })
    .nullable(true),
  status: Yup.string().required(requiredError),
  participationStatus: Yup.string().required(requiredError),
  // TODO: better way to manage content?
  webThreadId: Yup.string().nullable(true),
  mobileThreadId: Yup.string().nullable(true),
  allPpis: Yup.boolean(),
  requiredPpis: Yup.array().of(Yup.string())
    .nullable(true),
  // ----- attributes (specific to polls) -----
  // TODO: See if we can pull this data from qualtrics.
  numberOfQuestions: Yup.number()
    .positive('Number of questions must be greater than 0')
    .required(requiredError)
    .min(0),
  timeToCompletion: Yup.number()
    .positive('Time to completion must be greater than 0')
    .required(requiredError)
    .min(0),
  description: Yup.string(),
  url: Yup.string().required(requiredError),
  surveyId: Yup.string(),
});

// Initiative model
// https://github.com/nike-l2w/initiative-api/blob/main/src/initiative/resources/models/InitiativeModel.js#L7
// this.id = { type: 'string' }
// this.handle = { type: 'string' }
// this.name = { type: 'string' }
// this.initiativeType = { type: 'string' }
// this.status = { type: 'string' }
// this.attributes = { type: 'json' }
// this.platforms = { type: 'array' }
// this.webThreadId = { type: 'string' }
// this.mobileThreadId = { type: 'string' }
// this.createTimestamp = { type: 'timestamp' }
// this.updateTimestamp = { type: 'timestamp' }

export const INITIAL_VALUES = {
  name: '',
  handle: '',
  shortHandle: '',
  initiativeType: activitySurveyPrefix,
  status: '',
  participationStatus: '',
  attributes: {},
  platforms: [],
  webThreadId: '',
  mobileThreadId: '',
  participantLimit: null,
  unlimitedParticipants: true,
};
