import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './FormContainer.module.scss';

export const FormContainer = ({ children, className }) => {
  return (
    <div className={ cx(styles.formContainer, className, styles[className]) }>
      { children }
    </div>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FormContainer.defaultProps = {
  children: null,
  className: null,
};

export default memo(FormContainer);
