import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';

const useInitTypeValidation = (initType) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { id, initiativeType: selectedInitiativeType, viewMode } = useParams();

  useEffect(() => {
    if (typeof initType === 'string' && selectedInitiativeType !== initType) {
      addToast(
        `Initiative Type Mismatch Detected: The specified init's initiative type 
        does not match the URL's init type.`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
      setTimeout(() => {
        history.push(`/initiative/${ initType }/${ viewMode }/${ id }`);
      }, 1000);
    }
  }, [initType, selectedInitiativeType, viewMode, id]);
};

export default useInitTypeValidation;

export const modifyDataBasedOnPreference = ({
  addIdsPreference = 'upmid',
  athleteids = [],
  upmids = [],
  ...rest
}) => {
  const { shouldAddAudienceMembers: _, ...remainingData } = rest;

  const modifiedData =
    addIdsPreference === 'athleteId'
      ? { athleteids, ...remainingData }
      : addIdsPreference === 'upmid'
        ? { upmids, ...remainingData }
        : { ...remainingData };

  return modifiedData;
};
