const genderIdentity = [
  {
    name: 'Man',
    value: 'MALE',
    sortOrder: 1,
  },
  {
    name: 'Woman',
    value: 'FEMALE',
    sortOrder: 2,
  },
  {
    name: 'Agender',
    value: 'TRANSGENDER',
    sortOrder: 3,
  },
  {
    name: 'Non binary',
    value: 'NON_BINARY',
    sortOrder: 4,
  },
  {
    name: 'Decline to state',
    value: 'PREFER_NOT_TO_ANSWER',
    sortOrder: 5,
  },
  {
    name: 'Other',
    value: 'OTHER',
    sortOrder: 6,
  },
];

const mensTopSizes = [
  { value: 'N/A', label: 'N/A' },
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'S TALL', label: 'S Tall' },
  { value: 'M', label: 'M' },
  { value: 'M TALL', label: 'M Tall' },
  { value: 'L', label: 'L' },
  { value: 'L TALL', label: 'L Tall' },
  { value: 'XL', label: 'XL' },
  { value: 'XL TALL', label: 'XL Tall' },
  { value: '2XL', label: '2XL' },
  { value: '2XL TALL', label: '2XL Tall' },
  { value: '3XL', label: '3XL' },
  { value: '3XL TALL', label: '3XL Tall' },
  { value: '4XL', label: '4XL' },
  { value: '4XL TALL', label: '4XL Tall' },
  { value: '5XL', label: '5XL' },
  { value: '5XL TALL', label: '5XL Tall' },
];

const mensBottomSizes = [
  { value: 'N/A', label: 'N/A' },
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'S TALL', label: 'S Tall' },
  { value: 'M', label: 'M' },
  { value: 'M TALL', label: 'M Tall' },
  { value: 'L', label: 'L' },
  { value: 'L TALL', label: 'L Tall' },
  { value: 'XL', label: 'XL' },
  { value: 'XL TALL', label: 'XL Tall' },
  { value: '2XL', label: '2XL' },
  { value: '2XL TALL', label: '2XL Tall' },
  { value: '3XL', label: '3XL' },
  { value: '3XL TALL', label: '3XL Tall' },
  { value: '4XL', label: '4XL' },
  { value: '4XL TALL', label: '4XL Tall' },
  { value: '5XL', label: '5XL' },
  { value: '5XL TALL', label: '5XL Tall' },
];

const womensTopSizes = [
  { value: 'N/A', label: 'N/A' },
  { value: 'XXS', label: 'XXS' },
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
  { value: '1X', label: '1X' },
  { value: '2X', label: '2X' },
  { value: '3X', label: '3X' },
  { value: '4X', label: '4X' },
  { value: '5X', label: '5X' },
];

const womensBottomSizes = [
  { value: 'N/A', label: 'N/A' },
  { value: 'XXS', label: 'XXS' },
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
  { value: '1X', label: '1X' },
  { value: '2X', label: '2X' },
  { value: '3X', label: '3X' },
  { value: '4X', label: '4X' },
  { value: '5X', label: '5X' },
];

const activityChoices = [
  { value: 'AMERICAN_FOOTBALL', label: 'American Football' },
  { value: 'BARRE', label: 'Barre' },
  { value: 'BASEBALL', label: 'Baseball' },
  { value: 'BASKETBALL', label: 'Basketball' },
  { value: 'BOXING', label: 'Boxing' },
  { value: 'CLIMBING', label: 'Climbing' },
  { value: 'CRICKET', label: 'Cricket' },
  { value: 'CYCLING', label: 'Cycling' },
  { value: 'DANCE', label: 'Dance' },
  { value: 'DISC_GOLF', label: 'Disc Golf' },
  { value: 'FENCING', label: 'Fencing' },
  { value: 'FIGURE_SKATING', label: 'Figure Skating' },
  { value: 'GOLF', label: 'Golf' },
  { value: 'GYMNASTICS', label: 'Gymnastics' },
  { value: 'HIKING', label: 'Hiking' },
  { value: 'HOCKEY', label: 'Hockey' },
  { value: 'LACROSSE', label: 'Lacrosse' },
  { value: 'MARTIAL_ARTS', label: 'Martial Arts' },
  { value: 'MEDITATION_MINDFULNESS', label: 'Meditation / Mindfulness' },
  { value: 'MIXED_MARTIAL_ARTS', label: 'Mixed Martial Arts' },
  { value: 'OTHER', label: 'Other' },
  { value: 'PICKLEBALL', label: 'Pickleball' },
  { value: 'PILATES', label: 'Pilates' },
  { value: 'RACQUETBALL', label: 'Racquetball' },
  { value: 'ROWING', label: 'Rowing' },
  { value: 'RUGBY', label: 'Rugby' },
  { value: 'RUNNING', label: 'Running' },
  { value: 'SKATEBOARDING', label: 'Skateboarding' },
  { value: 'SKIING', label: 'Skiing' },
  { value: 'SNOWBOARDING', label: 'Snowboarding' },
  { value: 'SOCCER_GLOBAL_FOOTBALL', label: 'Soccer (Global Football)' },
  { value: 'SOFTBALL', label: 'Softball' },
  { value: 'SPIN', label: 'Spin' },
  { value: 'STRETCHING', label: 'Stretching' },
  { value: 'SURFING', label: 'Surfing' },
  { value: 'SWIMMING', label: 'Swimming' },
  { value: 'TENNIS', label: 'Tennis' },
  { value: 'TRACK_AND_FIELD', label: 'Track And Field' },
  { value: 'TRAINING_GYM', label: 'Training / Gym' },
  { value: 'ULTIMATE_FRISBEE', label: 'Ultimate Frisbee' },
  { value: 'VOLLEYBALL', label: 'Volleyball' },
  { value: 'WALKING', label: 'Walking' },
  { value: 'WRESTLING', label: 'Wrestling' },
  { value: 'YOGA', label: 'Yoga' },
];

const portlandArea = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const shoeSizes = [
  { value: 'N/A', label: 'N/A' },
  { value: 'M 3 / W 4.5', label: 'M 3 / W 4.5' },
  { value: 'M 3.5 / W 5', label: 'M 3.5 / W 5' },
  { value: 'M 4 / W 5.5', label: 'M 4 / W 5.5' },
  { value: 'M 4.5 / W 6', label: 'M 4.5 / W 6' },
  { value: 'M 5 / W 6.5', label: 'M 5 / W 6.5' },
  { value: 'M 5.5 / W 7', label: 'M 5.5 / W 7' },
  { value: 'M 6 / W 7.5', label: 'M 6 / W 7.5' },
  { value: 'M 6.5 / W 8', label: 'M 6.5 / W 8' },
  { value: 'M 7 / W 8.5', label: 'M 7 / W 8.5' },
  { value: 'M 7.5 / W 9', label: 'M 7.5 / W 9' },
  { value: 'M 8 / W 9.5', label: 'M 8 / W 9.5' },
  { value: 'M 8.5 / W 10', label: 'M 8.5 / W 10' },
  { value: 'M 9 / W 10.5', label: 'M 9 / W 10.5' },
  { value: 'M 9.5 / W 11', label: 'M 9.5 / W 11' },
  { value: 'M 10 / W 11.5', label: 'M 10 / W 11.5' },
  { value: 'M 10.5 / W 12', label: 'M 10.5 / W 12' },
  { value: 'M 11 / W 12.5', label: 'M 11 / W 12.5' },
  { value: 'M 11.5 / W 13', label: 'M 11.5 / W 13' },
  { value: 'M 12 / W 13.5', label: 'M 12 / W 13.5' },
  { value: 'M 12.5 / W 14', label: 'M 12.5 / W 14' },
  { value: 'M 13 / W 14.5', label: 'M 13 / W 14.5' },
  { value: 'M 13.5 / W 15', label: 'M 13.5 / W 15' },
  { value: 'M 14 / W 15.5', label: 'M 14 / W 15.5' },
  { value: 'M 14.5 / W 16', label: 'M 14.5 / W 16' },
  { value: 'M 15 / W 16.5', label: 'M 15 / W 16.5' },
  { value: 'M 15.5 / W 17', label: 'M 15.5 / W 17' },
  { value: 'M 16 / W 17.5', label: 'M 16 / W 17.5' },
  { value: 'M 16.5 / W 18', label: 'M 16.5 / W 18' },
  { value: 'M 17 / W 18.5', label: 'M 17 / W 18.5' },
  { value: 'M 17.5 / W 19', label: 'M 17.5 / W 19' },
  { value: 'M 18 / W 19.5', label: 'M 18 / W 19.5' },
];

const nikeAffiliationChoices = [
  { value: 'EMPLOYEE', label: 'Employee' },
  { value: 'ETW_CONTRACTOR', label: 'ETW Contractor' },
  { value: 'INTERN', label: 'Intern' },
  { value: 'NONE', label: 'None' },
  { value: 'OTHER', label: 'Other' },
];

const raceChoices = [
  {
    value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    label: 'American Indian or Alaska Native',
  },
  { value: 'ASIAN', label: 'Asian' },
  { value: 'BLACK_OR_AFRICAN_AMERICAN', label: 'Black or African American' },
  { value: 'HISPANIC_OR_LATINO', label: 'Hispanic or Latino' },
  {
    value: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  { value: 'WHITE', label: 'White' },
  { value: 'PREFER_NOT_TO_ANSWER', label: 'Prefer not to Answer' },
];

export const weightOptions = (start, end) => {
  const values = Array(end - start + 1)
    .fill()
    .map((_, idx) => `${ start + idx }lbs`);
  return values.map((itm) => ({ value: itm, label: itm }));
};

export const heightOptions = (start, end) => {
  const feet = (idx) => Math.floor((start + idx) / 12);
  const inches = (idx) => start + idx - feet(idx) * 12;
  const values = Array(end - start + 1)
    .fill()
    .map((_, idx) => `${ feet(idx) }'${ inches(idx) }"`);
  return values.map((itm) => ({ value: itm, label: itm }));
};

export const ppiChoices = {
  heightOptions,
  weightOptions,
  raceChoices,
  nikeAffiliationChoices,
  shoeSizes,
  activityChoices,
  portlandArea,
  womensBottomSizes,
  womensTopSizes,
  mensBottomSizes,
  mensTopSizes,
  genderIdentity,
};
