// modules
import React, { useEffect, useMemo } from 'react';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import { ViewMode, EntityType } from 'lib/enums';
import { Typography } from '@material-ui/core';
import { useFetchConfigListQuery } from 'features/adminApi/endpoints/config';
import { useToasts } from 'react-toast-notifications';

const columns = [
  { field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
  { field: 'handle', headerName: 'Handle', flex: 1, minWidth: 200 },
];

const actions = [
  {
    key: 'id',
    url: '/dev/config/edit/',
    label: 'edit',
    action: ViewMode.EDIT,
    entity: EntityType.DEV_TOOLS,
  },
];

const ConfigList = () => {
  const { addToast } = useToasts();
  const { data, isLoading, isError } = useFetchConfigListQuery();

  useEffect(() => {
    if (isError) {
      addToast('Error occurred while fetching config-list', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError]);

  const items = useMemo(() => {
    if (data) {
      const modofiedData = data.map((item) => ({
        id: window?.crypto?.randomUUID(),
        ...item,
      }));
      return modofiedData;
    }
  }, [data]);

  if (isLoading) {
    return <Loading text="Fetching config list" />;
  }

  return (
    <>
      <Typography variant="h3">Config List</Typography>
      <DataTable
        loading={ isLoading }
        heading=""
        columns={ columns }
        actions={ actions }
        actionMenu={ false }
        rows={ items || [] }
      />
    </>
  );
};

export default ConfigList;
