/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { FormContainer } from 'components/FormContainer';
import { ConfigForm } from 'components/forms';
import { ViewMode } from 'lib/enums';
import { useScroll } from 'lib/util.js';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from '../../../DevView/ConfigEdit/Edit/styles.module.scss';
import {
  useFetchConfigFieldQuery,
  usePatchConfigFieldMutation,
} from 'features/adminApi/endpoints/fields';
import { Text } from '@nike/eds';

export const filter = (data, id) =>
  data?.find((initiative) => initiative.handle === id);

//eslint-disable-next-line max-statements
const EditConfigField = () => {
  const { id, handle, initiativeType } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();
  const [elRef] = useScroll();

  const { data: configFieldData, isFetching } = useFetchConfigFieldQuery({
    initiativeHandle: id,
    fieldHandle: handle,
  });

  const [usePatchConfigField, patchConfigFieldResult] =
    usePatchConfigFieldMutation();

  const handleFormSubmit = useCallback(async (values) => {
    const convertedvalues = {
      handle: values.handle,
      initiativeHandle: id,
      value: values.value,
      description: values.description,
    };
    usePatchConfigField(convertedvalues);
  }, []);

  useEffect(() => {
    if (patchConfigFieldResult.isSuccess) {
      addToast('Configuation Field Properties Saved', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push(`/initiative/${ initiativeType }/${ id }/fields`);
    }
  }, [history, patchConfigFieldResult.isSuccess]);

  useEffect(() => {
    if (patchConfigFieldResult?.error?.data?.errorMessage) {
      addToast(patchConfigFieldResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchConfigFieldResult?.error?.data?.errorMessage]);

  return (
    <>
      <FormContainer className={ styles.assetContainer }>
        <div className={ styles.assetContainer } ref={ elRef }>
          <Text as="h1" font="title-1" style={{ width: '80%' }}>
            Edit Configuration Field { handle }
          </Text>
        </div>
        { !isFetching && (
          <ConfigForm
            disabled={ isFetching }
            viewMode={ ViewMode.EDIT }
            onSubmit={ handleFormSubmit }
            initialValues={ configFieldData }
            isConfigFieldType={ true }
          />
        ) }
      </FormContainer>
    </>
  );
};

export default EditConfigField;
