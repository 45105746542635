import React, { Fragment, useCallback, useState } from 'react';
import { Link, Modal, TableCell, Text } from '@nike/eds';
import { TableComponent } from './FormatsTable';
import styles from './index.module.scss';
import { dateFormats, headers, stringFormats } from './utils';
export const GuideModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setModalOpen((prevState) => !prevState);
  }, []);

  const copyToClipboardHandler = async (event, text, formatType) => {
    event.stopPropagation();
    event.preventDefault();

    const formattedText =
      formatType === 'date'
        ? `{{dateFormat activityTime "${ text }"}}`
        : `{{${ text } activityType}}`;

    try {
      await navigator.clipboard.writeText(formattedText);
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  const generateRows = (items, formatType) => {
    return items.map((item, idx) => (
      <tr key={ idx }>
        <TableCell key={ `format-${ idx }` }>
          <Text>{ item.format }</Text>
        </TableCell>
        <TableCell key={ `example-${ idx }` }>
          <Text>{ item.example }</Text>
        </TableCell>
        <TableCell key={ `action-${ idx }` }>
          <Link
            as="button"
            variant="secondary"
            onClick={ useCallback((event) => {
              copyToClipboardHandler(event, item.format, formatType);
            }, []) }
            className={ styles.copyButton }
          >
            Copy to clipboard
          </Link>
        </TableCell>
      </tr>
    ));
  };

  return (
    <Fragment>
      <Text
        font="legal-1"
        className={ `eds-color--text-secondary ${ styles.guideLink }` }
      >
        { 'Note: This field supports custom variables. ' }
        <Link onClick={ toggleModal }>{ 'Click here for usage guidelines.' }</Link>
      </Text>
      <Modal
        isOpen={ modalOpen }
        hideFade={ true }
        onDismiss={ toggleModal }
        headerSlot={
          <Text font="title-3" as="h3">
            Guide
          </Text>
        }
        className={ styles.guideModal }
        displayCheckOverride={ true }
      >
        <div>
          <div className="eds-spacing--mb-24">
            <Text font="subtitle-1" className="eds-spacing--mb-12">
              Using Custom Variables:
            </Text>
            <Text font="body-3" className={ styles.customVariablesBody }>
              { `To use custom variables in your text, you can include them in double curly braces \`{{}}\`.\n
                 To include a variable, such as activityType, without formatting, you can use:` }
              <div className="eds-spacing--pb-16 eds-spacing--pt-16">
                <strong>{ '{{activityType}}\n' }</strong>
              </div>
              {
                'To include the activity time with a specific format, you can use:\n'
              }
              <div className="eds-spacing--pb-16 eds-spacing--pt-16">
                <strong>
                  { '{{dateFormat activityTime "MMMM d, yyyy h aaa"}}\n' }
                </strong>
              </div>
              {
                'These will be replaced with the formatted values when the Custom Variables are processed in the backend'
              }
            </Text>
          </div>
          <Text font="subtitle-1" className="eds-spacing--mb-24">
            {
              'Below are some available formats and examples of how to use custom variables in your text:'
            }
          </Text>
          <Text font="title-4" className="eds-spacing--mb-16">
            { 'Date Formats:' }
          </Text>
          <TableComponent
            headers={ headers }
            rows={ generateRows(dateFormats, 'date') }
          />
          <Text
            font="title-4"
            className="eds-spacing--mb-16 eds-spacing--mt-24"
          >
            { 'String Formats:' }
          </Text>
          <TableComponent
            headers={ headers }
            rows={ generateRows(stringFormats, 'string') }
          />
        </div>
      </Modal>
    </Fragment>
  );
};
