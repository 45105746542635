import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const adminApiSliceWithTags = adminApiSlice.enhanceEndpoints({
  addTagTypes: ['audienceInitiatives'],
});

const audiencesApiSlice = adminApiSliceWithTags.injectEndpoints({
  endpoints(builder) {
    return {
      fetchAudienceInitiatives: builder.query({
        query(audienceHandle) {
          return `/audiences/${ audienceHandle }/initiatives`;
        },
        providesTags: (result, error, audienceHandle) => [
          { type: 'audienceInitiatives', id: audienceHandle },
        ],
        transformResponse: (response) =>
          pathOr([], ['initiatives'], response).map(({ handle }) => handle),
      }),
      createAudienceInitiatives: builder.mutation({
        query: ({ audienceHandle, initiativeHandle }) => ({
          url: `/audiences/${ audienceHandle }/initiatives`,
          method: 'POST',
          body: { initiativeHandle },
        }),
        invalidatesTags: (result, error, { audienceHandle }) => [
          { type: 'audienceInitiatives', id: audienceHandle },
          { type: 'audiencesForInitiative', id: 'LIST' },
        ],
      }),
      deleteAudienceInitiatives: builder.mutation({
        query: ({ audienceHandle, initiativeHandle }) => ({
          url: `/audiences/${ audienceHandle }/initiatives/${ initiativeHandle }`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { audienceHandle }) => [
          { type: 'audienceInitiatives', id: audienceHandle },
          { type: 'audiencesForInitiative', id: 'LIST' },
        ],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchAudienceInitiativesQuery,
  useCreateAudienceInitiativesMutation,
  useDeleteAudienceInitiativesMutation,
} = audiencesApiSlice;
