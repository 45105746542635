/* eslint-disable max-statements */
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { userEntityPermissions } from 'components/RBAC';
import { getEntitySubtype } from 'lib/auth.js';
import NoAccess from 'components/NoAccess';
import Loading from 'components/Loading';
const mainLayoutRoute = true;

function withRolePermissions(Component) {
  const ProtectedRoute = (props) => {
    const { type, entity } = useParams();

    const location = useLocation();
    const locationPath = location.pathname.split('/')[1];

    const entityType = type
      ? getEntitySubtype(type)
      : entity
        ? entity
        : locationPath;

    const { isLoading, hasAnyPermissionsPerEntity: userIsAllowedInEntity } =
      userEntityPermissions(entityType, mainLayoutRoute);

    if (isLoading) {
      return <Loading text="Checking permissions" />;
    }

    if (!userIsAllowedInEntity) {
      return <NoAccess />;
    }

    return <Component { ...props } />;
  };

  return ProtectedRoute;
}

export default withRolePermissions;
