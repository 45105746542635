import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const surveyApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['survey'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchSurvey: builder.query({
          query(initiativeHandle) {
            return `/initiatives/${ initiativeHandle }/inquiries?inquiryType=survey`;
          },
          providesTags: (result = []) => [
            'survey',
            ...result.map(({ id }) => ({ type: 'survey', id })),
          ],
          transformResponse: (response, _, status) =>
            pathOr([], ['inquiries'], response),
        }),
        fetchSurveyById: builder.query({
          query(payload) {
            const { id, initiativeHandle } = payload;
            return `/initiatives/${ initiativeHandle }/inquiries/${ id }`;
          },
          providesTags: (_result, _error, { id }) => {
            return [{ type: 'survey', id }];
          },
          transformResponse: (response, _, _status) => response,
        }),
        fetchSurveyAnswers: builder.query({
          query: (body) => {
            return {
              url: `/initiatives/${ body?.id }/inquiries/${ body?.inquiryHandle }/answers`,
              method: 'GET',
              responseHandler: async (response) => response.blob(),
              cache: 'no-cache',
            };
          },
          providesTags: (_result, _error, id) => [{ type: 'survey', _result }],
          transformResponse: (response, _, status) => response,
        }),
        patchSurvey: builder.mutation({
          query: (body) => {
            return {
              url: `/initiatives/${ body?.initiativeHandle }/inquiries/${ body?.id }`,
              method: 'PATCH',
              body,
            };
          },
          invalidatesTags: (result, error, { id }) => {
            return [{ type: 'survey', id }];
          },
          transformResponse: (response, _, status) => {
            return response;
          },
        }),
        createSurvey: builder.mutation({
          query: (body) => ({
            url: `/initiatives/${ body.initiativeHandle }/inquiries`,
            method: 'POST',
            body,
          }),
          invalidatesTags: (result, error, { id }) => {
            return [
              { type: 'survey', id: 'LIST' },
              { type: 'survey', id },
              'survey',
            ];
          },
        }),
      };
    },
  });

export const {
  useFetchSurveyQuery,
  useFetchSurveyByIdQuery,
  useFetchSurveyAnswersQuery,
  usePatchSurveyMutation,
  useCreateSurveyMutation,
} = surveyApiSlice;
