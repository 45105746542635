import * as Yup from 'yup';
import { requiredError } from '../utils';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required(requiredError),
  autoDelete: Yup.bool(),
});

export const INITIAL_VALUES = {
  email: '',
  autoDelete: false,
};
