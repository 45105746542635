import styled from '@emotion/styled';
import { Toggle } from '@nike/eds';

export const ToggleField = styled(Toggle)`
  &.eds-toggle--disabled {
    color: : ${ (props) =>
    (props.disabledstyle === 'true'
      ? 'var(--eds-color-black) !important'
      : 'var(--eds-color-grey-2)') };
  }

  &.eds-toggle .eds-toggle__input:disabled:checked~span {
    background-color: ${ (props) =>
    (props.disabledstyle === 'true'
      ? 'var(--eds-color-background-success) !important'
      : 'var(--eds-color-green-10)') };
    }`;
