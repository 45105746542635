import {
  CONTENT_CONSTANTS,
  CONSENT_FORM,
  SCREENER_FORM,
  SURVEY_FORM,
} from 'lib/layoutUtils';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { PERMISSIONS_BY_ROLES_CONFIG } from 'lib/configs';

import { EntityType } from 'lib/enums';

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((str) => {
          return `%00${ str.charCodeAt(0).toString(16) }`.slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return {};
  }
};

export const hasCodeInUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Boolean(searchParams.get('code'));
};

export const getEntitySubtype = (type) => {
  if (CONTENT_CONSTANTS.includes(type)) {
    return EntityType.ASSET_CONTENT;
  } else if (CONSENT_FORM.includes(type)) {
    return EntityType.ASSET_CONSENT;
  } else if (SCREENER_FORM.includes(type)) {
    return EntityType.ASSET_SCREENER;
  } else if (SURVEY_FORM.includes(type)) {
    return EntityType.ASSET_SURVEY;
  }
};

export const permissionsByRole = () => {
  const { data, isFetching } = useFetchConfigQuery(PERMISSIONS_BY_ROLES_CONFIG);
  return {
    isFetching,
    data: data?.value,
  };
};
