import { Typography } from '@material-ui/core';
import { FormContainer } from 'components/FormContainer';
import { AudienceForm, FormSkeleton } from 'components/forms';
import {
  arrayOf,
  bool,
  element,
  func,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import { INITIAL_VALUES } from 'components/forms/Audience/lib';

const AudienceFormWrapper = (props) => {
  const { dialogs, title, ...formProps } = props;
  return (
    <FormContainer>
      { dialogs && dialogs }
      <Typography variant="h1">{ title } </Typography>

      { !formProps.isLoading ? (
        <AudienceForm { ...formProps } />
      ) : (
        <FormSkeleton fields={ INITIAL_VALUES } />
      ) }
    </FormContainer>
  );
};

AudienceFormWrapper.propTypes = {
  dialogs: oneOfType([element, arrayOf(element)]),
  disabled: bool,
  hasUsers: bool,
  initialValues: shape({}),
  isLoading: bool,
  onCancel: func,
  onSubmit: func,
  onTagAdd: arrayOf(string),
  submitText: string,
  title: string,
  viewMode: string,
};

export default AudienceFormWrapper;
