import { adminApiSlice } from '../adminApiSlice';

const configFieldsApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['config-fields'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchConfigFields: builder.query({
          query(initiativeHandle) {
            return `/initiatives/${ initiativeHandle }/fields`;
          },
          providesTags: (_result, _error) => [
            { type: 'config-fields', id: 'LIST' },
          ],
          transformResponse: (response, _, _status) => {
            return response;
          },
        }),
        fetchConfigField: builder.query({
          query(params) {
            return `/initiatives/${ params.initiativeHandle }/fields/${ params.fieldHandle }`;
          },
          providesTags: (_result, _error, { fieldHandle }) => [
            { type: 'config-field', id: fieldHandle },
          ],
          transformResponse: (response, _, _status) => {
            return response;
          },
        }),
        patchConfigField: builder.mutation({
          query: (body) => {
            return {
              url: `/initiatives/${ body.initiativeHandle }/fields/${ body.handle }`,
              method: 'PATCH',
              body,
            };
          },
          invalidatesTags: (_result, _error, { handle }) => {
            return [{ type: 'config-fields', id: handle }];
          },
          transformResponse: (response, _, _status) => {
            return response;
          },
        }),
        createConfigField: builder.mutation({
          query: (body) => ({
            url: `/initiatives/${ body.initiativeHandle }/fields`,
            method: 'POST',
            body,
          }),
          invalidatesTags: (_response, _error, _) => [
            { type: 'config-fields', id: 'LIST' },
          ],
        }),
        deleteConfigField: builder.mutation({
          query: (body) => {
            return {
              url: `/initiatives/${ body.initiativeHandle }/fields/${ body.handle }`,
              method: 'DELETE',
            };
          },
          invalidatesTags: () => [{ type: 'config-fields', id: 'LIST' }],
        }),
      };
    },
  });

export const {
  useFetchConfigFieldsQuery,
  useFetchConfigFieldQuery,
  usePatchConfigFieldMutation,
  useCreateConfigFieldMutation,
  useDeleteConfigFieldMutation,
} = configFieldsApiSlice;
