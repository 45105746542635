// modules
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// aliased
import { PollForm } from 'components/forms';
import { FormContainer } from 'components/FormContainer';
import { useFetchPollQuery } from 'features/adminApi/endpoints/polls';
import { ViewMode } from 'lib/enums';

// local
import { parsePoll } from '../../lib';
import Loading from 'components/Loading';
import { Text } from '@nike/eds';

const ViewPoll = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data = [], isLoading } = useFetchPollQuery(id);

  const handleEditPoll = useCallback(async () => {
    history.push(`/initiative/polls/edit/${ id }`);
  }, [id]);

  const pollData = useMemo(() => parsePoll(data), [data]);

  if (isLoading) {
    return <Loading text="Fetching poll details" />;
  }

  return (
    <FormContainer>
      <Text font={ 'title-1' } as={ 'h1' }>
        Poll Details
      </Text>
      <PollForm
        key={ `poll_${ id }_${ isLoading }` }
        onSubmit={ handleEditPoll }
        disabled={ isLoading }
        initialValues={ pollData }
        viewMode={ ViewMode.READ }
      />
    </FormContainer>
  );
};

export default ViewPoll;
