import React, { useCallback } from 'react';
import { Toggle } from '@nike/eds';
import PropTypes from 'prop-types';

export const ToggleButton = ({
  id,
  isReject,
  disabled,
  answerOption,
  fieldName,
  setAnswerOption,
  onChange,
}) => {
  const handleOptionToggle = useCallback(
    (event) => {
      const options = answerOption?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isReject: !item.isReject,
          };
        }
        return item;
      });

      setAnswerOption(options);
      onChange({ target: { name: fieldName } }, options);
    },
    [answerOption]
  );

  return (
    <Toggle
      id={ 'toggle-' + id }
      checked={ !isReject }
      onClick={ handleOptionToggle }
      disabled={ disabled }
    />
  );
};

ToggleButton.propTypes = {
  answerOption: PropTypes.arrayOf(Object),
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  isReject: PropTypes.bool,
  onChange: PropTypes.func,
  setAnswerOption: PropTypes.func,
};
