import { activitySurveyPrefix } from 'components/forms/Poll/data';
import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const filter = ({ initiativeType }) => initiativeType === activitySurveyPrefix;

const adminApiSliceWithTags = adminApiSlice.enhanceEndpoints({
  addTagTypes: ['polls'],
});

const pollsApiSlice = adminApiSliceWithTags.injectEndpoints({
  endpoints(builder) {
    return {
      fetchPolls: builder.query({
        query() {
          return '/initiatives';
        },
        providesTags: (result) => {
          return result
            ? [
              ...result
                .filter(filter)
                .map(({ handle }) => ({ type: 'polls', handle })),
              { type: 'polls', id: 'LIST' },
            ]
            : [{ type: 'polls', id: 'LIST' }];
        },
        transformResponse: (response, _, status) =>
          pathOr([], ['initiatives'], response).filter(filter),
      }),
      fetchPoll: builder.query({
        query(handle) {
          return `/initiatives/${ handle }`;
        },
        providesTags: (result, error, handle) => [
          { type: 'polls', id: handle },
        ],
        transformResponse: (response) => response,
      }),
      createPoll: builder.mutation({
        query: (body) => ({
          url: '/initiatives',
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'polls', id: 'LIST' }],
      }),
      editPoll: builder.mutation({
        query: (body) => ({
          url: `/initiatives/${ body.handle }`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: (result, error, { handle }) => [
          { type: 'polls', id: 'LIST' },
          { type: 'polls', id: handle },
        ],
      }),
      deletePoll: builder.mutation({
        query: (handle) => ({
          url: `/initiatives/${ handle }`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: 'polls', id: 'LIST' }],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchPollQuery,
  useFetchPollsQuery,
  useEditPollMutation,
  useCreatePollMutation,
  useDeletePollMutation,
} = pollsApiSlice;
