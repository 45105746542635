import styled from '@emotion/styled';
import { displayCenter } from 'components/CardLayout/styles';
import { BreakPointEnum } from 'lib/enums';

export const LayoutContainer = styled.div`
  ${ ({ isPreviewMode }) =>
    isPreviewMode &&
    `.eds-vertical-navbar {
    background: #efefef;
  }
  .eds-vertical-navbar__strip {
    display: none;
  }` }
  .eds-vertical-navbar__strip {
    width: 104px;
  }
  .eds-vertical-navbar__strip ul li:nth-of-type(2) {
    visibility: hidden;
  }

  .eds-vertical-navbar__panel ul li:nth-of-type(2) {
    visibility: unset;
  }
  .eds-vertical-navbar .eds-icon-button--active {
    color: #111;
  }
`;

export const GlobalLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 104px auto;
  grid-gap: 0px;
`;

export const RightLayoutContainer = styled.div`
  background: #efefef;
  height: 100vh;
  overflow-y: auto;
  padding: 40px 56px;
  overflow-x: auto;
`;

export const BreadcrumbsMenuContainer = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  ${ displayCenter };
  justify-content: space-between;
  @media (max-width: ${ BreakPointEnum.breakpointM }) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MenuHeaderSlotsStyled = styled.div`
  ${ displayCenter };
  width: 435px;
  gap: 16px;
  cursor: pointer;
  @media (max-width: ${ BreakPointEnum.breakpointM }) {
    padding: 12px 24px;
  }
  @media (max-width: ${ BreakPointEnum.breakpointS }) {
    padding: 12px 16px;
  }
`;

export const MenuHeaderAvatarStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
