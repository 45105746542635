import * as Yup from 'yup';
import { invalidCharacterError, nonSpecialCharRegex, requiredError } from '../utils';

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const upmidRegex = /^[0-9]{8,11}$/i; //9, 10 or 11 digit number (older format)

const validateIdsSchema = (idType) =>
  Yup.array()
    .of(
      Yup.string().test(
        'validate id format',
        'One or more ids are not in correct format.',
        (value) => uuidRegex.test(value) || upmidRegex.test(value)
      )
    )
    .when(
      [
        'shouldAddAudienceMembers',
        'audienceType',
        'addIdsPreference',
        'showAddMembers',
      ],
      {
        is: (
          shouldAddAudienceMembers,
          audienceType,
          addIdsPreference,
          showAddMembers
        ) =>
          (showAddMembers || shouldAddAudienceMembers) &&
          addIdsPreference === idType &&
          audienceType === 'Static',
        then: Yup.array().min(1)
          .required(requiredError),
        else: Yup.array().nullable(),
      }
    )
    .nullable();

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  handle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .required(requiredError),
  description: Yup.string().nullable()
    .required(requiredError),
  status: Yup.string().oneOf(['active', 'inactive'])
    .required(requiredError),
  audienceType: Yup.string().oneOf(['Dynamic', 'Static'])
    .required(requiredError),
  ppiDefinition: Yup.array()
    .when('audienceType', {
      is: 'Dynamic',
      then: Yup.array()
        .of(
          Yup.object().shape({
            handle: Yup.string().required(requiredError),
            operator: Yup.string().required(requiredError),
            value: Yup.mixed()
              .test(
                'positive-number',
                'Value must be a positive number',
                (value, context) => {
                  const { handle } = context.parent;
                  if (handle === 'ppi-height' || handle === 'ppi-weight') {
                    return value && !isNaN(value) && value > 0;
                  }
                  return true;
                }
              )
              .required(requiredError),
          })
        )
        .min(1)
        .required(requiredError),
      otherwise: Yup.array().default([]),
    })
    .nullable(),
  upmids: validateIdsSchema('upmid'),
  athleteids: validateIdsSchema('athleteId'),
});

export const INITIAL_VALUES = {
  name: '',
  handle: '',
  description: '',
  status: 'active',
  audienceType: '',
  upmids: [],
  athleteids: [],
  addIdsPreference: 'upmid',
  shouldAddAudienceMembers: false,
  showAddMembers: false,
  ppiDefinition: [
    {
      handle: '',
      value: '',
      operator: '',
    },
  ],
};

export const selectOptions = [
  {
    label: 'Dynamic',
    value: 'Dynamic',
  },
  {
    label: 'Static',
    value: 'Static',
  },
];

export const selectInitAudienceOptions = [
  {
    label: 'Initiative Audience',
    value: 'Static',
  },
];

export const profilePropertiesSelectOptions = [
  {
    label: 'Gender Identity',
    value: 'ppi-gender-identity',
  },
  {
    label: 'Sport Preferences',
    value: 'ppi-sport-preferences',
  },
  {
    label: 'Weight',
    value: 'ppi-weight',
  },
  {
    label: 'Height',
    value: 'ppi-height',
  },
  {
    label: 'Located Near Portland Metro',
    value: 'ppi-located-near-portland-metro',
  },
];

const audienceProfileProperties = [
  {
    name: 'ppi-gender-identity',
    values: {
      type: 'select',
      options: [
        {
          label: 'MALE',
          value: 'MALE',
        },
        {
          label: 'FEMALE',
          value: 'FEMALE',
        },
        {
          label: 'OTHER',
          value: 'OTHER',
        },
        {
          label: 'PREFER NOT TO ANSWER',
          value: 'PREFER_NOT_TO_ANSWER',
        },
        {
          label: 'NON BINARY',
          value: 'NON_BINARY',
        },
        {
          label: 'TRANSGENDER',
          value: 'TRANSGENDER',
        },
      ],
    },
    operators: {
      type: 'select',
      options: [
        {
          label: 'IN',
          value: 'in',
        },
      ],
    },
  },
  {
    name: 'ppi-sport-preferences',
    values: {
      type: 'select',
      options: [
        {
          label: 'RUNNING',
          value: 'RUNNING',
        },
        {
          label: 'AMERICAN FOOTBALL',
          value: 'AMERICAN_FOOTBALL',
        },
        {
          label: 'YOGA',
          value: 'YOGA',
        },
        {
          label: 'DANCE',
          value: 'DANCE',
        },
        {
          label: 'BASKETBALL',
          value: 'BASKETBALL',
        },
        {
          label: 'BASEBALL',
          value: 'BASEBALL',
        },
        {
          label: 'SOCCER GLOBAL FOOTBALL',
          value: 'SOCCER_GLOBAL_FOOTBALL',
        },
        {
          label: 'SOFTBALL',
          value: 'SOFTBALL',
        },
        {
          label: 'TRAINING GYM',
          value: 'TRAINING_GYM',
        },
      ],
    },
    operators: {
      type: 'select',
      options: [
        {
          label: 'IN',
          value: 'in',
        },
      ],
    },
  },
  {
    name: 'ppi-weight',
    values: {
      type: 'input-number',
      range: {
        min: 100,
        max: 450,
      },
    },
    operators: {
      type: 'select',
      options: [
        {
          label: '=',
          value: '=',
        },
        {
          label: '<',
          value: '<',
        },
        {
          label: '>',
          value: '>',
        },
      ],
    },
  },
  {
    name: 'ppi-height',
    values: {
      type: 'input-number',
      range: {
        min: 2,
        max: 10,
      },
    },
    operators: {
      type: 'select',
      options: [
        {
          label: '=',
          value: '=',
        },
        {
          label: '<',
          value: '<',
        },
        {
          label: '>',
          value: '>',
        },
      ],
    },
  },
  {
    name: 'ppi-located-near-portland-metro',
    values: {
      type: 'select',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    operators: {
      type: 'select',
      options: [
        {
          label: '=',
          value: '=',
        },
      ],
    },
  },
];

export const getAudienceProfilePropertyValueOptions = (propertyName) => {
  const profileProperty = audienceProfileProperties.find(
    (profileProperty) => profileProperty.name === propertyName
  );
  return profileProperty?.values;
};

export const getAudienceProfilePropertyOperatorOptions = (propertyName) => {
  const profileProperty = audienceProfileProperties.find(
    (profileProperty) => profileProperty.name === propertyName
  );
  return profileProperty?.operators;
};
