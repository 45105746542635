import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const adminApiSliceWithTags = adminApiSlice.enhanceEndpoints({
  addTagTypes: [
    'initiativeAudience',
    'initiativeAudiences',
    'initiativeAudienceUser',
  ],
});

const initiativeAudiencesApiSlice = adminApiSliceWithTags.injectEndpoints({
  endpoints(builder) {
    return {
      fetchInitiativeAudiences: builder.query({
        query(initiativeHandle) {
          return `/initiatives/${ initiativeHandle }/initiativeAudiences`;
        },
        providesTags: () => [{ type: 'initiativeAudiences', id: 'LIST' }],
        transformResponse: (response) =>
          pathOr([], ['initiativeAudiences'], response),
      }),
      fetchInitiativeAudience: builder.query({
        query({ initiativeHandle, audienceHandle }) {
          return `/initiatives/${ initiativeHandle }/initiativeAudiences/${ audienceHandle }`;
        },
        providesTags: (_result, _error, { audienceHandle }) => [
          { type: 'initiativeAudience', id: audienceHandle },
        ],
      }),
      fetchInitiativeAudienceUsers: builder.query({
        query({ initiativeHandle, audienceHandle }) {
          return `/initiatives/${ initiativeHandle }/initiativeAudiences/${ audienceHandle }/users`;
        },
        providesTags: (result, _error, _) =>
          (result
            ? [
              ...result?.users.map(({ upmId }) => ({
                type: 'initiativeAudienceUser',
                id: upmId,
              })),
              'initiativeAudienceUser',
            ]
            : ['initiativeAudienceUser']),
      }),
      deleteInitiativeAudienceUser: builder.mutation({
        query: ({ initiativeHandle, audienceHandle, upmId }) => {
          return {
            url: `/initiatives/${ initiativeHandle }/initiativeAudiences/${ audienceHandle }/users/${ upmId }`,
            method: 'DELETE',
          };
        },
        invalidatesTags: (result, _error, { upmId }) => [
          { type: 'initiativeAudienceUser', id: upmId },
        ],
      }),
      createInitiativeAudience: builder.mutation({
        query: (body) => ({
          url: `/initiatives/${ body.initiativeHandle }/initiativeAudiences`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [
          { type: 'initiativeAudiences', id: 'LIST' },
          { type: 'audiences', id: 'LIST' },
        ],
      }),
      patchInitiativeAudience: builder.mutation({
        query: (body) => {
          return {
            url: `/initiatives/${ body.initiativeHandle }/initiativeAudiences/${ body.audienceHandle }`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: (_result, _error, { audienceHandle, upmId }) => [
          { type: 'initiativeAudiences', id: 'LIST' },
          { type: 'initiativeAudience', id: audienceHandle },
          { type: 'initiativeAudienceUser', id: upmId },
        ],
      }),
      createInitiativeAudienceMember: builder.mutation({
        query: (body) => ({
          url: `/initiatives/${ body.initiativeHandle }/initiativeAudiences/${ body.audienceHandle }/users`,
          method: 'POST',
          body: { upmId: body.upmId },
        }),
        invalidatesTags: [{ type: 'initiativeAudiences', id: 'LIST' }],
      }),
      createInitiativeAudienceMemberWithAthleteId: builder.mutation({
        query: (body) => ({
          url: `/initiatives/${ body.initiativeHandle }/initiativeAudiences/${ body.audienceHandle }/athletes`,
          method: 'POST',
          body: { athleteId: body.athleteId },
        }),
        invalidatesTags: [{ type: 'initiativeAudiences', id: 'LIST' }],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchInitiativeAudiencesQuery,
  useFetchInitiativeAudienceQuery,
  useFetchInitiativeAudienceUsersQuery,
  useDeleteInitiativeAudienceUserMutation,
  useCreateInitiativeAudienceMutation,
  usePatchInitiativeAudienceMutation,
  useCreateInitiativeAudienceMemberMutation,
  useCreateInitiativeAudienceMemberWithAthleteIdMutation:
    useCreateInitAudienceMemberWithAthleteIdMutation,
} = initiativeAudiencesApiSlice;
