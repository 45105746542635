/* eslint-disable max-statements */
// modules
import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

// aliased
import { DataRequestForm } from 'components/forms';
import { FormContainer } from 'components/FormContainer';
import SimpleDialog from 'components/SimpleDialog';

//
import {
  useFetchDataRequestQuery,
  usePatchDataRequestMutation,
} from 'features/adminApi/endpoints/dataRequests';
import { ViewMode } from 'lib/enums';
import { Text } from '@nike/eds';

const CreateDeleteRequest = () => {
  const { id } = useParams();
  const history = useHistory();
  // REDUX API HOOKS ---------------------------------------------------------
  const { data, isLoading } = useFetchDataRequestQuery(id);
  const [patchDataRequest, dataRequestResult] = usePatchDataRequestMutation();

  // MODAL HANDLERS -----------------------------------------------------------
  const [dataRequestError, setDataRequestError] = useState();
  const closeDataRequestErrorModal = useCallback(
    () => setDataRequestError(false),
    []
  );

  // API CALLS ----------------------------------------------------------------
  const handlePatchDataRequest = useCallback(async (values) => {
    // only pass editable data to request
    const { id, requestManner, requestNature, status } = values;
    patchDataRequest({
      id,
      requestManner,
      requestNature,
      status,
    });
  }, []);

  // HANDLE SUCCESSFUL DATA REQUEST -------------------------------------------
  useEffect(() => {
    if (dataRequestResult.isSuccess) {
      history.goBack();
    }
  }, [history, dataRequestResult.isSuccess, id]);

  // HANDLE DATA REQUEST ERROR ------------------------------------------------
  useEffect(() => {
    if (dataRequestResult.error?.data.errorMessage) {
      setDataRequestError(dataRequestResult.error?.data.errorMessage);
    }
  }, [dataRequestResult.error?.data.errorMessage]);

  return (
    <FormContainer>
      <SimpleDialog
        onClose={ closeDataRequestErrorModal }
        onSubmit={ closeDataRequestErrorModal }
        open={ dataRequestError }
        cancelLabel={ null }
        confirmLabel="Ok"
        title="Error Creating Data Request"
        description={ dataRequestError }
      />
      <Text font={ 'title-1' } as={ 'h1' }>
        Edit Data Request
      </Text>
      { !isLoading && data && (
        <DataRequestForm
          disabled={ dataRequestResult.isLoading }
          onSubmit={ handlePatchDataRequest }
          initialValues={ data }
          viewMode={ ViewMode.EDIT }
        />
      ) }
    </FormContainer>
  );
};

export default CreateDeleteRequest;
