import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import MenuHeaderSlot from './MenuHeaderSlot';
import MenuItems from './MenuItems';
import { Link, Menu, Text } from '@nike/eds';
import { currentEnvURL, retrieveHandleFromURL } from './subnavUtils';
import { WEB_FRONTEND_URLS } from 'lib/configs';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { useFetchInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import { InitiativeTypesEnum, InitiativeTypeList } from 'utils';
import { find, propEq } from 'ramda';
import { useToasts } from 'react-toast-notifications';

// eslint-disable-next-line
const SubNavigationMenu = ({ initiativeType }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const path = history?.location.pathname ?? '';
  const {
    name = '',
    handle = '',
    defaultMediaImage = '',
    shortHandle = '',
  } = JSON.parse(window.localStorage.getItem('sub-nav-obj', '{}')) || '';
  const [menuOpen, setMenuOpen] = useState(false);

  const handleFromURL = retrieveHandleFromURL(path);

  const {
    data: initData,
    isLoading: isInitDataLoading,
    isError,
    error,
  } = useFetchInitiativeQuery(handleFromURL, {
    skip: !handleFromURL,
  });

  useEffect(() => {
    if (isError && error?.data?.errorMessage) {
      addToast(error?.data?.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
      history.push('/initiative');
    }
  }, [isError, error]);

  const {
    name: initName = '',
    handle: initHandle = '',
    shortHandle: initShortHandle = '',
    defaultMedia = '',
  } = initData || '';
  const initImage = defaultMedia?.backgroundImage ?? '';

  const subNavObj = {
    name: initName,
    handle: initHandle,
    shortHandle: initShortHandle,
    defaultMediaImage: initImage,
  };

  if (handle !== handleFromURL) {
    window?.localStorage?.setItem('sub-nav-obj', JSON.stringify(subNavObj));
  }

  const { data: { value: configData } = {}, isFetching } =
    useFetchConfigQuery(WEB_FRONTEND_URLS);

  const webFrontEndURLs = useMemo(
    () => !isFetching && configData,
    [isFetching, configData]
  );

  const initTypeURLIndicator = [
    { type: InitiativeTypesEnum.QTX, urlSegment: 'survey' },
    { type: InitiativeTypesEnum.EXP, urlSegment: 'i' },
    { type: InitiativeTypesEnum.COR, urlSegment: 'i' },
  ];

  const webFrontEndURL = useMemo(
    () =>
      (webFrontEndURLs
        ? `${ currentEnvURL(webFrontEndURLs) }/${
          find(propEq('type', initiativeType), initTypeURLIndicator)
            ?.urlSegment
        }/${ handle === handleFromURL ? shortHandle : initShortHandle }`
        : ''),
    [
      handle,
      handleFromURL,
      webFrontEndURLs,
      shortHandle,
      initShortHandle,
      initTypeURLIndicator,
      initiativeType,
    ]
  );

  const handleMenuToggle = useCallback(() => {
    return setMenuOpen(!menuOpen);
  }, [menuOpen]);

  // const showsubNavMenu =
  //   !isInitDataLoading &&
  //   (handle === handleFromURL ? handle !== '' : initHandle !== '');

  const showsubNavMenu =
    !isInitDataLoading &&
    handleFromURL &&
    initHandle === handleFromURL &&
    !InitiativeTypeList.includes(handleFromURL);

  // const showsubNavMenu = !isInitDataLoading && handleFromURL;

  console.log('A-showsubNavMenu', showsubNavMenu);
  console.log('A-handleFromURL', handleFromURL);
  console.log('A-initHandle', initHandle);
  console.log('A-handle', handle);

  const headerAndBodySlotProps = {
    name: handle === handleFromURL ? name : initName,
    handle: handle === handleFromURL ? handle : initHandle,
    defaultMediaImage: initImage || defaultMediaImage,
  };

  if (!showsubNavMenu) {
    return null;
  }

  return (
    <Menu
      onClickOutside={ handleMenuToggle }
      isOpen={ menuOpen }
      headerSlot={
        <MenuHeaderSlot
          { ...headerAndBodySlotProps }
          handleMenuToggle={ handleMenuToggle }
          isMenuAnchor={ false }
        />
      }
      bodySlot={
        <MenuItems
          { ...headerAndBodySlotProps }
          menuOpen={ menuOpen }
          setMenuOpen={ setMenuOpen }
          initiativeType={ initiativeType }
        />
      }
      footerSlot={
        <Link
          target="_blank"
          href={ webFrontEndURL }
          disabled={ webFrontEndURL === '' }
        >
          <Text font="body-2">Preview</Text>
        </Link>
      }
      placement="bottom-start"
    >
      <MenuHeaderSlot
        { ...headerAndBodySlotProps }
        handleMenuToggle={ handleMenuToggle }
        isMenuAnchor={ true }
      />
    </Menu>
  );
};

MenuItems.propTypes = {
  initiativeType: PropTypes.string,
};

export default SubNavigationMenu;
