import React, { useCallback } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import { TextField } from '@nike/eds';

export const OptionDescriptionField = ({
  id,
  label,
  value,
  fieldName,
  onChange,
  answerOption,
  existingPPI,
  setAnswerOption,
}) => {
  const handleTextFieldChange = useCallback(
    (event) => {
      const textValue = event.target.value;
      const options = answerOption?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            description: textValue,
          };
        }
        return item;
      });
      onChange({ target: { name: fieldName } }, options);
      setAnswerOption(options);
    },
    [answerOption]
  );

  return (
    <Field
      key={ 'field-' + id }
      name={ id }
      value={ existingPPI ? label : value }
      style={{ width: '40%' }}
    >
      { ({ field, form }) => {
        const handleChange = useCallback(
          (event) => {
            form.setFieldTouched(field.name, true);
            handleTextFieldChange(event);
          },
          [answerOption]
        );

        return (
          <TextField
            id={ 'description-' + id }
            name={ label }
            label={ '' }
            value={ existingPPI ? label : value }
            disabled={ !!existingPPI }
            onChange={ handleChange }
            className={ styles.textFieldStyle }
            placeholder={ 'Option Description' }
          />
        );
      } }
    </Field>
  );
};

OptionDescriptionField.propTypes = {
  answerOption: PropTypes.arrayOf(Object),
  disabled: PropTypes.bool,
  existingPPI: PropTypes.string,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setAnswerOption: PropTypes.func,
  setTouched: PropTypes.func,
  value: PropTypes.string,
};
