import React from 'react';
import { Button, ButtonGroup } from '@nike/eds';
import { confirmModalDetails } from '../AssetCard/utils';
import PropTypes from 'prop-types';

export const ConfirmPublishModalFooter = ({
  handlePublish,
  confirmPublishModal,
}) => (
  <ButtonGroup>
    <Button variant="primary" onClick={ handlePublish }>
      { confirmModalDetails.leftCta }
    </Button>
    <Button variant="secondary" onClick={ confirmPublishModal }>
      { confirmModalDetails.rightCta }
    </Button>
  </ButtonGroup>
);

ConfirmPublishModalFooter.propTypes = {
  confirmPublishModal: PropTypes.func,
  handlePublish: PropTypes.func,
};
