import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'components/DataTable';
import styles from './Audience.module.scss';

const AudienceMemberList = ({
  loading,
  data,
  createHref,
  handleRemove,
  title = 'Members',
}) => {
  const columns = [
    {
      field: 'upmId',
      headerName: 'UPM Id',
      minWidth: 300,
      flex: 1,
      filterable: true,
      renderCell: (params) =>
        (params?.value ? (
          <div className={ styles.cellWrapper }>{ params.value }</div>
        ) : (
          'N/A'
        )),
    },

    {
      field: 'athleteId',
      headerName: 'Athlete Id',
      minWidth: 300,
      flex: 1,
      filterable: true,
      renderCell: (params) =>
        (params?.value ? (
          <div className={ styles.cellWrapper }>{ params.value }</div>
        ) : (
          'N/A'
        )),
    },
  ];

  const actions = [
    {
      key: 'upmId',
      label: 'remove',
      onClick: handleRemove,
      action: 'delete',
      entity: 'audience',
    },
  ];

  return (
    <DataTable
      heading={ title }
      loading={ loading }
      columns={ columns }
      rows={ data }
      actions={ actions }
      createHref={ createHref }
      createLabel="add"
    />
  );
};

AudienceMemberList.propTypes = {
  createHref: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  handleRemove: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  viewMode: PropTypes.string,
};

export default AudienceMemberList;
