import React, { useEffect, useMemo } from 'react';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import { ViewMode, EntityType } from 'lib/enums';
import { useFetchFeatureFlagsQuery } from 'features/adminApi/endpoints/featureFlag';
import { useToasts } from 'react-toast-notifications';

const columns = [{ field: 'handle', headerName: 'Handle', flex: 2 }];

const actions = [
  {
    key: 'id',
    url: '/dev/featureFlag/edit/',
    label: 'edit',
    action: ViewMode.EDIT,
    entity: EntityType.DEV_TOOLS,
  },
];

const FeatureFlagsListView = () => {
  const { addToast } = useToasts();
  const { data, isLoading, isError } = useFetchFeatureFlagsQuery();

  useEffect(() => {
    if (isError) {
      addToast('Error occurred while fetching featureFlags-list', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError]);

  const items = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        id: item.handle,
        ...item,
      }));
    }
  }, [data]);

  return isLoading ? (
    <Loading text="Fetching feature flag list" />
  ) : (
    <DataTable
      loading={ isLoading }
      heading="Feature Flag List"
      columns={ columns }
      actions={ actions }
      actionMenu={ false }
      rows={ items || [] }
      muiGridProps={{
        getRowId: (row) => row?.handle,
      }}
    />
  );
};

export default FeatureFlagsListView;
