import React, { Fragment } from 'react';
import { Table, TableHeading } from '@nike/eds';
import PropTypes from 'prop-types';
import styles from '../index.module.scss';

export const TableComponent = ({ headers, rows }) => {
  return (
    <Table className={ styles.guideTable }>
      <thead>
        <tr>
          { headers.map((column) => (
            <TableHeading key={ column.Header }>{ column.Header }</TableHeading>
          )) }
        </tr>
      </thead>
      <tbody>
        { rows.map((row, idx) => (
          <Fragment key={ idx }>{ row }</Fragment>
        )) }
      </tbody>
    </Table>
  );
};

TableComponent.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
    })
  ),
  rows: PropTypes.arrayOf(PropTypes.node),
};
