import { activitySurveyPrefix } from 'components/forms/Poll/data';
import { digitalServicePrefix } from 'components/forms/utils';

export const handleCompleteRedirect = (history, returnUrl, initiativeType) => {
  const initiativeURL = `/initiative/${ initiativeType }`;
  if (returnUrl) {
    history.push(returnUrl);
  } else if (initiativeType === digitalServicePrefix) {
    history.push(initiativeURL);
  } else if (initiativeType === activitySurveyPrefix) {
    history.push(initiativeURL);
  } else {
    history.push('/');
  }
};
