import { makeStyles } from '@material-ui/core/styles';
export const useModalStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '680px',
    borderRadius: '7px',
    boxShadow: '0 6px 10px 0 rgba(131,134,163,0.12)',
  },
  header: {
    backgroundColor: 'rgba(245, 246, 246, 1.0)',
    borderTopRightRadius: '7px',
    borderTopLeftRadius: '7px',
    '& h3': {
      lineHeight: '80px',
      paddingLeft: '20px',
      fontSize: '30px',
      letterSpacing: '-.05em',
      textAlign: 'center',
      fontWeight: '500',
    },
    '& button': {
      lineHeight: '110px',
      height: '100%',
      width: '100%',
    },
  },
  body: {
    padding: '20px 20px',
    '& p': {
      padding: '40px 20px',
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: '300',
      lineHeight: '40px',
    },
    '& strong': {
      paddingTop: '20px',
    },
  },
}));
