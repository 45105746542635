import { formatDistanceToNow } from 'date-fns';
import { format as formatDate, parseISO } from 'date-fns/fp';
import { isNil, path, pipe, unless } from 'ramda';

export const formatISO = (date, { format }) => {
  return date ? formatDate(format || 'MM/dd/yyyy', parseISO(date)) : '';
};

export const formatDateRelativeToNow = pipe(
  parseISO,
  formatDistanceToNow,
  result => `${ result } ago`
);

export const formatJoinDate = pipe(
  path(['joinDate']),
  unless(isNil, formatDateRelativeToNow)
);

export const formatLastLoginDate = pipe(
  path(['lastLoginDate']),
  unless(isNil, formatDateRelativeToNow)
);
