import React, { useState, useCallback } from 'react';
import { Menu, MenuItem, IconButton } from '@nike/eds';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { pickAll, pathOr, mergeAll, fromPairs } from 'ramda';

const ActionMenu = ({ actions, row }) => {
  const [open, setOpen] = useState(false);
  const handleMenuToggle = useCallback(() => {
    return setOpen(!open);
  }, [open]);

  const body = actions.map((action, i) => {
    const contains = action?.url.includes(':');
    const url = contains
      ? action.url.replace(`:${ action.key }`, row[action.key])
      : `${ action.url }${ row.handle || row.id }`;

    const to = action.url && url;
    const handleClick = useCallback(async () => {
      await window?.localStorage?.setItem(
        'sub-nav-obj',
        JSON.stringify(
          mergeAll([
            pickAll(['handle', 'name', 'shortHandle'], row),
            fromPairs([
              [
                'defaultMediaImage',
                pathOr('', ['defaultMedia', 'backgroundImage'], row),
              ],
            ]),
          ])
        )
      );
      if (action.onClick) action.onClick(row[action.key]);
    }, [action.onClick]);

    return (
      !action.hidden && (
        <Link
          disabled={ action.disable }
          value={ row.id }
          key={ `link_${ i }` }
          onClick={ handleClick }
          //Backend team is using handle as a unique id instead of uuid randomly generated
          to={ to }
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <MenuItem key={ action.label }>{ action.label }</MenuItem>
        </Link>
      )
    );
  });
  return (
    actions.length && (
      <div style={{ display: 'flex', alignItems: 'center' }} id="menuContainer">
        <Menu
          onClickOutside={ handleMenuToggle }
          isOpen={ open }
          bodySlot={ body }
          placement="bottom-start"
          modifiers={ [
            {
              name: 'preventOverflow',
              options: {
                mainAxis: true,
                altAxis: true, // true by default
              },
            },
          ] }
        >
          <div style={{ display: 'flex' }}>
            <IconButton
              onClick={ handleMenuToggle }
              size="small"
              variant="ghost"
              label="open menu"
              icon="Ellipsis"
            />
          </div>
        </Menu>
      </div>
    )
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    handle: PropTypes.string,
    shortHandle: PropTypes.string,
    defaultMedia: PropTypes.shape({ backgroundImage: PropTypes.string }),
  }),
};

export default ActionMenu;
