import {
  applyTo,
  curry,
  isNil,
  join,
  map,
  // path,
  pipe,
  reject,
} from 'ramda';

// functional version of apollo client's keyArgs array syntax
// composes multiple query args into a single cache key
// [(a -> String)] -> a -> String
const keyArgs = curry((keyGetters, args) => {
  return applyTo(keyGetters, pipe(
    map(applyTo(args)),
    reject(isNil),
    join(':'),
  ));
});

export default {
  orderByAppId: keyArgs([]),
  searchProducts: keyArgs([]),
};
