/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react';
import {
  useFetchConsentQuery,
  useCreateConsentMutation,
  usePatchConsentMutation,
  useFetchCompiledConsentByVersionQuery,
  useFetchConsentByIdQuery,
} from 'features/adminApi/endpoints/consent';
import { useParams, useHistory } from 'react-router-dom';
import { AddEditForm, Preview } from '../components';
import {
  convertHandleToCardType,
  getFormFields,
} from 'components/forms/Assets/lib';
import { checkAndUpdateConsentFields, CONSENT_FORM } from 'lib/layoutUtils';
import Loading from 'components/Loading';
import { isEmpty } from 'lodash';
import { ViewMode } from 'lib/enums';
import { convertLayoutId } from '../utils';

const skipVersionNumber = [0, NaN];

const AddEditConsent = () => {
  const { id, viewMode, type } = useParams();
  const edit = viewMode !== viewMode.VIEW;
  const history = useHistory();
  const versionNumber = Number(history?.location?.state?.version);

  const [layoutId, setlayoutId] = useState();
  const [formFields, setFormFields] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [preview, setPreview] = useState({ show: true });
  const payload = {
    initiativeHandle: id,
    version: versionNumber,
  };
  const skipCheck = skipVersionNumber.includes(versionNumber);

  /**
   * Redux hooks
   */
  const [usePatchConsent, patchConsentResult] = usePatchConsentMutation();
  const [useCreateConsent, postConsentResult] = useCreateConsentMutation();
  const { data, isLoading } = useFetchConsentQuery(id);
  const { data: compiledConsent, isLoading: compiledConsentLoading } =
    useFetchCompiledConsentByVersionQuery(payload, {
      skip: skipCheck || id === undefined,
    });

  const { data: consentData, isLoading: isConsentDataLoading } =
    useFetchConsentByIdQuery(
      { initiativeHandle: id, id: layoutId },
      {
        skip: !id || !layoutId || type !== CONSENT_FORM,
      }
    );

  useEffect(() => {
    const initialLayoutId = history?.location?.state?.layoutId
      ? history?.location?.state?.layoutId
      : JSON.parse(sessionStorage.getItem('layoutId')) || initialData.id;
    const finalLayoutId = convertLayoutId(initialLayoutId);
    setlayoutId(finalLayoutId);
  }, [history, initialData]);

  useEffect(() => {
    if (!compiledConsentLoading) {
      const selectedData = consentData
        ? viewMode !== ViewMode.CREATE && consentData
        : !skipCheck
          ? compiledConsent
            ? compiledConsent?.content
            : []
          : layoutId && data
            ? data.filter((itm) => itm.id === layoutId).pop()
            : [];

      const formFields =
        getFormFields(selectedData, 'consentDocument')?.elements || [];

      const initialData = formFields?.reduce(
        (acc, element) => {
          return {
            ...acc,
            [element?.type]: element?.value || '',
          };
        },
        {
          id: selectedData?.id,
          initiativeHandle:
            selectedData?.initiativeHandle ||
            history?.location?.state?.initiativeHandle,
        }
      );
      setFormFields(formFields);
      setInitialData(initialData);
    }
  }, [data, consentData, layoutId, compiledConsent, compiledConsentLoading]);

  useEffect(() => {
    const prev = { ...preview, sections: initialData.repeatingText };
    if (!isEmpty(prev?.sections)) setPreview(prev);
  }, [initialData?.repeatingText]);

  const layoutType = convertHandleToCardType(CONSENT_FORM);

  return isLoading || compiledConsentLoading || isConsentDataLoading ? (
    <Loading show={ true } text="Fetching Consent Document" />
  ) : (
    <>
      <AddEditForm
        layoutId={ layoutId }
        submitText={ 'Save Consent Form' }
        patchResult={ patchConsentResult }
        postResult={ postConsentResult }
        useCreate={ useCreateConsent }
        usePatch={ usePatchConsent }
        viewMode={ viewMode }
        data={ data }
        edit={ edit }
        isLoading={ isLoading }
        id={ id }
        setPreview={ setPreview }
        initialData={ initialData }
        assetType={ CONSENT_FORM }
        layoutType={ layoutType }
        formFields={ formFields }
        history={ history }
        updateFields={ checkAndUpdateConsentFields }
        initiativeHandle={ payload?.initiativeHandle }
      />

      <Preview
        show={ preview?.show }
        cardType={ layoutType }
        content={ preview?.sections }
      />
    </>
  );
};

export default AddEditConsent;
