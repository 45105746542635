import React from 'react';
import { Text } from '@nike/eds';
import RichTextTable from './RichTextTable';
import styles from './styles.module.scss';
import { Box } from '@material-ui/core';

export const formatText = ({ node, ...props }) => {
  const pattern = /^==(.+)==$/;
  let hasHighlightedSyntax = false;
  const renderChild = (children) => {
    return children.map((child, index) => {
      if (child.type === 'element') {
        const { tagName, ...childProps } = child;
        return (
          <Text
            className={ styles[tagName] }
            key={ index }
            as={ tagName }
            { ...childProps }
          >
            { renderChild(child.children) }
          </Text>
        );
      }

      const value = child.value.replace(/\n\\\n/g, '\n\n').replace(/\\/g, '');
      if (pattern.test(value) || value.includes('==')) {
        hasHighlightedSyntax = true;
        return (
          <span className={ styles.highlight } key={ index }>
            { value.replace(pattern, '$1').replace('==', '') }
          </span>
        );
      }
      return value;
    });
  };

  return (
    <div className={ styles.text }>
      <Text
        as={ node.tagName }
        font={ props?.level ? `title-${ props?.level }` : null }
        css={ hasHighlightedSyntax ? { color: 'red' } : {} }
      >
        { renderChild(node.children) }
      </Text>
    </div>
  );
};

export const format = ({ node, ...props }) => (
  <Box component={ node.tagName }>{ props.children }</Box>
);

export const formatTable = ({ node, ...props }) => {
  return <RichTextTable { ...props } />;
};

export const cleanText = (value) =>
  (value ? value.replaceAll('\n\\\n', '\n') : null);

const isHighlightedSyntax = (array) => {
  if (
    array.length < 2 ||
    typeof array[0] !== 'string' ||
    typeof array[array.length - 1] !== 'string'
  ) {
    return false;
  }
  return array[0] === '==' && array[array.length - 1] === '==';
};

const highlightContent = (content) => (
  <span className={ styles.highlight }>{ content }</span>
);

export const formatHighlightText = ({ node, ...props }) => {
  const pattern = /[=]=([^=]+)==/g;
  //eslint-disable-next-line max-statements, complexity
  const textWithHighlights = props.children.map((child, idx) => {
    const firstParentChild = child?.props?.children?.[0];
    if (typeof child === 'string') {
      if (pattern.test(child)) {
        const highlightedParts = child.split(pattern);
        return highlightedParts
          .map((part, index) => {
            if (index % 2 === 0) {
              //non-highlighted
              return part !== '' ? part : undefined;
            }
            //highlighted
            return highlightContent(part);
          })
          .filter((part) => part !== undefined);
      } else if (isHighlightedSyntax(props.children) && idx === 0) {
        return highlightContent(props.children[1]);
      }
    }
    if (
      (child.type === 'strong' || child.type === 'del') &&
      pattern.test(firstParentChild)
    ) {
      return (
        <strong key={ idx }>
          { highlightContent(firstParentChild.replace(pattern, '$1')) }
        </strong>
      );
    } else if (
      (child.type === 'strong' || child.type === 'del') &&
      isHighlightedSyntax(child?.props.children)
    ) {
      const firstChild = child?.props?.children?.[1];
      const nestedFirstChild = firstChild?.props?.children?.[0];
      const isDel = firstChild?.type === 'del';

      return (
        <span className={ styles.highlight } key={ idx }>
          { isDel ? (
            <strong>
              <del>{ nestedFirstChild }</del>
            </strong>
          ) : (
            <strong>{ nestedFirstChild }</strong>
          ) }
        </span>
      );
    } else if (!isHighlightedSyntax(props.children)) {
      return child;
    }
  });

  return (
    <Text as={ node.tagName } { ...props }>
      { textWithHighlights }
    </Text>
  );
};
