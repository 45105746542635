import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { applyTo, pipe, equals } from 'ramda';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

// aliases
import { propTypes, defaultProps } from 'lib/react';

export default applyTo(({
  label,
  multiple,
  className,
  options,
  ...props
}) => {
  
  const [field, meta, helpers] = useField(props);

  const handleChange = useCallback((event, option) => {
    const {
      setValue,
      setTouched,
    } = helpers;

    setValue(option);
    setTouched(true);
  }, [helpers]);

  return (
    <Autocomplete
      { ...field }
      options={ options }
      onChange={ handleChange }
      multiple={ true }
      getOptionLabel={ useCallback(option => option.label, []) }
      getOptionSelected={ useCallback((option, value) => equals(option, value), []) }
      renderInput={ useCallback(props => (
        <TextField
          { ...props }
          label={ label }
          helperText={ meta.error }
          error={ !!(meta.touched && meta.error) } />
      ), [meta, label]) }
    />
  );
}, pipe(
  propTypes({
    className: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  defaultProps({
    className: null,
    label: '',
    multiple: false,
    options: [],
  }),
  memo,
));
