import React, { forwardRef } from 'react';
//import { animated, useSpring } from 'react-spring/web.cjs';
import { useModalStyles } from './styles';
import { Backdrop, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';

const Fade = forwardRef((props, ref) => {
  const { in: children, ...other } = props;

  return (
    <div ref={ ref } { ...other }>
      { children }
    </div>
  );
});

// eslint-disable-next-line react/no-multi-comp
export default function ModalWrapper(props) {
  const { handleClose, open, children } = props;
  const classes = useModalStyles();

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={ classes.modal }
      open={ open }
      onClose={ handleClose }
      closeAfterTransition={ true }
      BackdropComponent={ Backdrop }
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={ open }>{ children }</Fade>
    </Modal>
  );
}

Fade.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

ModalWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
