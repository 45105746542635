import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { useField } from 'formik';

import { FieldError } from 'components/fields';


import DropZone from './components/DropZone';
import styles from './FileField.module.scss';

const FileField = ({
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const handleChange = useCallback(async file => {
    const { setValue, setTouched } = helpers;
    setValue(file);
    setTouched(true);
  }, [helpers]);

  return (
    <>
      <label className={ styles.label }>
        { label }
      </label>
      <input { ...field } { ...props } type="hidden" />
      <DropZone onChange={ handleChange } url={ path(['value', 'url'], field) } />
      { meta.touched && meta.error ? <FieldError>{ meta.error }</FieldError> : null }
    </>
  );
};

FileField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
FileField.defaultProps = {
  label: '',
  value: '',
};

export default memo(FileField);
