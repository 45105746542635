import React from 'react';
import styles from '../../DataRequest.module.scss';
import { getResolutionDeadline } from 'views/UserDeleteRequests/utils';
import { shape, string } from 'prop-types';
import { defaultTo } from 'ramda';

const DataRequestInfo = ({
  dataRequest: { upmId, athleteId, requestedDate, requestManner } = {},
}) => {
  const resolutionDeadline = getResolutionDeadline(requestedDate);

  return (
    <div className={ styles.grid }>
      <div>
        <span className={ styles.header }>
          <p className={ styles.bold }>UPM ID: </p>
          <p>{ defaultTo('', upmId) }</p>
        </span>
        <span className={ `${ styles.header } ${ styles.lightGray }` }>
          <p className={ styles.bold }>Athlete Profile ID: </p>
          <p>{ defaultTo('', athleteId) }</p>
        </span>
      </div>
      <div>
        <span className={ styles.header }>
          <p className={ styles.bold }>Resolution Deadline: </p>
          <p> { resolutionDeadline ? resolutionDeadline : '' }</p>
        </span>
        <span className={ styles.header }>
          <p className={ styles.bold }>Requested Via: </p>
          <p> { defaultTo('', requestManner) }</p>
        </span>
        <div />
      </div>
    </div>
  );
};

DataRequestInfo.propTypes = {
  dataRequest: shape({
    upmId: string,
    athleteId: string,
    requestedDate: string,
    requestManner: string,
  }),
};
export default DataRequestInfo;
