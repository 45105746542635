/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
// modules
import React, { memo, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

// aliased
// Views
import HomeView from 'views/HomeView';
import DevView from 'views/DevView';
import Initiative from 'views/Initiative';
import Audience from 'views/Audience';
import Cache from 'views/Cache';

// Components
import Progress from 'components/Progress';
import NotFound from 'components/NotFound';
import AdminNav from 'components/AdminNav';
import withRolePermissions from 'lib/hooks/withRolePermissions';
import Logout from 'components/Logout';
import AccessControl from 'components/RBAC';
import UserDeleteRequests from 'views/UserDeleteRequests';
import { navPanelSubItems } from 'components/AdminNav/SubNavigationMenu/subnavUtils';

export default memo(() => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  const CacheWithProps = useCallback(() => <Cache localData={ true } />, []);

  return (
    <div data-testid="protected-view-container">
      { isAuthenticated ? (
        <AccessControl>
          <AdminNav navItems={ isAuthenticated ? navPanelSubItems : [] }>
            <Switch>
              <Route path="/" exact={ true } component={ HomeView } />
              <Route path="/dev" component={ withRolePermissions(DevView) } />
              <Route
                path="/initiative"
                component={ withRolePermissions(Initiative) }
              />
              <Route
                path="/audience"
                component={ withRolePermissions(Audience) }
              />
              <Route
                path="/dataRequests"
                component={ withRolePermissions(UserDeleteRequests) }
              />
              <Route
                path="/cache"
                component={ withRolePermissions(CacheWithProps) }
              />
              <Route path="/logout" component={ Logout } />
              <Route component={ NotFound } />
            </Switch>
          </AdminNav>
        </AccessControl>
      ) : (
        <Progress />
      ) }
    </div>
  );
});
