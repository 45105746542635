import { BreakPointEnum } from '../../lib/enums';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BackgroundCss = css`
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
  object-fit: contain;
`;

// Desktop Styles
export const HeroCardStyled = (props) => css`
  border-radius: 8px;
  background-image: ${ `url(${ props.backgroundImage })` };
  position: relative;
  opacity: 0.95;
  min-height: 463px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: ${ BreakPointEnum.breakpointM }) {
    padding: 36px 36px 40px 36px;
    min-height: 415px;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    object-fit: contain;
  }

  @media (min-width: 961px) and (max-width: 1078px) {
    padding: 20px;
  }
`;
export const HeroSectionStyled = styled.div`
  width: 100%;
`;
export const Tagline = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (min-width: ${ BreakPointEnum.breakpointM }) {
    width: '60%';
    padding-right: 36px;
  }
  @media (min-width: ${ BreakPointEnum.breakpointM }) and (max-width: 1078px) {
    padding-right: 18px;
  }
  .web-overview-card-headline {
    max-width: 75%;
  }
`;

export const StyledHeroLogoDesktop = styled.div`
  display: none;
  @media (min-width: ${ BreakPointEnum.breakpointM }) {
    display: flex;

    align-items: center;
    justify-content: flex-start;
    margin: 70px 0px;
    width: 40%;
  }
  @media (min-width: ${ BreakPointEnum.breakpointM }) and (max-width: 1078px) {
    padding-right: 20px;
  }
`;

export const StyledHeroLogoMobile = styled.div`
  display: none;
  @media (max-width: ${ BreakPointEnum.breakpointM }) {
    display: block;
    margin-bottom: 20px;
  }
`;

//START web detail card
export const DynamicPageContentStyled = styled.div`
  padding-top: 75px;

  @media (min-width: ${ BreakPointEnum.breakpointM }) {
    padding-top: 75px;
  }
`;

export const StyledTagline = styled.div`
  width: 100%;
`;

export const LogoStyled = styled.img`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 60px 0px 0px 0px;
  max-height: 130px;
  max-width: 120px;

  @media (min-width: ${ BreakPointEnum.breakpointS }) {
    margin: 0 0 0 36px;
    max-height: 50px;
    max-width: 300px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 42px;
  .hero-cta {
    display: block;
    margin: 0 auto;
    max-width: 330px;
  }

  @media (min-width: ${ BreakPointEnum.breakpointS }) {
    margin-bottom: 36px;
    .hero-cta {
      display: flex;
      margin: 0px 36px;
    }
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  font-size: 1.1em;
  padding: 20px;
  height: calc(100vh - 75px);

  position: relative;
  width: 100%; // 100vw causes horizontal scrollbar
  min-height: 760px;
  margin: 0 auto;
  text-align: center;
  background-image: ${ (props) =>
    (props.backgroundImage
      ? `linear-gradient(73.93deg, #172D92 0%, rgba(23, 45, 146, 0) 100%), url("${ props.backgroundImage }")}`
      : 'url("/mntm_landing/art/HeroImage_Desktop.svg")') };
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
  mix-blend-mode: multiply;

  color: white;
  white-space: pre-wrap;

  p {
    padding-bottom: 60px;
    max-width: 95%;
    margin-top: 12px;
    font-size: 18px;
    line-height: 22px;
  }

  p.tagline {
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0px;
    max-width: 965px;
    padding-bottom: 0px;
  }

  h1 {
    padding-top: 120px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-align: center;

    padding-top: 60px;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  .initiative-logo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 60px 0px 0px 0px;
    max-height: 130px;
    max-width: 120px;
  }

  .caret {
    position: absolute;
    /* text-align: center; */
    bottom: 24px;
    left: 0px;
    width: 100%;
    & span {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #11111130;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
    }
    & svg {
      height: 10px;
      width: 16px;
    }
  }

  @media (min-width: ${ BreakPointEnum.breakpointS }) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    height: calc(100vh - 75px);
    padding: 0px 20px;
    font-size: inherit;

    h1 {
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
    }

    p {
      margin: auto;
      font-size: 20px;
      line-height: 24px;
    }

    p.tagline {
      letter-spacing: -0.03em;
      text-align: left;
      margin: 36px;
    }

    .initiative-logo {
      margin: 0 0 0 36px;
      max-height: 90px;
      max-width: 85px;
    }
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const HeroBottomStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  white-space: pre-wrap;
  margin: 60px 24px 0px 24px;
  font-size: 16px;

  li {
    text-align: left;
    font: 400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif !important;
    margin-bottom: 0;
  }

  .body-text,
  span {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 36px;
    font: 400 1rem/1.5 'Helvetica Now Text', Helvetica, Arial, sans-serif !important;
  }

  H1 strong {
    font: 700 1.7rem 'Helvetica Now Text', Helvetica, Arial, sans-serif !important;
    margin-bottom: 0;
  }

  H2 strong {
    font: 400 1.5rem 'Helvetica Now Text', Helvetica, Arial, sans-serif !important;
    margin-bottom: 0;
  }

  H3 strong {
    font: 400 1.3rem 'Helvetica Now Text', Helvetica, Arial, sans-serif !important;
    margin-bottom: 0;
  }

  .headline {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 36px;
  }

  .app-store-img {
    margin-bottom: 48px;
  }

  @media (min-width: ${ BreakPointEnum.breakpointS }) {
    margin: auto;
    max-width: 1000px;

    .headline {
      max-width: 1047px;
      font-style: normal;
      font-size: 72px;
      font-weight: 800;
      line-height: 80px;
      margin: 60px 36px 0px 36px;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 36px;
    }

    .body-text,
    span {
      line-height: 19px;
      text-align: center;
      margin: 40px 60px;
    }
  }
`;
//END web detail card

export const MobileCardStyles = css`
  max-width: 320px; //not sure what the max width in mobile
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileDetailScreen = (props) => css`
  ${ MobileCardStyles }
  min-height: 50vh;
`;

export const WebDetailPage = (props) => css``;

export const MobileOverviewCard = (props) => css`
  ${ MobileCardStyles }
  padding: 16px;
  min-height: 400px;
  border-radius: 8px;
  align-items: flex-start;
  background-image: ${ `url(${ props.backgroundImage })` };
  .eds-box {
    flex-grow: 1;
  }
`;

export const MobileReturningCard = (props) => css`
  ${ MobileCardStyles }
  min-height: 300px;
  background-image: ${ `url(${ props.backgroundImage })` };
  border-radius: 8px;
  padding: 16px;
  align-items: flex-end;
  .eds-box {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const cardStyles = {
  mobileOverviewCard: MobileOverviewCard,
  mobileDetailScreen: MobileDetailScreen,
  mobileReturningCard: MobileReturningCard,
  webOverviewCard: HeroCardStyled,
  webDetailPage: WebDetailPage,
  consentDocument: HeroCardStyled,
  overviewCard: HeroCardStyled,
};

export const PreviewCard = styled.div`
  ${ BackgroundCss }
  ${ ({ cardType }) => cardStyles[cardType] }
  h5 {
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -3.5%;
    font-weight: normal;
    @media (min-width: ${ BreakPointEnum.breakpointM }) {
      font-size: 64px;
      line-height: 64px;
    }
  }
  p {
    white-space: pre-wrap;
  }

  @media (min-width: ${ BreakPointEnum.breakpointM }) and (max-width: 1078px) {
    padding-right: 18px;
  }
`;
const alignSelfStyle = {
  alignSelf: 'self-start',
};

export const displayCenter = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

export const DetailCardCoverImage = styled.div`
  ${ BackgroundCss }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  min-height: 50%;
  flex-grow: 2;
  min-width: 100%;
  height: 600px;
  background: ${ (props) =>
    `linear-gradient(73.93deg, #172D92 0%, rgba(23, 45, 146, 0) 100%) , url(${ props.backgroundImage })` };
  mix-blend-mode: multiply;
  border-radius: 10px 10px 0 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  .caret-left-icon {
    align-self: self-start;
  }
`;
export const DetailCardContentContainer = styled.div`
  ${ alignSelfStyle };
  padding-bottom: 16px;
`;

export const AppleWatchContainer = styled.div`
  ${ displayCenter };
  padding: 24px 0px;
`;

export const DetailCardText = styled.div`
  flex-grow: 1;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  > p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
  > :first-of-type {
    margin: 8px 0px;
  }
  .detail-card-text {
    max-height: 200px;
    overflow: scroll;
    border: 1px solid var(--eds-color-border-primary);
    padding: 24px;
  }
`;

export const CtaButtonContainer = styled.div`
  margin: 0 10px;
  min-width: 280px;
`;

export const PermissionsContainer = styled.div`
  ${ alignSelfStyle };
`;

export const LogoImageWrapper = styled.div`
  width: auto;
  height: 100%;
  max-height: 150px;
  max-width: 150px;
  overflow: hidden;
  img {
    object-fit: contain;
  }
`;
