/* eslint-disable react/prop-types */
/* eslint-disable  max-statements */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-handler-names */
// modules
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { Link } from '@nike/eds';
import {
  useFetchAudienceInitiativesQuery,
  useCreateAudienceInitiativesMutation,
  useDeleteAudienceInitiativesMutation,
} from 'features/adminApi/endpoints/audienceInitiatives';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { AudienceTypesEnum } from 'utils';
import { ToggleField } from './styles';
import { usePatchInitiativeAudienceMutation } from 'features/adminApi/endpoints/initiativeAudiences';

const AudienceToggle = ({
  initiativeHandle,
  audienceHandle,
  name,
  isEditAllowed,
  audiencesType,
  status,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { initiativeType } = useParams();
  const { SHARED_AUDIENCE, INIT_AUDIENCE } = AudienceTypesEnum;
  const isInitiativeAudienceType = audiencesType === INIT_AUDIENCE;
  const audience = useFetchAudienceInitiativesQuery(audienceHandle, {
    skip: isInitiativeAudienceType,
  });

  const [postAudience, postAudienceResult] =
    useCreateAudienceInitiativesMutation();
  const [deleteAudience, deleteAudienceResult] =
    useDeleteAudienceInitiativesMutation();
  const [patchInitiativeAudience, initiativeAudienceResult] =
    usePatchInitiativeAudienceMutation();

  const [showEditBtn, setShowEditBtn] = useState(null);
  const [isChecked, setIsChecked] = useState(
    isInitiativeAudienceType ? status === 'active' : false
  );

  const [isHandleChangeTriggered, setIsHandleChangeTriggered] = useState(false);

  useEffect(() => {
    if (audience?.data) {
      setIsChecked((audience.data || []).includes(initiativeHandle));
    }
  }, [audience?.data]);

  const handleHover = useCallback(() => {
    setShowEditBtn(name);
  });

  const handleMouseLeave = useCallback(() => {
    setShowEditBtn(null);
  });

  const handleEditBtnClick = useCallback(() => {
    const initAudiencePath = `/initiative/${ initiativeType }/${ initiativeHandle }/audiences`;
    const initAudienceEditPath = `${ initAudiencePath }/edit/${ audienceHandle }`;
    const initAudienceViewPath = `${ initAudiencePath }/${ audienceHandle }`;
    const isSharedAudience =
      audiencesType === AudienceTypesEnum.SHARED_AUDIENCE;
    const path = isEditAllowed
      ? isSharedAudience
        ? `/audience/edit/${ audienceHandle }`
        : initAudienceEditPath
      : isSharedAudience
        ? `/audience/${ audienceHandle }`
        : initAudienceViewPath;

    sessionStorage.setItem('tabAudienceType', audiencesType);
    history.push({
      pathname: path,
      state: { initiativeHandle, audienceType: audiencesType },
    });
  }, [isEditAllowed]);

  const handleChange = useCallback(() => {
    setIsHandleChangeTriggered(true);
  }, []);

  useEffect(() => {
    if (isHandleChangeTriggered) {
      if (!isChecked) {
        if (isInitiativeAudienceType) {
          patchInitiativeAudience({
            initiativeHandle,
            audienceHandle,
            status: 'active',
          });
        } else postAudience({ audienceHandle, initiativeHandle });
      } else if (isInitiativeAudienceType) {
        patchInitiativeAudience({
          initiativeHandle,
          audienceHandle,
          status: 'inactive',
        });
      } else deleteAudience({ audienceHandle, initiativeHandle });
    }
  }, [
    isHandleChangeTriggered,
    isChecked,
    initiativeHandle,
    audienceHandle,
    audiencesType,
  ]);

  useEffect(() => {
    const successToastMessage =
      status === 'active'
        ? `Deleted Audience ${ name }`
        : `Added Audience ${ name }`;
    const errorToastMessage =
      status === 'active'
        ? `Error Deleting Audience ${ name }`
        : `Error Adding Audience ${ name }`;
    if (initiativeAudienceResult.isSuccess) {
      addToast(successToastMessage, {
        appearance: 'success',
        autoDismiss: true,
      });
      setIsChecked(!isChecked);
    } else if (initiativeAudienceResult.isError) {
      addToast(errorToastMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    setIsHandleChangeTriggered(false);
  }, [initiativeAudienceResult.isSuccess, initiativeAudienceResult.isError]);

  useEffect(() => {
    if (postAudienceResult.isSuccess) {
      addToast(`Added Audience ${ name }`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setIsChecked(!isChecked);
    } else if (postAudienceResult.isError) {
      addToast(`Error Adding Audience ${ name }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    setIsHandleChangeTriggered(false);
  }, [postAudienceResult.isSuccess, postAudienceResult.isError]);

  useEffect(() => {
    if (deleteAudienceResult.isSuccess) {
      addToast(`Deleted Audience ${ name }`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setIsChecked(!isChecked);
    } else if (deleteAudienceResult.isError) {
      addToast(`Error Deleting Audience ${ name }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    setIsHandleChangeTriggered(false);
  }, [deleteAudienceResult.isSuccess, deleteAudienceResult.isError]);

  return (
    <div
      onMouseEnter={ handleHover }
      onMouseLeave={ handleMouseLeave }
      className={ styles.toggleContainer }
    >
      <ToggleField
        checked={ isChecked }
        disabled={
          audience?.isLoading ||
          postAudienceResult.isLoading ||
          deleteAudienceResult.isLoading ||
          !isEditAllowed ||
          initiativeAudienceResult.isLoading
        }
        onChange={ handleChange }
        label={ name }
        disabledstyle={ isInitiativeAudienceType.toString() }
      />
      <Link
        as="button"
        font="subtitle-2"
        onClick={ handleEditBtnClick }
        variant="secondary"
      >
        { showEditBtn === name ? (isEditAllowed ? '[edit]' : '[view]') : null }
      </Link>
    </div>
  );
};

AudienceToggle.propTypes = {
  audienceHandle: PropTypes.string.isRequired,
  audiencesType: PropTypes.string,
  initiativeHandle: PropTypes.string.isRequired,
  isEditAllowed: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default AudienceToggle;
