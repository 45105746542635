import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

const TextAreaField = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      { ...field }
      { ...props }
      fullWidth={ true }
      error={ !!(meta.touched && meta.error) }
      helperText={ meta.error }
      label={ label }
      multiline={ true }
      rows="5"
      className={ className }
    />
  );
};

TextAreaField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

TextAreaField.defaultProps = {
  className: null,
  label: '',
};

export default TextAreaField;
