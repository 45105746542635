import React, { useCallback, useEffect } from 'react';
import styles from '../../index.module.scss';
import PropTypes from 'prop-types';
import { Button } from '@nike/eds';

const CategoryContentToggle = ({
  showCategoryContent,
  setShowCategoryContent,
  isRepeatedCategory,
  showToggle = true,
}) => {
  const handleEditBtnClick = useCallback(() => {
    setShowCategoryContent(!showCategoryContent);
  }, [showCategoryContent]);

  useEffect(() => {
    if (isRepeatedCategory) {
      setShowCategoryContent(false);
    }
  }, [isRepeatedCategory]);

  return (
    !isRepeatedCategory &&
    showToggle && (
      <div className={ styles.editCategoryContentBtn }>
        <Button
          font="subtitle-2"
          size="medium"
          variant="ghost"
          onClick={ handleEditBtnClick }
        >
          { !showCategoryContent ? 'Edit' : 'Hide' } Category Display Properties
        </Button>
      </div>
    )
  );
};

CategoryContentToggle.propTypes = {
  isRepeatedCategory: PropTypes.bool,
  setShowCategoryContent: PropTypes.func,
  showCategoryContent: PropTypes.bool,
  showToggle: PropTypes.bool,
};

export default CategoryContentToggle;
