import { TextField } from '@material-ui/core';
import React from 'react';
import styles from './DevView.module.scss';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Button, Spinner } from '@nike/eds';

const SearchBar = ({
  jobSearch = false,
  isFetching = false,
  searchValue,
  handleSearchChange,
  handleSearchSubmit,
  disabled,
}) => {
  return (
    <div className={ styles.searchWrapper }>
      <TextField
        placeholder={ !jobSearch ? 'Search for handles' : 'Search by Job ID' }
        variant="outlined"
        className={ styles.searchField }
        onChange={ handleSearchChange }
        value={ searchValue }
        InputProps={{
          startAdornment: <Search className={ styles.searchIcon } />,
        }}
      />
      <Button
        size="small"
        onClick={ handleSearchSubmit }
        disabled={ disabled }
        afterSlot={
          isFetching ? (
            <div className="eds--dark">
              <Spinner />
            </div>
          ) : null
        }
      >
        Submit
      </Button>
    </div>
  );
};

SearchBar.propTypes = {
  disabled: PropTypes.bool,
  handleSearchChange: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  jobSearch: PropTypes.bool,
  searchValue: PropTypes.string.isRequired,
};

export default SearchBar;
