/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  usePatchAudienceMutation,
  useFetchAudienceQuery,
  useFetchUsersQuery,
  useDeleteUserMutation,
  useCreateAudienceMemberMutation,
  useCreateAudienceMemberWithAthleteIdMutation,
} from 'features/adminApi/endpoints/audiences';
import {
  useFetchInitiativeAudienceQuery,
  useCreateInitiativeAudienceMemberMutation,
  useDeleteInitiativeAudienceUserMutation,
  useFetchInitiativeAudienceUsersQuery,
  usePatchInitiativeAudienceMutation,
  useCreateInitAudienceMemberWithAthleteIdMutation,
} from 'features/adminApi/endpoints/initiativeAudiences';
import { ViewMode } from 'lib/enums';
import AudienceFormWrapper from '../AudienceFormWrapper';
import AudienceMemberList from '../AudienceMemberList';
import { useFetchAudienceInitiativesQuery } from 'features/adminApi/endpoints/audienceInitiatives';
import { modifyDataBasedOnPreference } from 'views/Initiative/Experiences/utils';
import { isArray } from 'lodash';
import { AudienceTypesEnum } from 'utils';
import { filter, partition, propEq } from 'ramda';
import { Text } from '@nike/eds';
import FormContainer from 'components/FormContainer';

const Edit = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { audienceType: tabAudienceName, initiativeHandle } =
    location.state || {};
  const { addToast } = useToasts();
  const { data, isLoading: isLoadingFetch } =
    tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
      ? useFetchInitiativeAudienceQuery({
        initiativeHandle,
        audienceHandle: id,
      })
      : useFetchAudienceQuery(id);

  const { data: userData, isLoading: isLoadingUsers } =
    tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
      ? useFetchInitiativeAudienceUsersQuery({
        audienceHandle: id,
        initiativeHandle,
      })
      : useFetchUsersQuery(id);
  const [patchAudience, audienceResult] = usePatchAudienceMutation();
  const [patchInitiativeAudience, initiativeAudienceResult] =
    usePatchInitiativeAudienceMutation();
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();
  const [deleteInitiativeAudienceUser, deleteInitiativeAudienceUserResult] =
    useDeleteInitiativeAudienceUserMutation();

  const [showAddMember, setShowAddMember] = useState(false);
  const [createAudienceMember] = useCreateAudienceMemberMutation();
  const [createAudienceMemberWithAthleteId] =
    useCreateAudienceMemberWithAthleteIdMutation();
  const [createInitiativeAudienceMember] =
    useCreateInitiativeAudienceMemberMutation();
  const [createInitiativeAudienceMemberWithAthleteId] =
    useCreateInitAudienceMemberWithAthleteIdMutation();
  const [setFieldValueState, setSetFieldValueState] = useState(null);
  const [addIdsPreferenceField, setAddIdsPreferenceField] = useState('upmid');
  const [invalidIdExists, setInvalidExists] = useState(false);
  const [conflictTrueMembers, setConflictTrueMembers] = useState([]);
  const [successFalseMembers, setSuccessFalseMembers] = useState([]);
  const [successTrueMembers, setSuccessTrueMembers] = useState([]);
  const [description, setDescription] = useState(false);

  const { data: audienceData } = useFetchAudienceInitiativesQuery(id, {
    skip: !data || data?.audienceType !== 'Dynamic',
  });

  const [audienceMemberResult, setAudienceMemberResult] = useState([]);

  const hasUsers = !!userData?.users?.length;

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const showToast = (message, appearance) => {
    const options = {
      appearance,
      autoDismiss: true,
    };
    if (appearance === 'info') {
      options.autoDismissTimeout = 8000;
    }
    addToast(message, options);
  };

  const isValidArray = (array) =>
    isArray(array) &&
    array.length > 0 &&
    array.every((uuid) => uuid.trim() !== '');

  const addUserToAudience = useCallback(
    async (values) => {
      const addMemberConflictError = 409;
      const handleAudienceMembersById = async (members, idKey) => {
        const results = await Promise.allSettled(
          members.map(async (id) => {
            const { handle } = values;
            const data = { [idKey]: id, handle };
            const initiativeAudienceData =
              tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
                ? {
                  [idKey]: id,
                  audienceHandle: handle,
                  initiativeHandle,
                }
                : null;

            try {
              const result = await (idKey === 'upmId'
                ? tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
                  ? createInitiativeAudienceMember(initiativeAudienceData)
                  : createAudienceMember(data)
                : tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
                  ? createInitiativeAudienceMemberWithAthleteId(
                    initiativeAudienceData
                  )
                  : createAudienceMemberWithAthleteId(data));
              if (
                result.data?.handle === handle ||
                result.error?.status === addMemberConflictError
              ) {
                //Remove users added or already part of audience from input field
                setAudienceMemberResult((prevResult) => [...prevResult, id]);
                if (result.error?.status === addMemberConflictError) {
                  setInvalidExists(false);
                  return { id, conflict: true };
                }
                return { id, success: true };
              }
              setInvalidExists(true);
              return { id, success: false };
            } catch (error) {
              setInvalidExists(true);
              return { id, success: false };
            }
          })
        );

        const audienceMemberResults = results.map((result) => {
          if (result.status === 'fulfilled') {
            return result.value;
          }
          return result.reason;
        });

        const [conflictMemberResults, filteredData] = partition(
          propEq('conflict', true),
          audienceMemberResults
        );
        const conflictIds = conflictMemberResults.map((item) => item.id);

        const successTrue = filter(propEq('success', true), filteredData);
        const successFalse = filter(propEq('success', false), filteredData);

        setConflictTrueMembers(conflictIds);
        setSuccessFalseMembers(successFalse);
        setSuccessTrueMembers(successTrue);

        filteredData.forEach(({ id, success }) => {
          const chipElement = document.getElementById(`chip-${ id }`);
          if (chipElement) {
            const spanElement = chipElement.querySelector('span');
            if (spanElement) {
              spanElement.style.color = success
                ? 'var(--eds-color-green-50)'
                : 'var(--eds-color-brand-red)';
            }
          }
        });
      };

      if (isValidArray(values.upmids)) {
        await handleAudienceMembersById(values.upmids, 'upmId');
      } else if (isValidArray(values.athleteids)) {
        await handleAudienceMembersById(values.athleteids, 'athleteId');
      }
    },
    [
      createAudienceMember,
      createAudienceMemberWithAthleteId,
      createInitiativeAudienceMember,
      createInitiativeAudienceMemberWithAthleteId,
      setAudienceMemberResult,
    ]
  );

  const handlePatchAudience = useCallback(async (values) => {
    const {
      audienceType,
      description,
      handle,
      name,
      ppiDefinition,
      setFieldValue,
      addIdsPreference = 'upmid',
    } = values;
    if (tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE) {
      patchInitiativeAudience({
        audienceType,
        description,
        audienceHandle: handle,
        name,
        ppiDefinition,
        initiativeHandle,
      });
    } else {
      patchAudience({
        audienceType,
        description,
        handle,
        name,
        ppiDefinition,
      });
    }
    const modifiedData = modifyDataBasedOnPreference(values);
    addUserToAudience(modifiedData);
    setSetFieldValueState(() => setFieldValue);
    setAddIdsPreferenceField(addIdsPreference);
    setDescription(description);
  }, []);

  const handleRemove = useCallback(async (upmId) => {
    if (tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE) {
      await deleteInitiativeAudienceUser({
        audienceHandle: id,
        initiativeHandle,
        upmId,
      });
    } else await deleteUser({ id, upmId });
  }, []);

  const showAddMemberRef = useRef(false);

  useEffect(() => {
    const shouldShowAddMember = data?.audienceType === 'Static' && !hasUsers;

    if (!showAddMemberRef.current && shouldShowAddMember) {
      setShowAddMember(true);
      showAddMemberRef.current = true;
    } else if (showAddMemberRef.current && !shouldShowAddMember) {
      if (!invalidIdExists) {
        setShowAddMember(false);
        showAddMemberRef.current = false;
      }
    }
  }, [invalidIdExists, data, hasUsers]);

  //Edit Static/Initiative Audience's description field success handling
  useEffect(() => {
    const isSuccess =
      audienceResult.isSuccess || initiativeAudienceResult.isSuccess;
    if (
      isSuccess &&
      data?.description !== description &&
      data?.audienceType !== 'Dynamic '
    ) {
      showToast('Description changed successfully', 'success');
    }
  }, [
    audienceResult,
    initiativeAudienceResult,
    data?.description,
    data?.audienceType,
    description,
  ]);

  //Edit Dynamic Audience success & error handling
  useEffect(() => {
    const { isSuccess, error, isError } = audienceResult || {};
    if (data?.audienceType === 'Dynamic') {
      if (isSuccess) {
        showToast('Dynamic Audience form saved successfully', 'success');
      } else if (isError) {
        showToast(error?.data?.errorMessage, 'error');
      }
    }
  }, [audienceResult, data?.audienceType]);

  const cleanup = useCallback(() => {
    setSuccessFalseMembers([]);
    setSuccessTrueMembers([]);
    setConflictTrueMembers([]);
  }, []);

  //Edit Audience and AudienceMembers with upmId and athleteId success & error handling
  useEffect(() => {
    if (successTrueMembers.length > 0) {
      showToast(
        `Member(s) have been successfully added to the audience and can now be found in the table. 
        If not found, please refresh the page to view`,
        'success'
      );
      if (!(successFalseMembers.length > 0)) {
        setShowAddMember(false);
        setFieldValueState(
          addIdsPreferenceField === 'upmid' ? 'upmids' : 'athleteids',
          []
        );
      }
    }

    if (conflictTrueMembers.length > 0) {
      const formattedIds = conflictTrueMembers.map((id) => (
        <Text key={ id } font="subtitle-2" as="span">
          { id }
        </Text>
      ));
      showToast(
        <Text font="body-3">
          Audience user(s): { formattedIds }
          with the specified details already exist
        </Text>,
        'info'
      );

      if (!(successFalseMembers.length > 0)) {
        setShowAddMember(false);
        setFieldValueState(
          addIdsPreferenceField === 'upmid' ? 'upmids' : 'athleteids',
          []
        );
      }
    }

    if (successFalseMembers.length > 0) {
      showToast('One or more members cannot be added due to an error', 'error');
      setShowAddMember(true);
    }

    if (
      successFalseMembers.length > 0 ||
      successTrueMembers.length > 0 ||
      conflictTrueMembers.length > 0
    ) {
      cleanup();
    }
  }, [
    addIdsPreferenceField,
    successFalseMembers,
    successTrueMembers,
    conflictTrueMembers,
    cleanup,
  ]);

  //Delete static/initiative audience members success and error handling
  useEffect(() => {
    const { isSuccess, error } = deleteUserResult || {};
    const { isSuccess: initAudienceIsSuccess, error: initAudienceIsError } =
      deleteInitiativeAudienceUserResult || {};

    const isDeleteUserSuccess = isSuccess || initAudienceIsSuccess;
    const errorMessage =
      error?.data?.errorMessage || initAudienceIsError?.data?.errorMessage;

    if (isDeleteUserSuccess || errorMessage) {
      showToast(
        isDeleteUserSuccess ? 'Member(s) successfully removed' : errorMessage,
        isDeleteUserSuccess ? 'success' : 'error'
      );
    }
  }, [deleteUserResult, deleteInitiativeAudienceUserResult]);

  const addMember = useCallback(() => {
    setShowAddMember(true);
  }, []);

  return (
    <>
      <AudienceFormWrapper
        title="Edit Audience"
        disabled={
          audienceResult?.isLoading ||
          initiativeAudienceResult.isLoading ||
          (audienceData &&
            audienceData.length >= 1 &&
            data?.audienceType === 'Dynamic')
        }
        onSubmit={ handlePatchAudience }
        initialValues={ data }
        viewMode={ ViewMode.EDIT }
        isLoading={ !(!isLoadingFetch && data) }
        onCancel={ handleCancel }
        hasUsers={ hasUsers }
        showAddMembers={ showAddMember }
        onTagAdd={ audienceMemberResult }
        audienceType={ tabAudienceName }
      />
      { data?.audienceType === 'Static' && hasUsers && (
        <FormContainer>
          <AudienceMemberList
            handleRemove={ handleRemove }
            createHref={ addMember }
            loading={ isLoadingUsers }
            data={ userData?.users || [] }
            viewMode={ ViewMode.EDIT }
          />
        </FormContainer>
      ) }
    </>
  );
};

export default Edit;
