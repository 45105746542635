import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const ppiApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['ppi'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchPPI: builder.query({
          query() {
            return '/ppis';
          },
          providesTags: (result, error, handle) => [
            { type: 'ppi', id: handle },
          ],
          transformResponse: (response) => pathOr([], ['ppis'], response),
        }),
      };
    },
  });

export const { useFetchPPIQuery } = ppiApiSlice;
