/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { PERMISSIONS_BY_ROLES_CONFIG } from 'lib/configs';
import { Typography, Container } from '@material-ui/core';
import Title from 'components/Title';
import { DataGrid } from '@mui/x-data-grid';
import Loading from 'components/Loading';
import { kebabCase } from 'lodash';
import { convertHandleToTitle } from 'components/forms/Assets/lib';
import { CheckBox as CheckIcon } from '@material-ui/icons';

import styles from './styles.module.scss';

// eslint-disable-next-line react/prop-types
const ShowIcon = ({ showIcon }) => {
  return showIcon ? <CheckIcon /> : null;
};

const AccessControl = () => {
  const {
    data: { value: permissionsByRole },
    isFetching,
  } = useFetchConfigQuery(PERMISSIONS_BY_ROLES_CONFIG);

  const columns = [
    {
      field: 'entity',
      headerName: 'Entity',
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'read',
      headerName: 'Read',
      flex: 1,
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <ShowIcon showIcon={ params.row.read } />;
      },
    },
    {
      field: 'create',
      headerName: 'Create',
      flex: 1,
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      sort: false,
      renderCell: (params) => {
        return <ShowIcon showIcon={ params.row.create } />;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <ShowIcon showIcon={ params.row.edit } />;
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 1,
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return <ShowIcon showIcon={ params.row.delete } />;
      },
    },
  ];

  const permissionsMatrixData =
    !isFetching &&
    Object.keys(permissionsByRole).map((role) =>
      Object.keys(permissionsByRole[role]).map((entity, idx) => ({
        id: `${ idx }-${ role }`,
        role: convertHandleToTitle(kebabCase(role)),
        entity: convertHandleToTitle(kebabCase(entity)),
        create: permissionsByRole[role][entity].includes('CREATE'),
        read: permissionsByRole[role][entity].includes('READ'),
        edit: permissionsByRole[role][entity].includes('EDIT'),
        delete: permissionsByRole[role][entity].includes('DELETE'),
      }))
    );

  const permissionsTable = permissionsMatrixData.map((rowSet, idx) => {
    return (
      <div key={ idx }>
        <Typography variant="h5" style={{ paddingTop: 20 }}>
          { rowSet[0].role }
        </Typography>
        <DataGrid
          className={ styles.dataGrid }
          rowHeight={ 24 }
          loading={ isFetching }
          hideFooter={ true }
          columns={ columns }
          autoHeight={ true }
          rows={ rowSet || [] }
        />
      </div>
    );
  });

  return (
    <Container>
      <Title title={ 'Dev' } />
      <hr />
      <Typography variant="h2">Permissions by Role</Typography>
      <hr />
      <>{ isFetching ? <Loading text="fetching data" /> : permissionsTable }</>
    </Container>
  );
};

export default AccessControl;
