/* eslint-disable max-statements */
import { Tab, TabGroup } from '@nike/eds';
import { useFetchConsentQuery } from 'features/adminApi/endpoints/consent';
import { useFetchContentQuery } from 'features/adminApi/endpoints/content';
import { useFetchScreenerQuery } from 'features/adminApi/endpoints/screener';
import { useFetchSurveyQuery } from 'features/adminApi/endpoints/survey';
import {
  addAssetType,
  CONSENT_FORM,
  DEFAULT_MEDIA,
  SCREENER_FORM,
  SURVEY_FORM,
} from 'lib/layoutUtils';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { groupByAssetType } from './groupAssets';

const ManageAssets = () => {
  const [activeId, setActiveId] = useState('content');
  const { id: initiativeHandle } = useParams();
  const location = useLocation();

  useEffect(() => {
    setActiveId(
      location?.state?.assetType === DEFAULT_MEDIA
        ? 'content'
        : location?.state?.assetType ?? 'content'
    );
  }, [location]);
  const {
    data: contentData = [],
    isLoading: isContentLoading,
    error: contentError,
  } = useFetchContentQuery(initiativeHandle);

  const {
    data: consentData = [],
    isLoading: isConsentLoading,
    error: consentError,
  } = useFetchConsentQuery(initiativeHandle);

  const {
    data: screenerData = [],
    isLoading: isScreenerLoading,
    error: screenerError,
  } = useFetchScreenerQuery(initiativeHandle);

  const {
    data: surveyData = [],
    isLoading: isSurveyLoading,
    error: surveyError,
  } = useFetchSurveyQuery(initiativeHandle);

  const consolidatedData = [
    ...contentData.map((data) =>
      addAssetType(data, 'content', data.contentType)
    ),
    ...consentData.map((data) => addAssetType(data, 'consent', CONSENT_FORM)),
    ...screenerData.flatMap((data) => {
      return data.inquiryType === 'screener'
        ? [addAssetType(data, 'screener', SCREENER_FORM)]
        : [];
    }),
    ...surveyData.map((data) => addAssetType(data, 'survey', SURVEY_FORM)),
  ];

  const handleChange = useCallback((event) => {
    setActiveId(event.target.id);
  }, []);

  const isLoading = [
    isContentLoading,
    isScreenerLoading,
    isConsentLoading,
    isSurveyLoading,
  ].includes(true);

  const error =
    [contentError, screenerError, consentError, surveyError].some === true;

  return (
    <>
      <TabGroup
        name="manage-assets-tabs"
        activeId={ activeId }
        onChange={ handleChange }
      >
        <Tab id="content">Content</Tab>
        <Tab id="consent-document">Consent Form</Tab>
        <Tab id="screener">Screener</Tab>
        <Tab id="survey">Survey</Tab>
      </TabGroup>

      { groupByAssetType(consolidatedData, activeId, initiativeHandle, {
        isLoading,
        error,
        contentType: location?.state?.assetType,
      }) }
    </>
  );
};

export default ManageAssets;
