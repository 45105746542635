/* eslint-disable react/jsx-no-bind */
// modules
import React from 'react';
import { Container } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreateAudiences from './Audiences/CreateAudiences';
import NotFound from 'components/NotFound';
import Polls from 'views/Initiative/Polls';
import Audiences from 'views/Initiative/Audiences';
import ExperienceListView from 'views/Initiative/Experiences/components/List';
import EditExperience from 'views/Initiative/Experiences/components/Edit';
import CreateExperience from 'views/Initiative/Experiences/components/Create';
import DetailExperience from 'views/Initiative/Experiences/components/DetailView';
import ManageAssets from 'views/Initiative/Experiences/components/ManageAssets';
import Candidates from 'views/Initiative/Experiences/components/Candidates';
import withRolePermissions from 'lib/hooks/withRolePermissions';
import withEntityPermissions from 'lib/hooks/withEntityPermissions';
import AssetWrapper from 'views/Initiative/Experiences/components/ManageAssets/AssetWrapper';
import { ViewMode } from 'lib/enums';
import ConfigFieldsListView from './ConfigurationFields/List';
import EditConfigField from './ConfigurationFields/Edit';
import CreateConfigField from './ConfigurationFields/Create';
import Landing from './landing';
import Edit from 'views/Audience/components/Edit';
import DetailView from 'views/Audience/components/View';
const Initiative = () => {
  return (
    <Container>
      <Switch>
        <Route path="/initiative" exact={ true } component={ Landing } />
        <Route
          path="/:entity/:initiativeType"
          exact={ true }
          component={ ExperienceListView }
        />
        <Route path="/initiative/polls" component={ Polls } />
        <Route path="/initiative/activity-survey" component={ Polls } />
        <Route
          path="/initiative/:initiativeType/:id/fields"
          exact={ true }
          component={ ConfigFieldsListView }
        />
        <Route
          path="/initiative/:initiativeType/:id/fields/create"
          exact={ true }
          component={ CreateConfigField }
        />
        <Route
          path="/initiative/:initiativeType/:id/fields/:handle"
          component={ EditConfigField }
        />
        <Route
          path="/initiative/:initiativeType/:id/assets"
          component={ ManageAssets }
        />
        <Route
          path="/initiative/:initiativeType/audiences"
          exact={ true }
          component={ () => <Redirect to="/audience" /> }
        />
        <Route
          path="/initiative/:initiativeType/:handle/audiences"
          exact={ true }
          component={ withRolePermissions(Audiences) }
        />
        <Route
          path="/initiative/:initiativeType/:initiativeHandle/:entity/edit/:id"
          exact={ true }
          component={ Edit }
        />

        <Route
          path="/initiative/:initiativeType/:id/create/audiences"
          exact={ true }
          component={ CreateAudiences }
        />

        { /*After creating the initiative audience in the initiative creation step,
        we need to render the same UI view in the "Audiences" component while continuing
        with the initiative creation process. So added this alternate route to reuse*/ }
        <Route
          path="/initiative/:initiativeType/:viewMode/:id/audiences"
          exact={ true }
          component={ withRolePermissions(Audiences) }
        />
        <Route
          path="/initiative/:initiativeType/:initiativeHandle/audience/:id"
          exact={ true }
          component={ DetailView }
        />
        <Route
          path="/:entity/:initiativeType/:id/candidates"
          component={ Candidates }
        />
        <Route
          path="/:entity/:initiativeType/delete/:id"
          exact={ true }
          component={ withEntityPermissions(EditExperience, ViewMode.DELETE) }
        />
        <Route
          path="/:entity/:initiativeType/:viewMode/:id"
          exact={ true }
          render={ ({ match }) => {
            const { viewMode } = match.params;
            const ExperienceRoute =
              viewMode === 'edit'
                ? withEntityPermissions(EditExperience, ViewMode.EDIT)
                : withEntityPermissions(DetailExperience, ViewMode.READ);
            return <ExperienceRoute />;
          } }
        />
        <Route
          path="/initiative/:initiativeType/:viewMode/:id/assets/create/default-media"
          exact={ true }
          component={ withRolePermissions(AssetWrapper) }
        />
        <Route path="/initiative" exact={ true } component={ ExperienceListView } />
        <Route
          path="/:entity/:initiativeType/create"
          exact={ true }
          component={ withEntityPermissions(CreateExperience, ViewMode.CREATE) }
        />
        <Route
          path="/:entity/:initiativeType/:id"
          exact={ true }
          component={ withEntityPermissions(DetailExperience, ViewMode.READ) }
        />
        <Route component={ NotFound } />
      </Switch>
    </Container>
  );
};

export default Initiative;
