export const PERMISSIONS_BY_ROLES_CONFIG = 'config-admin-permission-roles';
export const WEB_FRONTEND_URLS = 'config-frontend-urls';
export const PPI_ANSWER_OPTIONS = 'config-ppi-answer-options';
export const KNOWN_INQUIRY_VARIABLES = 'config-known-inquiry-variables';

export const config = [
  {
    id: 1,
    handle: 'config-ios-data',
    version: '0.0.1',
    isCurrent: true,
  },
  {
    id: 2,
    handle: 'config-vl-to-l2w-initiative-handle-map',
    version: '0.0.1',
    isCurrent: true,
  },
  {
    id: 3,
    handle: 'config-oscar-scopes',
    version: '1.0.0',
    isCurrent: true,
  },
  {
    id: 4,
    handle: 'config-accounts-clients',
    version: '1.0.0',
    isCurrent: true,
  },
  {
    id: 5,
    handle: 'config-oscar-clients',
    version: '1.0.0',
    isCurrent: true,
  },
  {
    id: 6,
    handle: 'config-admin-permission-roles',
    version: 1,
    isCurrent: true,
  },
  {
    id: 7,
    handle: 'config-l2w-service-names',
    version: '0.0.1',
    isCurrent: true,
  },
  {
    id: 8,
    handle: 'config-uls-user-delete-slack-notify',
    version: '0.0.1',
    isCurrent: true,
  },
  {
    id: 9,
    handle: 'config-new-test',
    version: 1,
    isCurrent: true,
  },
  {
    id: 10,
    handle: 'config-onboarding-cms',
    version: '0.0.1',
    isCurrent: true,
  },
  {
    id: 11,
    handle: 'config-onboarding-cms-en-us',
    version: '0.0.1',
    isCurrent: false,
  },
  {
    id: 12,
    handle: 'config-onboarding-cms-en-us',
    version: '0.0.2',
    isCurrent: true,
  },
  {
    id: 13,
    handle: 'config-frontend-urls',
    version: '1',
    isCurrent: true,
  },
];

export const initiativeFields = [
  {
    id: 'id-1',
    handle: 'field-handle-1',
    initiativeHandle: 'digital-service',
    description: 'desc-1',
  },
  {
    id: 'id-2',
    handle: 'field-handle-2',
    initiativeHandle: 'digital-service',
    description: 'desc-2',
  },
  {
    id: 'id-3',
    handle: 'field-handle-3',
    initiativeHandle: 'digital-service',
    description: 'desc-3',
  },
  {
    id: 'id-4',
    handle: 'field-handle-4',
    initiativeHandle: 'digital-service',
    description: 'desc-4',
  },
  {
    id: 'id-5',
    handle: 'field-handle-5',
    initiativeHandle: 'digital-service',
    description: 'desc-5',
  },
  {
    id: 'id-6',
    handle: 'field-handle-6',
    initiativeHandle: 'digital-service',
    description: 'desc-6',
  },
  {
    id: 'id-7',
    handle: 'field-handle-7',
    initiativeHandle: 'digital-service',
    description: 'desc-7',
  },
  {
    id: 'id-8',
    handle: 'field-handle-8',
    initiativeHandle: 'digital-service',
    description: 'desc-8',
  },
  {
    id: 'id-9',
    handle: 'field-handle-9',
    initiativeHandle: 'digital-service',
    description: 'desc-9',
  },
  {
    id: 'id-10',
    handle: 'field-handle-10',
    initiativeHandle: 'digital-service',
    description: 'desc-10',
  },
  {
    id: 'id-11',
    handle: 'field-handle-11',
    initiativeHandle: 'digital-service',
    description: 'desc-11',
  },
  {
    id: 'id-12',
    handle: 'field-handle-12',
    initiativeHandle: 'digital-service',
    description: 'desc-12',
  },
];

export const initiativeField = {
  id: 'id-12',
  handle: 'field-handle-12',
  initiativeHandle: 'digital-service',
  description: 'desc-12',
  value: {
    fieldHandleProperty1: 'fieldHandleValue1',
  },
};
