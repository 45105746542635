import { configureStore } from '@reduxjs/toolkit';
import { innovationApiSlice } from './features/innovationApi/innovationApiSlice';
import { adminApiSlice } from './features/adminApi/adminApiSlice';

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      [innovationApiSlice.reducerPath]: innovationApiSlice.reducer,
      [adminApiSlice.reducerPath]: adminApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(innovationApiSlice.middleware)
        .concat(adminApiSlice.middleware);
    },
    preloadedState,
  });
};
