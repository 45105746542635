/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AssetList } from './views';
import AssetWrapper from './AssetWrapper';
import withRolePermissions from 'lib/hooks/withRolePermissions';

const ManageAssets = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={ path } exact={ true } component={ AssetList } />
      <Route
        path={ '/initiative/:initiativeType/:id/assets/:viewMode/:type' }
        exact={ true }
        component={ withRolePermissions(AssetWrapper) }
      />
    </Switch>
  );
};

export default ManageAssets;
