import pt from 'prop-types';
import React from 'react';
import { Icon, AppHeader, StatusIndicator } from '@nike/eds';
import { capitalize } from '@vl/js-lib/browser/string';

const CardToolbar = ({ title = '', status = 'inactive' }) => {
  const statusMap = {
    live: 'success',
    inactive: 'warning',
  };
  return (
    <AppHeader
      style={{ padding: '0 8px', backgroundColor: '#fff' }}
      logoSlot={ <Icon size="l" name="GiftCard" /> }
      toolbarSlot={
        <StatusIndicator
          label={ capitalize(status) }
          size="large"
          status={ statusMap[status] }
        />
      }
      appName={ title }
    />
  );
};

CardToolbar.propTypes = {
  status: pt.string,
  title: pt.string,
};

export default CardToolbar;
