import React from 'react';
import config from 'conf';
import { ViewMode, EntityType } from 'lib/enums';
import { InitiativeTypesEnum } from 'utils';
import { pluck } from 'ramda';
import { NikeApp } from '@nike/nike-design-system-icons';

//retrieves the handle from the path if visited through deeplink
//TODO: checks can be avoided if all routes are configured in similar way
// eslint-disable-next-line complexity,max-statements
export const retrieveHandleFromURL = (path) => {
  const pathArray = path.split('/').filter(Boolean);
  const pathArrLength = pathArray.length;
  if (path) {
    if (path.includes('/assets')) {
      const pathIndex =
        pathArray.indexOf('assets') || pathArray.indexOf('candidates');
      const handleIndex = pathIndex > 0 && pathIndex - 1;
      return pathArray[handleIndex];
    } else if (pathArray.indexOf('candidates') === pathArrLength - 1) {
      return pathArray[pathArrLength - 2];
    } else if (path.includes('/fields')) {
      return pathArray[pathArrLength - 2].includes('fields')
        ? pathArray[pathArrLength - 3]
        : pathArray[pathArrLength - 2];
    } else if (path.endsWith('/create')) {
      return '';
    } else if (path.endsWith('/audiences')) {
      if (path.includes('/create/')) {
        return '';
      }
      return pathArray[pathArrLength - 2];
    } else if (path.includes('/audiences')) {
      if (path.includes('/edit/')) {
        return pathArray[pathArrLength - 4];
      }
    } else if (
      path.endsWith('/initiative/digital-service') ||
      path.endsWith('/initiative/core') ||
      path.endsWith('/initiative/activity-survey')
    ) {
      return '';
    } else if (path.includes('/initiative/')) {
      if (path.includes('/audience/')) return pathArray[2];
      // checking for view, edit, delete and audiences route in init's URL
      return pathArray[pathArrLength - 1];
    }
    return '';
  }
  return '';
};

export const actions = (initiativeType, handle = null) => {
  const allActions = [
    {
      key: 'id',
      url: `/initiative/${ initiativeType }/view/${ handle ? handle : '' }`,
      label: 'view',
      action: ViewMode.READ,
      entity: EntityType.INITIATIVE,
    },
    {
      key: 'id',
      url: `/initiative/${ initiativeType }/edit/${ handle ? handle : '' }`,
      label: 'edit',
      action: ViewMode.EDIT,
      entity: EntityType.INITIATIVE,
    },
    {
      key: 'handle',
      url: `/initiative/${ initiativeType }/${
        handle ? handle : ':handle'
      }/audiences`,
      label: 'audiences',
      action: ViewMode.READ,
      entity: EntityType.AUDIENCE,
    },
    {
      key: 'handle',
      url: `/initiative/${ initiativeType }/${
        handle ? handle : ':handle'
      }/candidates`,
      label: 'candidate review',
      action: ViewMode.EDIT,
      entity: EntityType.ASSET_SCREENER,
    },
    {
      key: 'handle',
      url: `/initiative/${ initiativeType }/${
        handle ? handle : ':handle'
      }/assets`,
      label: 'manage assets',
      action: ViewMode.READ,
      entity: EntityType.INITIATIVE,
    },
    {
      key: 'handle',
      label: 'configuration fields',
      url: `/initiative/${ initiativeType }/${
        handle ? handle : ':handle'
      }/fields`,
      action: ViewMode.EDIT,
      entity: EntityType.INITIATIVE,
    },
    {
      key: 'id',
      url: `/initiative/${ initiativeType }/delete/${ handle ? handle : '' }`,
      label: 'delete',
      action: ViewMode.DELETE,
      entity: EntityType.INITIATIVE,
    },
  ];

  const lookupActionsByInitiativeType = (initiativeType, allActions) => {
    switch (initiativeType) {
      case InitiativeTypesEnum.QTX:
        return ['view', 'edit', 'audiences'];
      default:
        return pluck('label', allActions);
    }
  };

  const returnActions = allActions.filter((action) =>
    lookupActionsByInitiativeType(initiativeType, allActions).includes(
      action.label
    )
  );

  return returnActions;
};

export const currentEnvURL = (configURLs) => {
  const BASE_URL = config.ADMIN_API_HOST;
  const webFrontEndURLsArr = [];
  const envs = Object.keys(configURLs);
  webFrontEndURLsArr.push(JSON.parse(JSON.stringify(configURLs)));
  return envs
    .map((env) => {
      return webFrontEndURLsArr
        .map(
          (currentEnvURL) =>
            currentEnvURL[env].adminConsoleURL === BASE_URL &&
            currentEnvURL[env].webFrontEndURL
        )
        .filter(Boolean);
    })
    .flat()[0];
};

export const navPanelSubItems = [
  {
    id: '1a',
    label: 'Initiatives',
    icon: 'Experiments',
    active: false,
    value: 'initiative',
  },
  {
    id: '1b',
    label: 'Audiences',
    icon: 'Club',
    active: false,
    value: 'audience',
  },
  {
    id: '1c',
    label: 'Data Requests',
    icon: 'Delete',
    active: false,
    value: 'dataRequests',
  },
  {
    id: '1d',
    label: 'Cache',
    icon: 'Data',
    active: false,
    value: 'cache',
  },
  {
    id: '1e',
    label: 'Developer Tools',
    icon: 'QRCode',
    active: false,
    value: 'dev',
  },
];

export const navPanelItems = [
  {
    id: '1',
    icon: <NikeApp width={ 48 } height={ 48 } />,
    label: 'QuickView',
    active: false,
    value: '',
    subItems: navPanelSubItems,
  },
  {
    id: '2',
    active: false,
    icon: '',
    label: '',
    value: '',
    subItems: undefined,
  },
];
