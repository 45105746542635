/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Checkbox as NikeCheckbox } from '@nike/eds';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import styles from './index.module.scss';

export const Checkbox = (props) => {
  const [{ value, ...field }] = useField(props);
  return (
    <div className={ styles.checkbox }>
      <NikeCheckbox
        label={ props.label }
        value={ props.value }
        disabled={ props.disabled }
        checked={ (value || []).includes(props.value) }
        { ...field }
      />
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
export const CheckboxSet = ({ label, name, options, disabled, required }) => {
  return (
    <FieldWrapper>
      <div className={ styles.label }>
        <Text font="title-6" as="h6">
          <label>
            { label }
            { required && <span className="asterisk">*</span> }
          </label>
        </Text>
      </div>
      <div className={ styles.checkboxGroup }>
        { options.map((data, i) => (
          <Checkbox
            key={ i }
            name={ name }
            label={ data.label }
            value={ data.value }
            disabled={ disabled }
          />
        )) }
      </div>
    </FieldWrapper>
  );
};

CheckboxSet.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  required: PropTypes.bool,
};

export default CheckboxSet;
