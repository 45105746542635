import styled from '@emotion/styled';

export const AssetGrid = styled.div`
  // mobile styles
  margin: 24px auto;
  display: grid;
  gap: var(--eds-space-24);
  grid-template-columns: repeat(1, 1fr);

  // desktop styles
  @media (min-width: 900px) {
    ${ props =>
    `grid-template-columns: repeat(${ props.count === 1 ? 1 : 2 }, 1fr)` }
  }
`;

export default AssetGrid;
