import { adminApiSlice } from '../adminApiSlice';

adminApiSlice.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['user'],
  endpoints(builder) {
    return {
      deleteUserApiCache: builder.mutation({
        query: () => ({
          url: '/caches/user-api',
          method: 'DELETE',
        }),
        invalidatesTags: [],
      }),
    };
  },
});
export const { useDeleteUserApiCacheMutation } = adminApiSlice;
