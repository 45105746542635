import { useFetchAudienceQuery } from 'features/adminApi/endpoints/audiences';
import { useFetchInitiativeAudienceQuery } from 'features/adminApi/endpoints/initiativeAudiences';
import { ViewMode } from 'lib/enums';
import React, { useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AudienceFormWrapper from '../AudienceFormWrapper';
import { AudienceTypesEnum } from 'utils';

const DetailView = () => {
  const { id: audienceHandle, initiativeType, initiativeHandle } = useParams();
  const history = useHistory();

  const location = useLocation();
  const { audienceType } = location.state || {};

  const { data, isFetching } =
    audienceType === AudienceTypesEnum.INIT_AUDIENCE
      ? useFetchInitiativeAudienceQuery({
        initiativeHandle,
        audienceHandle,
      })
      : useFetchAudienceQuery(audienceHandle);

  const handleSubmit = useCallback(() => {
    history.push({
      pathname: initiativeHandle
        ? `/initiative/${ initiativeType }/${ initiativeHandle }/audience/edit/${ audienceHandle }`
        : `/audience/edit/${ audienceHandle }`,
      state: { audienceType, initiativeHandle },
    });
  }, [history]);

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <AudienceFormWrapper
      title="Audience Detail"
      onSubmit={ handleSubmit }
      onCancel={ handleCancel }
      initialValues={ data }
      viewMode={ ViewMode.READ }
      isLoading={ !(!isFetching && data) }
      submitText={ 'Edit' }
    />
  );
};

export default DetailView;
