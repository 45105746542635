/* eslint-disable react/prop-types */
// modules
import React, { useCallback, useEffect } from 'react';

import { StatusIndicator, Text } from '@nike/eds';
import { capitalize } from '@vl/js-lib/browser/string';
import { FormContainer } from 'components/FormContainer';
// aliased
import { ConfigForm } from 'components/forms';
import {
  useFetchConfigQuery,
  usePatchConfigMutation,
} from 'features/adminApi/endpoints/config';
import { ViewMode } from 'lib/enums';
import { useScroll } from 'lib/util.js';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from './styles.module.scss';

export const filter = (data, id) =>
  data?.find((initiative) => initiative.handle === id);

//eslint-disable-next-line max-statements
const EditConfig = () => {
  const { id } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();
  const [elRef] = useScroll();

  // EXPERIENCE REDUX API HOOKS ----------------------------------------------------------
  const [usePatchConfig, patchConfigResult] = usePatchConfigMutation();
  const { configHandle } = useParams();
  const { data: configData, isFetching } = useFetchConfigQuery(configHandle);

  // API CALLS ----------------------------------------------------------------
  const handleFormSubmit = useCallback(async (values) => {
    const convertedvalues = {
      name: values.name,
      handle: values.handle,
      value: values.value,
      version: values.version,
    };
    usePatchConfig(convertedvalues);
  }, []);

  // HANDLE SUCCESSFUL EDIT REQUEST -------------------------------------------
  useEffect(() => {
    if (patchConfigResult.isSuccess) {
      addToast('Config Properties Saved', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push('/dev/config');
    }
  }, [history, patchConfigResult.isSuccess]);

  // HANDLE EDIT ERROR -------------------------------------------------
  useEffect(() => {
    if (patchConfigResult?.error?.data?.errorMessage) {
      addToast(patchConfigResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchConfigResult?.error?.data?.errorMessage]);

  return (
    <>
      <FormContainer className={ styles.assetContainer }>
        <div className={ styles.assetContainer } ref={ elRef }>
          <Text as="h1" font="title-1" style={{ width: '80%' }}>
            Edit Config { configData?.name }
          </Text>
          <StatusIndicator
            style={{ margin: 8 }}
            label={ capitalize(configData?.status) }
            status={
              { active: 'success', inactive: 'inactive' }[configData?.status]
            }
          />
        </div>
        { !isFetching && configData && (
          <ConfigForm
            disabled={ isFetching }
            viewMode={ ViewMode.EDIT }
            onSubmit={ handleFormSubmit }
            submitText="Edit"
            initialValues={ configData }
            id={ id }
          />
        ) }
      </FormContainer>
    </>
  );
};

export default EditConfig;
