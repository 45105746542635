/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  QuestionSetTypes,
  getFieldValueByFieldName,
  getLastNonConditionalIdx,
  getQuestionSetByFieldName,
  getQuestionSetIndex,
  isCategoryField,
} from '../../RepeatableFieldsetUtils';
import { Field } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import { SelectField } from 'components/fields';
import styles from '../../index.module.scss';
import { isArray, isEmpty } from 'lodash';
import { TooltipContainer } from 'components/fields/SelectWithCriteria/styles';
import TooltipWrapper from 'components/TooltipWrapper';
import { Text } from '@nike/eds';
import CategoryContentToggle from '../CategoryContentToggle';
import {
  initialValuesTypes,
  objectOrArray,
  selectOptionPropType,
} from 'lib/propTypes';
import { filterPPI, getFieldLabel } from '../../lib';
import { useParams } from 'react-router-dom';
import { ViewMode } from 'lib/enums';

export const InquirySelectField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  placeholder,
  disabled,
  formState,
  fieldOptions,
  hasErrors,
  errorMessage,
  onChange,
  isCreatable,
  showCategoryContent,
  setShowCategoryContent,
  errors,
}) => {
  return (
    <FieldWrapper disabled={ disabled }>
      <Field
        key={ fieldId }
        name={ fieldName }
        placeholder={ placeholder || fieldName }
      >
        { ({ field }) => {
          const isConditionalSelect = fieldName.includes(
            QuestionSetTypes.CONDITIONAL_OPTIONS
          );
          const questionSetField = getQuestionSetByFieldName(
            formState,
            fieldName
          );
          const isConditionalToggled = getFieldValueByFieldName(
            questionSetField,
            QuestionSetTypes.CONDITIONAL_TOGGLE
          );
          const shouldRenderSelect =
            (isConditionalSelect && isConditionalToggled) ||
            [
              QuestionSetTypes.EXISTING_PPI,
              QuestionSetTypes.QUESTION_CATEGORY,
            ].includes(field?.name.split('-')[0]);
          const currentIdx = getQuestionSetIndex(formState, fieldName);
          const lastNonConditionalIndex = getLastNonConditionalIdx(
            currentIdx,
            formState
          );
          const lastNonConditionalIndexAnswerOptions = getFieldValueByFieldName(
            formState?.[lastNonConditionalIndex]?.value,
            QuestionSetTypes.ANSWER_OPTIONS
          );

          const isIdxIsUsedInConditional =
            formState?.[currentIdx]?.isUsedInConditional;

          const isSelectDisabled =
            (field?.name.split('-')[0] === QuestionSetTypes.EXISTING_PPI &&
              isIdxIsUsedInConditional) ||
            disabled;

          const conditionalOptions = lastNonConditionalIndexAnswerOptions?.map(
            (value) => {
              if (typeof value === 'string') {
                return {
                  label: value,
                  value,
                };
              }
              return {
                label: value.label || value.name,
                value: value.value,
              };
            }
          );

          const isPPIField = fieldName.includes(QuestionSetTypes.EXISTING_PPI);
          const options = isConditionalSelect
            ? isPPIField
              ? conditionalOptions?.filter(filterPPI)
              : conditionalOptions
            : isPPIField
              ? fieldOptions?.filter(filterPPI)
              : fieldOptions;

          const conditionalValues = getFieldValueByFieldName(
            formState?.[currentIdx]?.value,
            QuestionSetTypes.CONDITIONAL_OPTIONS
          );

          const categoryList =
            !isEmpty(formState) &&
            formState.map(
              (question) =>
                getFieldValueByFieldName(
                  question.value,
                  QuestionSetTypes.QUESTION_CATEGORY
                )?.value ?? null
            );

          const isRepeatedCategory = isArray(categoryList)
            ? categoryList
              .slice(0, currentIdx)
              .includes(categoryList[currentIdx])
            : false;

          const { viewMode } = useParams();

          useEffect(() => {
            const timer = setTimeout(() => {
              const hasCategoryError = Object.keys(errors).some((key) =>
                isCategoryField(key.split('-')[0])
              );
              if (
                !isRepeatedCategory &&
                hasCategoryError &&
                viewMode !== ViewMode.CREATE
              ) {
                setShowCategoryContent(true);
              }
            }, 500);

            return () => clearTimeout(timer);
          }, [errors, isRepeatedCategory]);

          return (
            shouldRenderSelect && (
              <TooltipContainer>
                <TooltipWrapper
                  active={ isSelectDisabled }
                  message={
                    <Text
                      font="subtitle-2"
                      className="eds-spacing--mb-4"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {
                        '⚠️ PPI select is restricted as options are used in a conditional question.'
                      }
                    </Text>
                  }
                  children={
                    <div className={ styles.selectFieldWrapper }>
                      <SelectField
                        hasErrors={ !!hasErrors }
                        errorMessage={ errorMessage }
                        disabled={ isSelectDisabled }
                        name={ fieldName }
                        id={ fieldName }
                        multiple={ !!isConditionalSelect }
                        label={ getFieldLabel(fieldLabel || field.name) }
                        value={
                          fieldValue ||
                          field.value ||
                          (isConditionalSelect ? conditionalValues : {})
                        }
                        options={ options }
                        isCreatable={ isCreatable }
                        onChange={ onChange }
                        isConditionalSelect={ isConditionalSelect }
                        required={ true }
                      />
                      { field?.name
                        .split('-')[0]
                        .includes(QuestionSetTypes.QUESTION_CATEGORY) && (
                        <CategoryContentToggle
                          showCategoryContent={ showCategoryContent }
                          setShowCategoryContent={ setShowCategoryContent }
                          isRepeatedCategory={ isRepeatedCategory }
                          showToggle={ !!(fieldValue || field.value) }
                        />
                      ) }
                    </div>
                  }
                />
              </TooltipContainer>
            )
          );
        } }
      </Field>
    </FieldWrapper>
  );
};

InquirySelectField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldOptions: PropTypes.arrayOf(selectOptionPropType),
  fieldValue: PropTypes.oneOfType([
    selectOptionPropType,
    PropTypes.arrayOf(selectOptionPropType),
    PropTypes.shape({}),
  ]),
  formState: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
  hasErrors: PropTypes.bool,
  isCreatable: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setShowCategoryContent: PropTypes.func,
  showCategoryContent: PropTypes.bool,
};
