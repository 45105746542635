/* eslint-disable max-statements */
/* eslint-disable complexity */
// modules
import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe, isEmpty } from 'ramda';
import { withFormik } from 'formik';
import { TextField, SelectField, Toggle } from 'components/fields';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import { ViewMode } from 'lib/enums';
import CheckboxSet from 'components/fields/CheckboxSet';
import { helperText, trimAndReplace } from '../utils';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import {
  INITIAL_VALUES,
  validationSchema,
  TYPE_OPTIONS,
  POLL_STATUS_OPTIONS,
  PARTICIPATION_STATUS_OPTIONS,
  PLATFORM_OPTIONS,
  PPI_OPTIONS,
} from './lib';
import styles from './index.module.scss';
import {
  activitySurveyPrefix,
  pickAttributeFields,
  pickFields,
  surveyPrefix,
} from './data';
import { isNil } from 'lodash';
import { Button } from '@nike/eds';
import { getRequiredFields } from 'utils';

export default applyTo(
  ({
    disabled,
    viewMode,
    submitText,
    cancelText,
    onCancel,
    handleSubmit,
    errors,
    dirty,
    values,
    setFieldValue,
    touched,
    onSubmit,
  }) => {
    const createPage = viewMode === ViewMode.CREATE;
    useEffect(() => {
      if (values.unlimitedParticipants) {
        setFieldValue('participantLimit', null);
      }
    }, [values.unlimitedParticipants]);

    useEffect(() => {
      if (values.allPpis) {
        // required ppis of null means all ppis to the api,
        // and makes the ui look nicer compared to selecting them all.
        setFieldValue('requiredPpis', null);
      }
    }, [values.allPpis]);

    useEffect(() => {
      if (values.requiredPpis?.length) {
        setFieldValue('allPpis', false);
      }
    }, [values.requiredPpis]);

    useEffect(() => {
      const val = trimAndReplace(values?.name);
      if (!isNil(val) && createPage) setFieldValue('handle', val);
    }, [values?.name]);

    const handleChange = useCallback((ev) => {
      const val = ev.target?.value;
      return setFieldValue('handle', trimAndReplace(val));
    }, []);

    const requiredFields = getRequiredFields(validationSchema);

    return (
      <form onSubmit={ handleSubmit }>
        <SelectField
          name="initiativeType"
          label="Initiative Type"
          disabled={ true }
          options={ TYPE_OPTIONS }
        />
        <TextField
          name="name"
          label="Name"
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.name }
        />
        <MaskedTextField
          name="handle"
          label="Handle"
          onChange={ handleChange }
          handleType={ createPage ? surveyPrefix : activitySurveyPrefix }
          helperText={ helperText }
          createPage={ createPage }
          value={
            !createPage
              ? values?.handle
              : values?.handle || touched?.handle
                ? values?.handle
                : trimAndReplace(values?.name)
          }
          disabled={ !createPage || disabled }
          className={ !createPage ? styles.greyOut : styles.maskedText }
          required={ requiredFields.handle }
        />
        <TextField
          name="shortHandle"
          label="Short Handle"
          helperText={ helperText }
          disabled={ (!createPage && true) || disabled }
          required={ requiredFields.shortHandle }
        />
        <SelectField
          name="status"
          label="Status"
          options={ POLL_STATUS_OPTIONS }
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.status }
        />
        <SelectField
          name="participationStatus"
          label="Participation Status"
          options={ PARTICIPATION_STATUS_OPTIONS }
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.participationStatus }
        />
        <SelectField
          name="requiredPpis"
          label="Required Profile Properties"
          multiple={ true }
          options={ PPI_OPTIONS }
          disabled={ viewMode === ViewMode.READ }
        />
        <Toggle name="allPpis" label="All" />

        <CheckboxSet
          name="platforms"
          label="Platforms"
          supportText="Select which platforms you wish to your poll to support"
          options={ PLATFORM_OPTIONS }
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.platforms }
        />

        <TextField
          name="participantLimit"
          label="Participant Limit"
          disabled={ viewMode === ViewMode.READ || values.unlimitedParticipants }
          type="number"
          required={ !values.unlimitedParticipants }
        />
        <Toggle
          name="unlimitedParticipants"
          label="Unlimited Participants"
          disabled={ viewMode === ViewMode.READ }
        />

        <TextField
          name="surveyId"
          label="Poll Id"
          helperText="Needs to be a valid poll id i.e It should exist on Research API"
          disabled={ viewMode === ViewMode.READ }
        />

        <TextField
          name="url"
          label="Poll URL"
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.url }
        />

        <TextField
          name="numberOfQuestions"
          label="Number of Questions"
          type="number"
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.numberOfQuestions }
        />

        <TextField
          name="timeToCompletion"
          label="Time To Completion"
          type="number"
          disabled={ viewMode === ViewMode.READ }
          required={ requiredFields.timeToCompletion }
        />

        <TextField
          name="description"
          label="Description"
          disabled={ viewMode === ViewMode.READ }
        />

        <TextField
          name="webThreadId"
          label="Web CMS Thread Id"
          disabled={ viewMode === ViewMode.READ }
        />

        <TextField
          name="mobileThreadId"
          label="Mobile CMS Thread Id"
          disabled={ viewMode === ViewMode.READ }
        />

        <div className={ styles.btns }>
          { cancelText && onCancel && (
            <Button variant="secondary" onClick={ onCancel }>
              { cancelText }
            </Button>
          ) }
          { viewMode === ViewMode.READ ? (
            <Button variant="primary" onClick={ onSubmit } type="button">
              { submitText }
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={ !isEmpty(errors) || !dirty }
              type="submit"
            >
              { submitText }
            </Button>
          ) }
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) =>
        (isEmpty(initialValues) ? INITIAL_VALUES : initialValues),
      validationSchema,
      handleSubmit: (
        values,
        { setSubmitting, props: { onSubmit, viewMode } }
      ) => {
        const { handle } = values;

        const activityHandle =
          (viewMode === ViewMode.CREATE && `${ surveyPrefix }-${ handle }`) ||
          handle;

        const modifiedRequest = {
          ...pickFields(values),
          handle: activityHandle,
          attributes: pickAttributeFields(values),
        };

        onSubmit(modifiedRequest);
        setSubmitting(false);
      },

      displayName: 'PollForm',
    }),
    propTypes({
      cancelText: PropTypes.string,
      canceUrl: PropTypes.string,
      handleReset: PropTypes.func,
      handleSubmit: PropTypes.func,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      submitText: PropTypes.string,
      viewMode: PropTypes.oneOf(
        Object.keys(ViewMode).map((key) => ViewMode[key])
      ),
    }),
    defaultProps({
      handleReset: () => {},
      handleSubmit: () => {},
      handleCheckbox: () => {},
      requiredPPI: [],
      initialValues: {},
      onSubmit: () => {},
      disabled: false,
      submitText: 'Submit',
      viewMode: ViewMode.CREATE,
    }),
    memo
  )
);
