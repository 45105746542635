import { digitalServicePrefix } from 'components/forms/utils';
import { adminApiSlice } from '../adminApiSlice';
import { map, pick, filter } from 'ramda';

const filterInits = ({ initiativeType }) =>
  initiativeType === digitalServicePrefix;

const experiencesApiSlice = adminApiSlice.injectEndpoints({
  tagTypes: ['experiences', 'initiatives'],
  endpoints(builder) {
    return {
      fetchExperiencesList: builder.query({
        query() {
          return '/initiatives';
        },
        providesTags: (result) => {
          return result
            ? [...result.filter(filter), { type: 'initiatives', id: 'LIST' }]
            : [{ type: 'initiatives', id: 'LIST' }];
        },
        transformResponse: (response, _meta, _arg) => {
          //Passing only the fields needed by Create and Edit experience otherwise object contract mismatch will happen
          return map(
            (data) => ({
              ...pick(
                [
                  'id',
                  'name',
                  'handle',
                  'shortHandle',
                  'moduleHandle',
                  'initiativeType',
                  'status',
                  'participantCount',
                  'participantLimit',
                  'participationStatus',
                  'platforms',
                  'requiredPpis',
                  'isConsentRequired',
                  'defaultMedia',
                  'organization',
                ],
                data
              ),
              ...data.attributes,
            }),
            filter(filterInits, response.initiatives)
          );
        },
      }),
      patchExperienceRequest: builder.mutation({
        query: (body) => {
          return {
            url: `/initiatives/${ body.handle }`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: (_result, _error, _data) => [
          { type: 'experiences', id: 'LIST' },
          { type: 'initiative', id: 'LIST' },
        ],
      }),
      createExperience: builder.mutation({
        query: (body) => ({
          url: '/initiatives',
          method: 'POST',
          body,
        }),
        invalidatesTags: (_response, _, _body) => [
          { type: 'experiences', id: 'LIST' },
          { type: 'initiative', id: 'LIST' },
        ],
      }),
      deleteExperienceRequest: builder.mutation({
        query: (handle) => {
          return {
            url: `/initiatives/${ handle }`,
            method: 'DELETE',
          };
        },
        invalidatesTags: [
          { type: 'experiences', id: 'LIST' },
          { type: 'initiative', id: 'LIST' },
        ],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchExperiencesListQuery,
  usePatchExperienceRequestMutation,
  useDeleteExperienceRequestMutation,
  useCreateExperienceMutation,
} = experiencesApiSlice;
