import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const adminApiSliceWithTags = adminApiSlice.enhanceEndpoints({
  addTagTypes: ['audiences', 'user'],
});

const audiencesApiSlice = adminApiSliceWithTags.injectEndpoints({
  endpoints(builder) {
    return {
      fetchAudiences: builder.query({
        query() {
          return '/audiences';
        },
        providesTags: () => [{ type: 'audiences', id: 'LIST' }],
        transformResponse: (response) => pathOr([], ['audiences'], response),
      }),
      fetchAudience: builder.query({
        query(id) {
          return `/audiences/${ id }`;
        },
        providesTags: (_result, _error, id) => [{ type: 'audiences', id }],
      }),
      fetchUsers: builder.query({
        query(id) {
          return `/audiences/${ id }/users`;
        },
        providesTags: (result, _error, handle) =>
          (result
            ? [
              ...result?.users.map(({ upmId }) => ({
                type: 'user',
                id: upmId,
              })),
              'user',
            ]
            : ['user']),
      }),
      deleteUser: builder.mutation({
        query: ({ id, upmId }) => {
          return {
            url: `/audiences/${ id }/users/${ upmId }`,
            method: 'DELETE',
          };
        },
        invalidatesTags: (result, _error, { upmId }) => [
          { type: 'user', id: upmId },
        ],
      }),
      createAudience: builder.mutation({
        query: (body) => ({
          url: '/audiences',
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'audiences', id: 'LIST' }],
      }),
      patchAudience: builder.mutation({
        query: (body) => {
          return {
            url: `/audiences/${ body.handle }`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: (_result, _error, { handle, upmId }) => [
          { type: 'audiences', id: handle },
          { type: 'audiences', id: 'LIST' },
          { type: 'user', id: upmId },
        ],
      }),
      createAudienceMember: builder.mutation({
        query: (body) => ({
          url: `/audiences/${ body.handle }/users`,
          method: 'POST',
          body: { upmId: body.upmId },
        }),
        invalidatesTags: [{ type: 'audiences', id: 'LIST' }],
      }),
      createAudienceMemberWithAthleteId: builder.mutation({
        query: (body) => ({
          url: `/audiences/${ body.handle }/athletes`,
          method: 'POST',
          body: { athleteId: body.athleteId },
        }),
        invalidatesTags: [{ type: 'audiences', id: 'LIST' }],
      }),
    };
  },
  overrideExisting: true,
});

export const {
  useFetchAudiencesQuery,
  useFetchAudienceQuery,
  useFetchUsersQuery,
  useDeleteUserMutation,
  usePatchAudienceMutation,
  useCreateAudienceMutation,
  useCreateAudienceMemberMutation,
  useCreateAudienceMemberWithAthleteIdMutation,
} = audiencesApiSlice;
