export const columns = [
  {
    field: 'handle',
    headerName: 'Handle',
    minWidth: 250,
    flex: 2,
    filterable: true,
  },
  {
    field: 'expiresAt',
    headerName: 'Expires At',
    minWidth: 200,
    flex: 1,
    filterable: true,
    type: 'dateTime',
  },
  {
    field: 'createTimestamp',
    headerName: 'Created At',
    minWidth: 200,
    flex: 2,
    filterable: true,
    type: 'dateTime',
  },
];

export const getRows = (caches = []) => {
  return caches.map((handle) => {
    const expiration = new Date(handle.createTimestamp).getTime() + handle.ttl;

    return {
      ...handle,
      expiresAt: expiration,
    };
  });
};

export const getActions = (props) => {
  return [
    {
      key: 'handle',
      label: 'clear',
      onClick: props.clear,
      url: '',
      action: 'create',
      entity: 'cache',
    },
  ];
};

export const services = [
  'config-api',
  'user-api',
  'initiative-api',
  'admin-api',
];
