import { makeStyles } from '@material-ui/core/styles';
export const useModalStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.7)',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '500px',
    border: '3px solid red',
  },
  header: {
    height: '80px',
    backgroundColor: 'rgba(245, 246, 246, 1.0)',
    '& h3': {
      lineHeight: '80px',
      paddingLeft: '20px',
      fontSize: '30px',
      letterSpacing: '-.05em',
      textAlign: 'center',
      fontWeight: '500',
    },
    '& button': {
      lineHeight: '80px',
      height: '100%',
      width: '100%',
    },
  },
  body: {
    padding: '40px 20px',
    fontSize: '24px',
    lineHeight: '36px',
  },
}));
