/* eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AddEditContent,
  AddEditConsent,
  AddEditScreener,
  AddEditSurvey,
} from './views';
import { STATE_BASED_CONTENT } from 'lib/layoutUtils';

const AssetWrapper = ({ ...addEditProps }) => {
  const { type } = useParams();
  const layoutId = addEditProps?.location?.state?.layoutId;
  const [activeId, setActiveId] = useState(layoutId);
  const [stateBasedAsset, setStateBasedAsset] = useState(false);

  useEffect(() => {
    const isStateBasedAsset = STATE_BASED_CONTENT.includes(type);
    setStateBasedAsset(isStateBasedAsset);
  }, [type]);

  const handleChange = useCallback((event) => {
    setActiveId(event.target.id);
  }, []);

  return (
    {
      'consent-document': <AddEditConsent { ...addEditProps } />,
      screener: <AddEditScreener { ...addEditProps } />,
      survey: <AddEditSurvey { ...addEditProps } />,
    }[type] || (
      <>
        <AddEditContent
          { ...addEditProps }
          activeId={ stateBasedAsset ? activeId : null }
          handleTabChange={ handleChange }
        />
      </>
    )
  );
};

export default AssetWrapper;
