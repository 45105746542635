import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon, Text } from '@nike/eds';
import { MenuHeaderAvatarStyled, MenuHeaderSlotsStyled } from './styles';

const MenuHeaderSlot = ({
  name,
  handle,
  defaultMediaImage,
  handleMenuToggle,
  isMenuAnchor = false,
}) => {
  return (
    <MenuHeaderSlotsStyled onClick={ handleMenuToggle }>
      { defaultMediaImage ? (
        <Avatar initials="exp" size={ 48 } url={ defaultMediaImage } />
      ) : (
        <Icon
          backgroundColor={
            isMenuAnchor ? 'var(--eds-color-black)' : 'var(--eds-color-grey-4)'
          }
          color={
            isMenuAnchor ? 'var(--eds-color-white)' : 'var(--eds-color-black)'
          }
          backgroundShape="circle"
          size="l"
          name="NikeApp"
          enableFocus={ true }
        />
      ) }
      <MenuHeaderAvatarStyled>
        <Text font={ isMenuAnchor ? 'title-4' : 'title-5' }>{ name }</Text>
        <Text font="body-3" color="grey-1">
          { handle }
        </Text>
      </MenuHeaderAvatarStyled>
    </MenuHeaderSlotsStyled>
  );
};

MenuHeaderSlot.propTypes = {
  defaultMediaImage: PropTypes.string,
  handle: PropTypes.string,
  handleMenuToggle: PropTypes.func,
  isMenuAnchor: PropTypes.bool,
  name: PropTypes.string,
};

export default MenuHeaderSlot;
