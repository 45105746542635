/* eslint-disable max-statements */
// modules
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  useFetchDataRequestsInitiativesQuery,
  useFetchDataRequestQuery,
  usePatchDataRequestInitiativeMutation,
} from 'features/adminApi/endpoints/dataRequests';
import Loading from 'components/Loading';
import DataTable from 'components/DataTable';
import { getActions, columns } from './tableConfig';
import { useToasts } from 'react-toast-notifications';
import DataRequestInfo from './DataRequestInfo';

//thir-party
import { pathOr, reject, isNil, join } from 'ramda';
import { Text } from '@nike/eds';

const localeText = {
  noRowsLabel: 'No data available',
};

const DataRequestDetail = () => {
  const { id } = useParams();
  const { addToast } = useToasts();
  const [disable, setDisable] = useState(false);
  const skipCondition = {
    skip: !id,
  };

  // REDUX API HOOKS ----------------------------------------------------------
  const {
    data: dataRequest = [],
    isLoading: dataIsLoading,
    error: datRequestError,
  } = useFetchDataRequestQuery(id, skipCondition);
  const { data = [], isLoading } = useFetchDataRequestsInitiativesQuery(
    id,
    skipCondition
  );
  const [patchDataRequestInitiative, { isSuccess, error, originalArgs }] =
    usePatchDataRequestInitiativeMutation();

  const items = data?.initiatives || [];

  // API CALLS ----------------------------------------------------------------
  const handleResolveRequest = useCallback(
    (handle) => {
      patchDataRequestInitiative({
        handle,
        id,
        status: 'complete',
      });
    },
    [patchDataRequestInitiative]
  );

  const tableActions = getActions({ resolve: handleResolveRequest, disable });

  useEffect(() => {
    setDisable(originalArgs?.status === 'complete');
    if (isSuccess) {
      addToast('Success', { appearance: 'success', autoDismiss: true });
    }
  }, [isSuccess, originalArgs]);

  useEffect(() => {
    const getErrorMessage = join(', ');
    const errorMessages = reject(isNil, [
      pathOr(null, ['data', 'errorMessage'], error),
      pathOr(null, ['data', 'errorMessage'], datRequestError),
    ]);
    const errorMessage = getErrorMessage(errorMessages);

    if (errorMessage) {
      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    }
  }, [error, datRequestError]);

  return (
    <>
      { dataIsLoading || isLoading ? (
        <Loading text="Fetching Data-Request-Details" />
      ) : (
        <>
          <Text font={ 'title-1' } as={ 'h1' }>
            Data Request Detail
          </Text>
          <DataRequestInfo dataRequest={ dataRequest } />
          <DataTable
            noToolbar={ true }
            columns={ columns }
            actions={ tableActions }
            loading={ isLoading }
            rows={ items }
            localeText={ localeText }
          />
        </>
      ) }
    </>
  );
};

export default DataRequestDetail;
