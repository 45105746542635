import { adminApiSlice } from '../adminApiSlice';

const configApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['config', 'config-list'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchConfig: builder.query({
          query(configHandle) {
            return `/configs/${ configHandle }/current`;
          },
          providesTags: (_result, _error, configHandle) => [
            { type: 'config', id: configHandle },
          ],
          transformResponse: (response, _, _status) => {
            return response;
          },
        }),
        fetchConfigList: builder.query({
          query() {
            return '/configs';
          },
          providesTags: () => [{ type: 'config-list', id: 'LIST' }],
          transformResponse: (response, _, _status) => response,
        }),
        patchConfig: builder.mutation({
          query: (body) => {
            return {
              url: `/configs/${ body.handle }/${ body.version }`,
              method: 'PATCH',
              body,
            };
          },
          invalidatesTags: (_result, _error, { handle }) => {
            return [{ type: 'config', id: handle }];
          },
          transformResponse: (response, _, _status) => {
            return response;
          },
        }),
        //TODO: Integrate this in the config-list component in case if we need this feature
        postConfig: builder.mutation({
          query: (body) => {
            return {
              url: '/configs',
              method: 'POST',
            };
          },
          invalidatesTags: (_result, _error, { handle }) => {
            return [{ type: 'config', id: handle }];
          },
        }),

        //TODO: Integrate this in the config-list component in case if we need this feature
        deleteConfig: builder.mutation({
          query: (body) => {
            return {
              url: `/configs/${ body.handle }/${ body.version }`,
              method: 'DELETE',
            };
          },
          invalidatesTags: (_result, _error, { handle }) => {
            return [{ type: 'config', id: handle }];
          },
        }),
      };
    },
  });

export const {
  useFetchConfigQuery,
  useFetchConfigListQuery,
  usePatchConfigMutation,
} = configApiSlice;
